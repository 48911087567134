import { RequestResponseSchema, ServerErrorType } from 'services';

/* eslint-disable no-unused-vars */
export interface PaymentLinkContextType {
    state: PaymentLinkStateType;
    create: (
        params: Partial<PaymentLinkType>,
        type: PaymentLinkOptions
    ) => Promise<RequestResponseSchema<PaymentLinkResponse> | null>;

    update: (
        id: string,
        params: Partial<PaymentLinkType>
    ) => Promise<RequestResponseSchema<PaymentLinkResponse> | null>;

    findAll: (
        params?: Partial<PaymentLinkQueryType>
    ) => Promise<RequestResponseSchema<PaymentLinkType[]> | null>;

    findOne: (
        id: string
    ) => Promise<RequestResponseSchema<PaymentLinkType> | null>;

    remove: (id: string) => Promise<RequestResponseSchema<null> | null>;

    getAnalytics: () => Promise<RequestResponseSchema<null> | null>;

    getAnalyticsByPaymentLink: (
        id: string
    ) => Promise<RequestResponseSchema<null> | null>;
}

interface PaymentLinkResponse extends PaymentLinkType {
    errors: ServerErrorType | null;
}

export interface PaymentLinkProviderType {
    children: JSX.Element;
}

export type PaymentLinkStateType = {
    loading: boolean;
};

export type PaymentLinkActionsType = {
    type: PaymentLinkActionsOptions;
    payload: Partial<PaymentLinkStateType>;
};

export enum PaymentLinkActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface PaymentLinkType {
    url: string;
    linkType: PaymentLinkOptions;
    paymentLinkName: string;
    amount: number;
    baseCurrency: string;
    currencies: string[];
    description: string;
    customUrl: string;
    redirectUrl: string;
    extraFields: string[];
    subscriptionInterval: Intervals;
    subscriptionTimes: number;
    donationImageUrl: string;
    donationWebsite: string;
    donationPhoneNumber: string;
    isActivated: boolean;
    link: string;
    createdAt: string;
    _id: string;
}

export enum PaymentLinkOptions {
    SingleCharge = 'single charge',
    SubscriptionLink = 'subscription link',
    DonationPage = 'donation page',
}

export interface PaymentLinkQueryType {
    from: string;
    to: string;
    isActive: boolean | string;
    page: number;
    subscriptionInterval: Intervals;
    baseCurrency: string;
    maxAmount: number;
    minAmount: number;
    minSubscriptionTimes: number;
    maxSubscriptionTimes: number;
    linkType: PaymentLinkOptions;
}

export interface AnalyticType {
    count: number;
    localCurrencyValue: number;
    dollarSum: number;
    activeLinks: number;
}

export enum Intervals {
    Hourly = 'hourly',
    Weekly = 'weekly',
    Daily = 'daily',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    EverySixMonths = 'every six months',
    Yearly = 'yearly',
}
