/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { MetaType, RequestResponseSchema, ServerErrorType } from 'services';

export interface CustomerContextType {
    state: CustomerStateType;
    findAll: (
        params?: Partial<CustomersQueryType>
    ) => Promise<{
        customers: CustomerType[];
        meta: MetaType | null;
    } | null>;
    findOne: (id: string) => Promise<CustomerType | null>;
    archive: (id: string, status: boolean) => Promise<boolean>;
    update: (
        id: string,
        params: Partial<CustomersQueryType>
    ) => Promise<RequestResponseSchema<CustomerDataResponse> | null>;
    create: (
        body: Partial<CustomersQueryType>
    ) => Promise<RequestResponseSchema<CustomerDataResponse> | null>;
}

export interface CustomerDataResponse extends CustomerType {
    errors: ServerErrorType;
}
export interface CustomerProviderType {
    children: JSX.Element;
}

export type CustomerStateType = {
    loading: boolean;
};

export type CustomerActionsType = {
    type: CustomerActionsOptions;
    payload: Partial<CustomerStateType>;
};

export enum CustomerActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface CustomerType {
    fullName: string;
    email: string;
    phoneNumber: string;
    isBlacklisted: boolean;
    _id: string;
    createdAt: string;
}

export interface CustomersQueryType {
    from: string;
    to: string;
    isBlacklisted: boolean;
    page: number;
    email: string;
    phoneNumber: string;
}
