import React, { createContext, useReducer, useCallback, FC } from 'react';
import { coinForBarterRequest, ContentType, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    BrandingActionsOptions,
    BrandingContextType,
    BrandingProviderType,
    BrandingType,
} from './types';
import { defaultBrandingContextValue, defaultBrandingState } from './default';

export const BrandingContext = createContext<BrandingContextType>(
    defaultBrandingContextValue
);

export const BrandingProvider: FC<BrandingProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultBrandingState);

    const find = useCallback(async () => {
        try {
            dispatch({
                type: BrandingActionsOptions.Update,
                payload: { loading: true },
            });
            const { statusCode, data } = await coinForBarterRequest.call(
                `/branding`,
                MethodTypes.Get,
                undefined,
                true
            );

            if (statusCode === 200) {
                dispatch({
                    type: BrandingActionsOptions.Update,
                    payload: {
                        loading: false,
                        branding: { ...data },
                    },
                });
            }
        } catch (e) {}

        dispatch({
            type: BrandingActionsOptions.Update,
            payload: { loading: false },
        });
    }, []);

    const update = useCallback(
        async (params: Partial<BrandingType>, file?: File) => {
            try {
                dispatch({
                    type: BrandingActionsOptions.Update,
                    payload: { loading: true },
                });
                const formData = new FormData();
                for (
                    let index = 0;
                    index < Object.entries(params).length;
                    index++
                ) {
                    const [key, value] = Object.entries(params)[index];
                    formData.append(key, value);
                }

                if (file) {
                    formData.append('logo', file);
                }
                const response = await coinForBarterRequest.call(
                    `/branding`,
                    MethodTypes.Post,
                    formData,
                    true,
                    ContentType.Form
                );
                dispatch({
                    type: BrandingActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: BrandingActionsOptions.Update,
                    payload: { loading: false },
                });
            }

            return null;
        },
        []
    );

    return (
        <BrandingContext.Provider
            value={{
                state,
                find,
                update,
            }}
        >
            {children}
        </BrandingContext.Provider>
    );
};
