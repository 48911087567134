import { defaultNotificationState } from './default';
import {
    NotificationActionsOptions,
    NotificationActionsType,
    NotificationStateType,
} from './types';

export const authReducer = (
    state: NotificationStateType,
    { type, payload }: NotificationActionsType
): NotificationStateType => {
    switch (type) {
        case NotificationActionsOptions.Default:
            return defaultNotificationState;

        case NotificationActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
