import { defaultCustomerState } from './default';
import {
    CustomerActionsOptions,
    CustomerActionsType,
    CustomerStateType,
} from './types';

export const authReducer = (
    state: CustomerStateType,
    { type, payload }: CustomerActionsType
): CustomerStateType => {
    switch (type) {
        case CustomerActionsOptions.Default:
            return defaultCustomerState;

        case CustomerActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
