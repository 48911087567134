import { defaultAccountState } from './default';
import {
    AccountActionsOptions,
    AccountActionsType,
    AccountStateType,
} from './types';

export const authReducer = (
    state: AccountStateType,
    { type, payload }: AccountActionsType
): AccountStateType => {
    switch (type) {
        case AccountActionsOptions.Default:
            return defaultAccountState;

        case AccountActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
