/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { MetaType, RequestResponseSchema, ServerErrorType } from 'services';

export interface PayoutContextType {
    state: PayoutStateType;
    findAll: (
        params?: Partial<PayoutsQueryType>
    ) => Promise<{
        payouts: PayoutType[];
        meta: MetaType | null;
    } | null>;
    findOne: (id: string) => Promise<PayoutType | null>;
    getSettings: () => Promise<void>;
    makeAllAutomatic: (value: boolean) => Promise<void>;
    makeAllInACurrency: (type: PayoutInType) => Promise<void>;
    makeCustom: (custom: PayoutSettingsType[]) => Promise<void>;
    createComplaint: (
        id: string,
        body: { message: string }
    ) => Promise<RequestResponseSchema<ComplaintDataResponse> | null>;
    getComplaints: (
        params?: Partial<ComplaintQueryType>
    ) => Promise<{
        complaints: ComplaintType[];
        meta: MetaType | null;
    } | null>;
    getComplaint: (
        complaintId: string
    ) => Promise<RequestResponseSchema<ComplaintType> | null>;
}

export interface ComplaintDataResponse extends ComplaintType {
    errors: ServerErrorType;
}

export interface PayoutProviderType {
    children: JSX.Element;
}

export type PayoutStateType = {
    loading: boolean;
    settings: PayoutSettingsType[];
};

export type PayoutActionsType = {
    type: PayoutActionsOptions;
    payload: Partial<PayoutStateType>;
};

export enum PayoutActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface PayoutType {
    description: string;
    status: PayoutStatus;
    amount: number;
    currency: string;
    address: string;
    bankAccount: string;
    _id: string;
    createdAt: string;
}

export interface PayoutsQueryType {
    from: string;
    to: string;
    page: number;
    currency: string;
    status: string;
    sort: string;
    minAmount: number;
    maxAmount: number;
    bankAccount: string;
    address: string;
    isAscending: boolean;
    skip: number;
}

export enum PayoutStatus {
    Error = 'error',
    Pending = 'pending',
    Success = 'success',
}

export interface PayoutSettingsType {
    currency: string;
    payoutIn: PayoutInType;
    isAutomatic: boolean;
}

export enum PayoutInType {
    Naira = 'naira',
    Dollar = 'dollar',
    Crypto = 'crypto',
    LocalCurrency = 'local-currency',
}

export interface ComplaintType {
    payoutId: string;
    message: string;
    status: PaymentComplaintStatus;
    _id: string;
    createdAt: string;
}
export enum PaymentComplaintStatus {
    Resolved = 'resolved',
    Pending = 'pending',
    Review = 'review',
}

export interface ComplaintQueryType {
    from: string;
    to: string;
    page: number;
    status: PaymentComplaintStatus;
    isAscending: boolean;
    skip: number;
    payoutId: string;
}
