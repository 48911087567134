import { defaultWithdrawalState } from './default';
import {
    WithdrawalActionsOptions,
    WithdrawalActionsType,
    WithdrawalStateType,
} from './types';

export const authReducer = (
    state: WithdrawalStateType,
    { type, payload }: WithdrawalActionsType
): WithdrawalStateType => {
    switch (type) {
        case WithdrawalActionsOptions.Default:
            return defaultWithdrawalState;

        case WithdrawalActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
