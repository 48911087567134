/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback } from 'react';

export const useModal = (): any => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleModal = useCallback(() => {
        setIsModalVisible((isModalVisible) => !isModalVisible);
    }, []);

    return {
        isModalVisible,
        toggleModal,
    };
};
