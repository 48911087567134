import { defaultWebHookState } from './default';
import {
    WebHookActionsOptions,
    WebHookActionsType,
    WebHookStateType,
} from './types';

export const authReducer = (
    state: WebHookStateType,
    { type, payload }: WebHookActionsType
): WebHookStateType => {
    switch (type) {
        case WebHookActionsOptions.Default:
            return defaultWebHookState;

        case WebHookActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
