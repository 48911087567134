import { PaymentPlanContextType, PaymentPlanStateType } from './types';

export const defaultPaymentPlanState: PaymentPlanStateType = {
    loading: false,
};

export const defaultPaymentPlanContextValue: PaymentPlanContextType = {
    state: defaultPaymentPlanState,
    create: async () => null,

    update: async () => null,

    findAll: async () => null,

    findOne: async () => null,

    getPaymentPlanAnalytics: async () => null,

    getAnalyticsByPaymentPlan: async () => null,
};
