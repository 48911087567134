import { defaultWalletState } from './default';
import {
    WalletActionsOptions,
    WalletActionsType,
    WalletStateType,
} from './types';

export const authReducer = (
    state: WalletStateType,
    { type, payload }: WalletActionsType
): WalletStateType => {
    switch (type) {
        case WalletActionsOptions.Default:
            return defaultWalletState;

        case WalletActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
