import { defaultPaymentPlanState } from './default';
import {
    PaymentPlanActionsOptions,
    PaymentPlanActionsType,
    PaymentPlanStateType,
} from './types';

export const authReducer = (
    state: PaymentPlanStateType,
    { type, payload }: PaymentPlanActionsType
): PaymentPlanStateType => {
    switch (type) {
        case PaymentPlanActionsOptions.Default:
            return defaultPaymentPlanState;

        case PaymentPlanActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
