import { useTest, useToast } from 'hooks';
import React, { FC } from 'react';
import {
    CheckBoxLabel,
    Slider,
    CheckBoxWrapper,
} from './ToggleLiveMode.styled';

export const ToggleLiveMode: FC = () => {
    const toast = useToast();
    const { setIsTest, isTest } = useTest();
    const handleToggleChange = () => {
        const newMode = !isTest;
        setIsTest(newMode);
        toast.success(
            newMode ? 'Switched to test mode' : 'Switched to live mode'
        );
        setTimeout(() => {
            location.reload();
        }, 1000);
    };

    return (
        <CheckBoxWrapper>
            <CheckBoxLabel>
                <input
                    type="checkbox"
                    defaultChecked={isTest}
                    onClick={handleToggleChange}
                />
                <Slider>
                    <p>Test Mode</p>
                    <p>Live Mode</p>
                </Slider>
            </CheckBoxLabel>
        </CheckBoxWrapper>
    );
};
