/* eslint-disable no-unused-vars */
import { ServerErrorType } from 'services';

export interface ProfileContextType {
    state: ProfileStateType;
    getProfile: () => void;
    updateProfile: (values: UpdateProfileType) => void;
}

export interface ProfileProviderType {
    children: JSX.Element;
}

export type ProfileStateType = {
    loading: boolean;
    data?: ProfileStateDataType;
    phoneNumberToken?: string;
    errors?: ServerErrorType;
};

export type ProfileActionsType = {
    type: ProfileActionsOptions;
    payload: Partial<ProfileStateType>;
};

export enum ProfileActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface ProfileStateDataType {
    phoneNumber: string;
    isPhoneNumberVerified: boolean;
}
export type UpdateProfileType = {
    phoneNumber?: string;
};
