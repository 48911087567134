import tw from 'tailwind-styled-components'

export const Container = tw.div`
    flex
    items-center
    w-max
    max-w-sm
    py-1
    px-2
    my-2
`

export const Error = tw.p`
    text-error
    text-center
    text-tiny
    leading-4
`
