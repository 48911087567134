import React, { createContext, useReducer, useCallback, FC } from 'react';
import { useToast } from 'hooks';
import {
    coinForBarterRequest,
    MethodTypes,
    RequestResponseSchema,
} from 'services';
import { authReducer } from './reducer';
import {
    CustomerActionsOptions,
    CustomerContextType,
    CustomerProviderType,
    CustomersQueryType,
    CustomerDataResponse,
    CustomerType,
} from './types';
import { defaultCustomerContextValue, defaultCustomerState } from './default';

export const CustomerContext = createContext<CustomerContextType>(
    defaultCustomerContextValue
);

export const CustomerProvider: FC<CustomerProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultCustomerState);
    const { success, error } = useToast();

    const findAll = useCallback(
        async (params?: Partial<CustomersQueryType>) => {
            try {
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: true },
                });

                const searchParams =
                    coinForBarterRequest.makeQueryString(params || {}) || '';
                const {
                    data,
                    statusCode,
                    meta,
                } = await coinForBarterRequest.call(
                    `/customers${searchParams}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    dispatch({
                        type: CustomerActionsOptions.Update,
                        payload: { loading: false },
                    });
                    return { customers: data, meta: meta || null };
                }
            } catch (e) {
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: false },
                });
            }

            return null;
        },
        []
    );

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: CustomerActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/customers/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: false },
                });
                return data;
            }
        } catch (e) {
            dispatch({
                type: CustomerActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const update = useCallback(
        async (id: string, params) => {
            try {
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: true },
                });
                const response = await coinForBarterRequest.call(
                    `/customers/${id}`,
                    MethodTypes.Patch,
                    params,
                    true
                );
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                error('An error occurred, please try again');
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: false },
                });
            }
            dispatch({
                type: CustomerActionsOptions.Update,
                payload: { loading: false },
            });

            return null;
        },
        [error]
    );

    const archive = useCallback(
        async (id: string, status: boolean) => {
            try {
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/customers/blacklist/${id}/${status}`,
                    MethodTypes.Patch,
                    undefined,
                    true
                );
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: false },
                });
                if (statusCode === 200 && status === true) {
                    success('Customer successfully blacklisted');
                    return true;
                } else if (statusCode === 200 && status === false) {
                    success('Customer successfully unblacklisted');
                    return true;
                } else {
                    error(message);
                }
            } catch (e) {
                error('An error occurred, please try again');
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: false },
                });
            }
            dispatch({
                type: CustomerActionsOptions.Update,
                payload: { loading: false },
            });

            return false;
        },
        [success, error]
    );

    const create = useCallback(
        async (
            body: Partial<CustomerType>
        ): Promise<RequestResponseSchema<CustomerDataResponse> | null> => {
            try {
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: true },
                });
                const response = await coinForBarterRequest.call(
                    `/customers`,
                    MethodTypes.Post,
                    body,
                    true
                );
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: CustomerActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: CustomerActionsOptions.Update,
                payload: { loading: false },
            });

            return null;
        },
        [error]
    );

    return (
        <CustomerContext.Provider
            value={{
                state,
                findAll,
                findOne,
                update,
                archive,
                create,
            }}
        >
            {children}
        </CustomerContext.Provider>
    );
};
