import React, { useState, FC } from 'react';
import { ReactComponent as LogoWithText } from '@assets/icons/logo-name.svg';
import { ReactComponent as Hamburger } from '@assets/icons/hamburger.svg';
import { ReactComponent as Close } from '@assets/images/bars-cross.svg';
import { NotificationProvider } from 'context';
import { useNotification } from 'hooks/useNotifications';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { ProfileMenu } from './ProfileMenu/ProfileMenu';
import { ToggleLiveMode } from './ToggleLiveMode/ToggleLiveMode';
import {
    Container,
    Actions,
    IconButton,
    Logo,
    UserCorner,
    Toggler,
} from './Header.styled';

const NotificationIcon = () => {
    const {
        state: { totalUnread },
    } = useNotification();
    const history = useHistory();

    return (
        <IconButton
            onClick={() => {
                history.push(routes.notifications);
            }}
        >
            {totalUnread ? <span>{totalUnread}</span> : null}
            <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M22.199 17.2501V16.2501H21.199H20.2942V10.5834C20.2942 8.29749 19.3862 6.1052 17.7698 4.48882C16.1534 2.87243 13.9611 1.96436 11.6752 1.96436C9.38926 1.96436 7.19697 2.87243 5.58059 4.48882C3.96421 6.1052 3.05613 8.29749 3.05613 10.5834V16.2501H2.15137H1.15137V17.2501V19.1548V20.1548H2.15137H7.81803V21.0596V22.9644V23.9644H8.81803H14.5323H15.5323V22.9644V21.0596V20.1548H21.199H22.199V19.1548V17.2501Z"
                    strokeWidth="2"
                />
            </svg>
        </IconButton>
    );
};

export const Header: FC<{ toggle: () => void; isSideBarOpen: boolean }> = ({
    toggle,
    isSideBarOpen,
}) => {
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <Container>
            <Toggler onClick={toggle}>
                <>
                    {isSideBarOpen && <Close />}
                    {!isSideBarOpen && <Hamburger />}
                </>
            </Toggler>

            <Logo to="/">
                <LogoWithText
                    style={{
                        width: '200px',
                    }}
                />
            </Logo>

            <Actions>
                <ToggleLiveMode />
                {/* <div /> */}
                <NotificationProvider>
                    <NotificationIcon />
                </NotificationProvider>
                <UserCorner onClick={() => setOpenMenu(!openMenu)}>
                    <IconButton>
                        <svg
                            width="22"
                            height="25"
                            viewBox="0 0 22 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M20.4814 16.6402L20.4814 16.6401L20.4748 16.6329C19.3099 15.3708 17.8963 14.3636 16.323 13.6747C14.7497 12.9858 13.0508 12.6301 11.3333 12.6301C9.61575 12.6301 7.91685 12.9858 6.34355 13.6747C4.77025 14.3636 3.35665 15.3708 2.19178 16.6329L2.19174 16.6329L2.18494 16.6404C1.85526 17.005 1.67084 17.4778 1.66666 17.9693L1.66663 17.9693V17.9778V21.9778H1.66654L1.66671 21.9911C1.67373 22.5169 1.88753 23.0188 2.26185 23.3881C2.63614 23.7574 3.14081 23.9645 3.66662 23.9645C3.66665 23.9645 3.66668 23.9645 3.66671 23.9645H18.9999C19.5303 23.9645 20.0391 23.7538 20.4141 23.3787C20.7892 23.0036 20.9999 22.4949 20.9999 21.9645V17.9645V17.9535L20.9997 17.9425C20.9891 17.4602 20.8051 16.9979 20.4814 16.6402Z"
                                strokeWidth="2"
                            />
                            <path
                                d="M11.3333 13.2977C14.4629 13.2977 16.9999 10.7606 16.9999 7.63101C16.9999 4.5014 14.4629 1.96436 11.3333 1.96436C8.20367 1.96436 5.66663 4.5014 5.66663 7.63101C5.66663 10.7606 8.20367 13.2977 11.3333 13.2977Z"
                                strokeWidth="2"
                            />
                        </svg>

                        <svg
                            width="17"
                            height="10"
                            viewBox="0 0 17 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.13062 0.797119L8.63062 8.29712L16.1306 0.797119"
                                strokeWidth="2"
                                style={{ fill: 'none' }}
                            />
                        </svg>
                    </IconButton>
                </UserCorner>
                <ProfileMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
            </Actions>
        </Container>
    );
};
