import { motion } from 'framer-motion';
import styled from 'styled-components/macro';
import tw from 'tailwind-styled-components';

export const Icon = tw(styled.div`
    height: 70px;
    width: 70px;
    top: -45px;
    padding: 20px;
`)`
    absolute
    rounded-full
    bg-white
    flex 
    items-center 
    justify-center
    shadow-lg
    transition: all 0.3s;
`;

export const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    width: '100%';
    align-items: stretch;
    justify-content: space-between;
    padding: 12px 20px;
    height: 'auto';
    margin-top: 48px;
    padding-top: 46px;
    min-height: 200px;
    margin-bottom: 0px;
    background: #ffffff;
    box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.1),
        0px 3px 3px rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
        div:first-child {
            transform: scale(0.85, 0.85);
            transform-origin: center;
        }
    }

    div:first-child {
        transition: all 0.3s;
    }
`;

export const Title = tw(styled.h4`
    font-size: 16px;
    font-weight: bold;
`)`
    text-body
    font-bold
    mb-1
`;

export const Subtitle = tw(styled.p`
    font-size: 14px;
`)`
    text-small
`;
