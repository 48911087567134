import { RequestResponseSchema, ServerErrorType } from 'services';

/* eslint-disable no-unused-vars */
export interface PaymentPlanSubscriberContextType {
    state: PaymentPlanSubscriberStateType;
    create: (
        params: Partial<PaymentPlanSubscriberType>,
        paymentPlanId: string
    ) => Promise<RequestResponseSchema<PaymentPlanSubscriberResponse> | null>;

    remove: (id: string) => Promise<RequestResponseSchema<null> | null>;

    findAll: (
        queryType: Partial<PaymentPlanSubscriberQueryType>
    ) => Promise<RequestResponseSchema<PaymentPlanSubscriberType[]> | null>;

    findOne: (
        id: string
    ) => Promise<RequestResponseSchema<PaymentPlanSubscriberType> | null>;
}

interface PaymentPlanSubscriberResponse extends PaymentPlanSubscriberType {
    errors: ServerErrorType | null;
}

export interface PaymentPlanSubscriberProviderType {
    children: JSX.Element;
}

export type PaymentPlanSubscriberStateType = {
    loading: boolean;
};

export type PaymentPlanSubscriberActionsType = {
    type: PaymentPlanSubscriberActionsOptions;
    payload: Partial<PaymentPlanSubscriberStateType>;
};

export enum PaymentPlanSubscriberActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface PaymentPlanSubscriberType {
    customer: string;
    paymentPlanId: string;
    noOfTimesPayed: number;
    nextTimeToCharge: string;
    isActive: boolean;
    _id: string;
    createdAt: string;
    email?: string;
    phoneNumber: string;
    fullName: string;
}

export interface PaymentPlanSubscriberQueryType {
    from: string;
    to: string;
    isActive: boolean;
    page: number;
    customerId: string;
    paymentPlanId: string;
    maxNoOfTimesPayed: number;
    minNoOfTimesPayed: number;
    minNextTimeToCharge: string;
    maxNextTimeToCharge: string;
}
