/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AccountStatus } from 'context/accounts';
import {
    AuthContextType,
    AuthLoginValuesType,
    AuthRegisterValuesType,
    AuthStateType,
} from './types';

export const defaultAuthState: AuthStateType = {
    loading: false,
    authenticated: false,
    registering: false,
    data: {
        user: {
            referralCode: '',
            howYouHeardAboutUs: '',
            country: '',
            tradingName: '',
            fullName: '',
            emailVerifiedAt: '',
            email: '',
            _id: '',
            updatedAt: '',
            createdAt: '',
        },
        account: {
            userId: '',
            _id: '',

            name: '',

            email: '',

            type: '',

            isDeleted: false,

            isPhoneNumberVerified: false,

            isEmailVerified: false,

            status: AccountStatus.Active,

            isPrimary: false,

            phoneNumber: '',
        },
    },
};

export const defaultAuthContextValue: AuthContextType = {
    state: defaultAuthState,
    login: (values: AuthLoginValuesType) => {},
    logout: () => {},
    register: (values: AuthRegisterValuesType) => {},
    getAuthenticatedUser: () => {},
    suspend: () => {},
};
