import { defaultAccountProfileState } from './default';
import {
    AccountProfileActionsOptions,
    AccountProfileActionsType,
    AccountProfileStateType,
} from './types';

export const authReducer = (
    state: AccountProfileStateType,
    { type, payload }: AccountProfileActionsType
): AccountProfileStateType => {
    switch (type) {
        case AccountProfileActionsOptions.Default:
            return defaultAccountProfileState;

        case AccountProfileActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
