import React, { createContext, useReducer, useCallback, FC } from 'react';
import {
    coinForBarterRequest,
    MethodTypes,
    RequestResponseSchema,
} from 'services';
import { useToast } from 'hooks';
import { authReducer } from './reducer';
import {
    InvoiceActionsOptions,
    InvoiceContextType,
    InvoiceProviderType,
    InvoicesQueryType,
    InvoiceDataResponse,
    InvoiceType,
    SentInvoicesQueryType,
} from './types';
import { defaultInvoiceContextValue, defaultInvoiceState } from './default';

export const InvoiceContext = createContext<InvoiceContextType>(
    defaultInvoiceContextValue
);

export const InvoiceProvider: FC<InvoiceProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultInvoiceState);
    const { error, success } = useToast();

    const findAll = useCallback(async (params?: Partial<InvoicesQueryType>) => {
        try {
            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: true },
            });

            const searchParams =
                coinForBarterRequest.makeQueryString(params || {}) || '';
            const { data, statusCode, meta } = await coinForBarterRequest.call(
                `/invoices${searchParams}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                return { invoices: data, meta: meta || null };
            }
        } catch (e) {
            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: InvoiceActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const findAllSent = useCallback(
        async (params?: Partial<SentInvoicesQueryType>) => {
            try {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: true },
                });

                const searchParams =
                    coinForBarterRequest.makeQueryString(params || {}) || '';
                const {
                    data,
                    statusCode,
                    meta,
                } = await coinForBarterRequest.call(
                    `/invoices/send/${searchParams}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    dispatch({
                        type: InvoiceActionsOptions.Update,
                        payload: { loading: false },
                    });
                    return { sentInvoices: data, meta: meta || null };
                }
            } catch (e) {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
            }

            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: false },
            });

            return null;
        },
        []
    );

    const findOneSent = useCallback(async (id: string) => {
        try {
            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/invoices/send/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                return data;
            }
        } catch (e) {
            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/invoices/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                return data;
            }
        } catch (e) {
            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const update = useCallback(
        async (id: string, params) => {
            try {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: true },
                });
                const response = await coinForBarterRequest.call(
                    `/invoices/${id}`,
                    MethodTypes.Patch,
                    params,
                    true
                );
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: false },
            });

            return null;
        },
        [error]
    );

    const remove = useCallback(
        async (id: string) => {
            try {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/customers/blacklist/${id}/${status}`,
                    MethodTypes.Patch,
                    undefined,
                    true
                );
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                if (statusCode === 200) {
                    success('Invoice successfully deleted');
                    return true;
                } else {
                    error(message);
                }
            } catch (e) {
                error('An error occurred, please try again');

                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
            }
            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: false },
            });

            return false;
        },
        [error, success]
    );

    const create = useCallback(
        async (
            body: Partial<InvoiceType>
        ): Promise<RequestResponseSchema<InvoiceDataResponse> | null> => {
            try {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: true },
                });
                const response = await coinForBarterRequest.call(
                    `/invoices`,
                    MethodTypes.Post,
                    body,
                    true
                );
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: false },
            });

            return null;
        },
        [error]
    );

    const deleteInvoice = useCallback(
        async (id: string) => {
            try {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: true },
                });

                const response = await coinForBarterRequest.call(
                    `/invoices/${id}`,
                    MethodTypes.Delete,
                    undefined,
                    true
                );

                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }

            return null;
        },
        [error]
    );

    const inviteCustomers = useCallback(
        async (id: string, emails: string[]): Promise<boolean> => {
            try {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/invoices/send/${id}`,
                    MethodTypes.Post,
                    { customers: emails },
                    true
                );
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });

                if (statusCode === 201) {
                    success('Invoice successfully sent to customer(s)');
                    return true;
                }
                error(message);
            } catch (e) {
                dispatch({
                    type: InvoiceActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: InvoiceActionsOptions.Update,
                payload: { loading: false },
            });

            return false;
        },
        [error, success]
    );

    return (
        <InvoiceContext.Provider
            value={{
                state,
                findAll,
                findOne,
                update,
                remove,
                findOneSent,
                create,
                findAllSent,
                inviteCustomers,
                deleteInvoice,
            }}
        >
            {children}
        </InvoiceContext.Provider>
    );
};
