/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { FC } from 'react';

export const InputWrapper = styled.div`
    padding: 10px 0;
`;

export const Label: FC<Record<string, any>> = styled.label`
    font-size: 14px;
    padding-bottom: 10px;
    display: block;
    pointer-events: none;
    color: ${(props: Record<string, any>) => (props.error ? 'red' : '#7b7b7b')};
    transition: all 0.2s ease-out;
    font-weight: 600;
`;

export const InputElement: FC<Record<string, any>> = styled.input`
    box-sizing: border-box;
    border-radius: 3px;
    display: block;
    width: 100%;
    border: ${(props: Record<string, any>) =>
        props.error ? '1px solid red' : '1px solid #cacaca'};
    font-size: 14px;
    color: #7b7b7b;
    font-weight: 600;
    padding: 10px 20px;
    transition: all 0.1s;
    :focus {
        outline-width: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const TextAreaElement: FC<Record<string, any>> = styled.textarea`
    box-sizing: border-box;
    border-radius: 3px;
    display: block;
    width: 100%;
    border: ${(props: Record<string, any>) =>
        props.error ? '1px solid red' : '1px solid #cacaca'};
    font-size: 14px;
    color: #7b7b7b;
    font-weight: 600;
    padding: 10px 20px;
    transition: all 0.1s;
    :focus {
        outline-width: 0;
    }
`;

export const LabelHint = tw.span`
    text-tiny
    text-black-200
`;

export const PasswordElement = tw(InputElement)`
    pr-8
`;

export const PasswordEye = styled.span`
    svg {
        path {
            fill: ${(props: Record<string, any>) =>
                props['data-visible'] ? '#646464' : '#B2BBBF'};
        }
    }
`;

export const Validation = tw(styled.span`
    font-size: 12px;
`)`
    block
    px-0
    flex
    items-center
    pt-1
    font-thin
    text-small
    text-error
    pb-0
    capitalize
`;

export const SelectStyled: FC<Record<string, any>> = styled.input`
    /* appearance-none
    focus:outline-none
    focus:ring-1
    border
    focus:border-transparent
    ${(p: Record<string, any>) => (p.error ? 'ring-error' : 'ring-gray-300')}
    ${(p: Record<string, any>) =>
        p.error ? 'border-error' : 'border-gray-300'}
    rounded-md
    w-full
    transition-shadow
    ${(p: Record<string, any>) => (p.error ? 'bg-error' : 'bg-gray-100')}
    ${(p: Record<string, any>) => (p.error ? 'bg-opacity-10' : '')}
    py-2.5
    px-3 */
    appearance: none;
    width: 100%;
    height: 48px;
    padding: 8px;
    outline: 0;
    border: 1px solid #cacaca;
    border-radius: 3px;
    background: #fff;
    border: ${(props: Record<string, any>) =>
        props.error ? '2px solid #CF0000' : '1px solid #CACACA'};
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #373737;
`;

export const SelectWrapper = tw.div`
    relative
`;

export const DropdownStyled = styled.div`
    width: 100%;
    padding: 0;
    background: white;
    z-index: 30;
    max-height: 200px;
    overflow: hidden;
    overflow-y: scroll;
    border: 1px solid #cacaca;
    border-top: 0px;
    margin: 0;
`;

export const MenuItem = styled.button`
    color: #7b7b7b;
    padding: 10px 20px;
    border-bottom: 1px dashed #3d0040;
    cursor: pointer;
    transition: all 0.1s;
    display: block;
    width: 100%;
    text-transform: capitalize;
    text-align: left;
    :last-child {
        border: 0;
    }

    :focus {
        outline-width: 0;
        border-width: 0;
    }

    :hover {
        background: rgba(61, 0, 64, 0.15);
    }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
`;

export const CheckBoxIcon = styled.svg`
    fill: none;
    stroke: #0369a1;
    stroke-width: 2px;
`;

export const StyledCheckbox = styled.div`
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 1px;
    border: 1px solid #0369a1;
    background: #fff;
    border-radius: 4px;
    transition: all 150ms;

    ${CheckBoxIcon} {
        visibility: ${(props: Record<string, any>) =>
            props['data-checked'] ? 'visible' : 'hidden'};
    }
`;
