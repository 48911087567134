import { BalanceContextType, BalanceStateType } from './types';

export const defaultBalanceState: BalanceStateType = {
    loading: false,
    data: [],
};

export const defaultBalanceContextValue: BalanceContextType = {
    state: defaultBalanceState,
    getBalances: () => {},
};
