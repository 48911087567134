import React, { FC } from 'react';
import { Loader } from './Loader/Loader';
import { ButtonElement, LinkButton, SmallButtonElement } from './Button.styled';
import { ButtonSize, ButtonType } from './types';

export const Button: FC<ButtonType> = ({
    variant = 'primary',
    children,
    loading,
    size,
    to,
    style,
    ...props
}) => {
    return to ? (
        <LinkButton to={to} variant={variant} {...props} style={style}>
            {loading ? <Loader /> : children}
        </LinkButton>
    ) : size !== ButtonSize.Small ? (
        <ButtonElement variant={variant} {...props}>
            {loading ? <Loader /> : children}
        </ButtonElement>
    ) : (
        <SmallButtonElement variant={variant} {...props}>
            {loading ? <Loader /> : children}
        </SmallButtonElement>
    );
};
