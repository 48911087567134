import { defaultTransactionState } from './default';
import {
    TransactionActionsOptions,
    TransactionActionsType,
    TransactionStateType,
} from './types';

export const authReducer = (
    state: TransactionStateType,
    { type, payload }: TransactionActionsType
): TransactionStateType => {
    switch (type) {
        case TransactionActionsOptions.Default:
            return defaultTransactionState;

        case TransactionActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
