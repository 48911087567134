import React, {
    createContext,
    useReducer,
    useCallback,
    FC,
    useEffect,
} from 'react';
import { coinForBarterRequest, MethodTypes } from 'services';
import { useAuth, useToast } from 'hooks';
import { authReducer } from './reducer';
import {
    NotificationActionsOptions,
    NotificationContextType,
    NotificationProviderType,
    NotificationType,
} from './types';
import {
    defaultNotificationContextValue,
    defaultNotificationState,
} from './default';

export const NotificationContext = createContext<NotificationContextType>(
    defaultNotificationContextValue
);

export const NotificationProvider: FC<NotificationProviderType> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(authReducer, defaultNotificationState);
    const { error, success } = useToast();
    const {
        state: {
            data: { account },
        },
    } = useAuth();
    const getNotifications = useCallback(
        async (params?: {
            from?: string;
            to?: string;
            isRead?: boolean;
            page?: number;
        }) => {
            try {
                dispatch({
                    type: NotificationActionsOptions.Update,
                    payload: { loading: true },
                });
                const queryString = params
                    ? coinForBarterRequest.makeQueryString(params)
                    : '';
                const {
                    data,
                    statusCode,
                    meta,
                } = await coinForBarterRequest.call(
                    `/notifications${queryString}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    dispatch({
                        type: NotificationActionsOptions.Update,
                        payload: {
                            notifications: data.notifications,
                            totalUnread: data.totalUnread,
                            loading: false,
                            meta: meta,
                        },
                    });
                }
            } catch (e) {}
        },
        []
    );

    const readAll = useCallback(async () => {
        try {
            dispatch({
                type: NotificationActionsOptions.Update,
                payload: { loading: true },
            });
            const { statusCode } = await coinForBarterRequest.call(
                `/notifications/read`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: NotificationActionsOptions.Update,
                    payload: { loading: false },
                });
                success('All notifications read');
            } else {
            }
        } catch (e) {
            error('An error occurred, please try again');
        }
    }, [error, success]);

    const readNotification = useCallback(async (id: string) => {
        try {
            dispatch({
                type: NotificationActionsOptions.Update,
                payload: { loading: true },
            });
            const { statusCode } = await coinForBarterRequest.call(
                `/notifications/read/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: NotificationActionsOptions.Update,
                    payload: { loading: false },
                });
            }
        } catch (e) {}
    }, []);

    const getNotification = useCallback(
        async (id: string): Promise<NotificationType | null> => {
            try {
                dispatch({
                    type: NotificationActionsOptions.Update,
                    payload: { loading: true },
                });
                const { data, statusCode } = await coinForBarterRequest.call(
                    `/notifications/${id}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    dispatch({
                        type: NotificationActionsOptions.Update,
                        payload: { loading: false },
                    });
                    return data;
                }

                return null;
            } catch (e) {
                return null;
            }
        },
        []
    );

    useEffect(() => {
        const abortController = new AbortController();
        getNotifications({ page: state.meta?.pagination.page || 1 });
        return abortController.abort();
    }, [getNotifications, state.meta?.pagination.page, account]);

    return (
        <NotificationContext.Provider
            value={{
                state,
                readAll,
                readNotification,
                getNotification,
                getNotifications,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
