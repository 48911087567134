import { CurrencyOptions, CurrencyType, MiscProvider } from 'context';
import { useMisc } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { convertNumberToStringWithCommas } from 'utils';

export const BalanceDisplay: FC<BalanceDisplayProps> = (props) => {
    return (
        <MiscProvider>
            <Index {...props} />
        </MiscProvider>
    );
};

const Index: FC<BalanceDisplayProps> = ({ amount, currency }) => {
    const [cy, setCy] = useState<CurrencyType | null>(null);
    const {
        state: {
            data: { currencies },
        },
    } = useMisc();

    useEffect(() => {
        const abort = new AbortController();
        setCy(
            currencies?.find(
                (item: CurrencyType) => item.abbreviation === currency
            ) || null
        );
        return abort.abort();
    }, [currencies, currency]);

    return (
        <span>
            {cy?.type === CurrencyOptions.Fiat ? cy?.symbol : ''}{' '}
            {convertNumberToStringWithCommas(
                Math.floor(amount * 10 ** (cy?.decimals || 8)) /
                    10 ** (cy?.decimals || 8)
            )}{' '}
            {cy?.type === CurrencyOptions.Crypto ? cy?.abbreviation : ''}
        </span>
    );
};

interface BalanceDisplayProps {
    currency: string;
    amount: number;
}
