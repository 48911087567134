import React, { FC } from 'react';

export const Footer: FC = () => {
    const link = ' text-small text-grey-300';
    return (
        <footer
            className="flex flex-row wrap justify-start flex-wrap gap-4 p-4 border-t mt-8 pb-16"
            style={{ borderColor: 'grey' }}
        >
            <a
                href={
                    process.env.REACT_APP_DEVELOPERS_URL ||
                    'https://developers.coinforbarter.com'
                }
                rel="noreferrer"
                target="_blank"
                className={link}
            >
                Docs
            </a>

            <a
                href={
                    process.env.REACT_APP_SUPPORT_URL ||
                    'https://support.coinforbarter.com'
                }
                rel="noreferrer"
                target="_blank"
                className={link}
            >
                Support
            </a>

            <a
                href={
                    process.env.REACT_APP_STATUS_URL ||
                    'https://status.coinforbarter.com'
                }
                rel="noreferrer"
                target="_blank"
                className={link}
            >
                Status
            </a>

            <a
                href={`${
                    process.env.REACT_APP_WEBSITE_URL ||
                    'https://coinforbarter.com'
                }/terms`}
                rel="noreferrer"
                target="_blank"
                className={link}
            >
                Privacy Policy
            </a>

            <a
                href={
                    process.env.REACT_APP_WEBSITE_URL ||
                    'https://coinforbarter.com'
                }
                rel="noreferrer"
                target="_blank"
                className={link}
            >
                &copy; CoinForBarter
            </a>
        </footer>
    );
};

export default Footer;
