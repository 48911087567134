import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { motion } from 'framer-motion';

const ModalContainer = styled(motion.div)`
    background: #00000050;
    overflow-y: hidden;
    backdrop-filter: blur(5px);
    z-index: 20;
    padding: 20px 5vw;
    box-sizing: border-box;
`;

// fixed top-0 left-0 flex items-center justify-center w=full h-screen pt-4 px-4 text-center
export const Container = tw(ModalContainer)`
    fixed inset-0 flex items-center justify-center
`;

export const ModalMain = tw.div`
sm:my-8
sm:align-middle
sm:max-w-xl
sm:w-full
sm:px-10
sm:py-5
box-border
w-full
relative
flex
flex-col
justify-between
bg-white
rounded-md
shadow-xl
transform
transition-all
box-border
px-6
sm:py-5
py-4
`;
export const ModalCloseButton = tw.button`
    outline-none 
    absolute
    -top-4
    -right-4 
    focus:outline-none 
    self-end
`;
export const ModalRoot = styled.div`
    position: sticky;
    bottom: 0;
    padding: 10px 0;
`;

export const ModalTop = styled.div`
    position: sticky;
    top: 0;
    padding: 10px 0;
`;

export const ModalBody = styled.div`
    overflow: hidden;
    overflow-y: auto;
    max-height: 60vh;
    padding: 10px 0;
`;
