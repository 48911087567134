import React, {
    ChangeEventHandler,
    // FormEventHandler,
    useEffect,
    useState,
    FC,
} from 'react';
import { pageVariant } from 'constants/framer';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { Button, Input, Password } from 'components';
import { useAuth } from 'hooks';
import { AuthLoginValuesType } from 'context/auth/types';
import { defaultLoginValue } from './default';
import { Title, ForgotPasswordLink, ButtonWrapper } from '../styled';

export const Login: FC<{ blockOptions?: boolean }> = ({ blockOptions }) => {
    const {
        login,
        state: {
            errors,
            loading,
            toVerify,
            authenticated,
            data: {
                user: { email },
            },
        },
    } = useAuth();
    const [values, setValues] = useState<AuthLoginValuesType>({
        ...defaultLoginValue,
        email,
    });
    const history = useHistory();

    useEffect(() => {
        if (toVerify) {
            history.push(`${routes.verify}?email=${values.email}`);
        }
    }, [authenticated, history, toVerify, values]);

    // const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {

    // };

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            variants={pageVariant}
        >
            {!blockOptions && <Title>Login</Title>}

            <form>
                {!blockOptions && (
                    <Input
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleInputChange}
                        errors={errors?.email}
                        id="Email"
                        disabled={loading}
                        label="Email"
                    />
                )}
                <Password
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleInputChange}
                    errors={errors?.password}
                    disabled={loading}
                    id="password"
                    label="Password"
                />

                <ButtonWrapper style={{ justifyContent: 'flex-end' }}>
                    <Button
                        type="submit"
                        loading={loading}
                        onClick={(e) => {
                            e.preventDefault();
                            login(values);
                        }}
                        disabled={loading}
                    >
                        Login
                    </Button>
                </ButtonWrapper>
            </form>

            {!blockOptions && (
                <ButtonWrapper className="mt-4">
                    <ForgotPasswordLink to={routes.register}>
                        Create Account
                    </ForgotPasswordLink>
                    <ForgotPasswordLink to={routes.forgotPassword}>
                        Forgot Password?
                    </ForgotPasswordLink>
                </ButtonWrapper>
            )}
        </motion.div>
    );
};
