import React, {
    createContext,
    useReducer,
    useCallback,
    FC,
    useEffect,
} from 'react';
import { useAuth } from 'hooks';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    BalanceActionsOptions,
    BalanceContextType,
    BalanceProviderType,
} from './types';
import { defaultBalanceContextValue, defaultBalanceState } from './default';

export const BalanceContext = createContext<BalanceContextType>(
    defaultBalanceContextValue
);

export const BalanceProvider: FC<BalanceProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultBalanceState);
    const {
        state: {
            data: { account },
        },
    } = useAuth();
    const getBalances = useCallback(async () => {
        try {
            dispatch({
                type: BalanceActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/balances`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: BalanceActionsOptions.Update,
                    payload: { data: data, loading: false },
                });
            }
        } catch (e) {
            dispatch({
                type: BalanceActionsOptions.Update,
                payload: { loading: false },
            });
        }
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        getBalances();
        return abortController.abort();
    }, [getBalances, account]);

    return (
        <BalanceContext.Provider
            value={{
                state,
                getBalances,
            }}
        >
            {children}
        </BalanceContext.Provider>
    );
};
