/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
    createContext,
    useState,
    FC,
    useEffect,
    useCallback,
} from 'react';

import './index.css';
import SuccessBars from '@assets/images/success-notification.svg';
import ErrorBars from '@assets/images/error-notification.svg';
import Close from '@assets/images/close.svg';
import { ToastType, Props } from './types';
import { toastActions } from './default';

export const ToastContext = createContext<ToastType>(toastActions);

export const ToastProvider: FC<Props> = ({ children }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const error = useCallback((message: string) => {
        setIsSuccess(false);
        setMessage(message);
    }, []);

    const success = useCallback((message: string) => {
        setIsSuccess(true);
        setMessage(message);
    }, []);

    const showToast = () => {
        setTimeout(() => {
            setMessage('');
        }, 5000);
    };

    useEffect(() => {
        if (message) {
            showToast();
        }
    }, [message]);

    return (
        <ToastContext.Provider value={{ success, error }}>
            {message && (
                <div className="fixed top-0 right-0 z-50">
                    <div className="flex m-8 notification p-4 relative">
                        <img
                            src={Close}
                            alt="close"
                            onClick={(e) => {
                                e.preventDefault();
                                setMessage('');
                            }}
                            className="absolute top-0 right-0 m-4 cursor-pointer"
                        />
                        {isSuccess && (
                            <div className="flex items-center justify-start">
                                <div className="mr-4">
                                    <img
                                        className="block h-12"
                                        src={SuccessBars}
                                        alt="success"
                                    />
                                </div>
                                <div>
                                    <h3 style={{ color: 'green' }}>Success</h3>
                                    <p>
                                        {message.replace(
                                            message[0],
                                            message[0].toUpperCase()
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}

                        {!isSuccess && (
                            <div className="flex items-center justify-start">
                                <div className="mr-4">
                                    <img
                                        className="block h-12"
                                        src={ErrorBars}
                                        alt="error"
                                    />
                                </div>
                                <div>
                                    <h3 style={{ color: 'red' }}>Error!</h3>
                                    <p>
                                        {message.replace(
                                            message[0],
                                            message[0].toUpperCase()
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {children}
        </ToastContext.Provider>
    );
};
