import React, { useEffect, useState, FC } from 'react';
import { Button } from 'components';
import SuccessIcon from '@assets/icons/success.gif';
import ErrorIcon from '@assets/icons/error.svg';
import { routes } from 'routes';
import { useAuth } from 'hooks';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { pageVariant } from 'constants/framer';
import { coinForBarterRequest } from 'services';
import { usePreloader } from 'hooks/usePreloader';
import { ButtonWrapper, Title, ForgotPasswordLink } from '../styled';

export const VerifyEmail: FC = () => {
    const {
        resendVerificationEmail,
        state: { loading: authLoading },
    } = useAuth();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [statusCode, setStatusCode] = useState<number>(0);
    const { setIsLoading } = usePreloader();

    const history = useHistory();

    const verifyEmail = async (token: string) => {
        setLoading(true);
        const { message, statusCode } = await coinForBarterRequest.call(
            `/auth/email/verify/${token}`
        );
        setMessage(message);
        setLoading(false);
        setStatusCode(statusCode);
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (!params.email) {
            history.push(routes.login);
        } else {
            setEmail(params.email);
        }

        if (!params.token) {
            history.push(routes.login);
        } else {
            setToken(params.token);
        }
    }, [history, email]);

    useEffect(() => {
        if (token) {
            verifyEmail(token);
        }
    }, [token]);

    useEffect(() => {
        setIsLoading(loading);
    }, [loading, setIsLoading]);

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            variants={pageVariant}
            className="text-center py-5"
        >
            {statusCode !== 0 && (
                <img
                    src={statusCode === 200 ? SuccessIcon : ErrorIcon}
                    alt="Email verification success icon"
                    className="m-auto my-6 w-16  p-2 rounded-lg"
                />
            )}

            {statusCode !== 200 && statusCode !== 0 && (
                <Title className="py-2 text-redText text-2xl">Oops!</Title>
            )}

            {statusCode === 200 && (
                <Title className="py-2 text-redText text-2xl">
                    Congratulations
                </Title>
            )}

            <p
                className=" ml-2 text-secondary-700 text-tiny"
                style={{
                    fontSize: '14px',
                }}
            >
                {message}
            </p>

            <ButtonWrapper>
                <ForgotPasswordLink to={routes.login}>Login</ForgotPasswordLink>
                {statusCode !== 200 && (
                    <Button
                        disabled={authLoading}
                        loading={authLoading}
                        onClick={() => {
                            if (resendVerificationEmail) {
                                resendVerificationEmail(email);
                            }
                        }}
                    >
                        Resend Verification Email
                    </Button>
                )}
            </ButtonWrapper>
        </motion.div>
    );
};
