import { defaultAuthState } from './default';
import { AuthActionsOptions, AuthActionsType, AuthStateType } from './types';

export const authReducer = (
    state: AuthStateType,
    { type, payload }: AuthActionsType
): AuthStateType => {
    switch (type) {
        case AuthActionsOptions.Default:
            return defaultAuthState;

        case AuthActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
