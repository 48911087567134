import React, {
    createContext,
    useReducer,
    useCallback,
    FC,
    useEffect,
} from 'react';
import { useToast } from 'hooks';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    AccountProfileActionsOptions,
    AccountProfileContextType,
    AccountProfileProviderType,
    UpdateAccountProfileType,
} from './types';
import {
    defaultAccountProfileContextValue,
    defaultAccountProfileState,
} from './default';

export const AccountProfileContext = createContext<AccountProfileContextType>(
    defaultAccountProfileContextValue
);

export const AccountProfileProvider: FC<AccountProfileProviderType> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(
        authReducer,
        defaultAccountProfileState
    );
    const { error, success } = useToast();

    const getAccountProfile = useCallback(async () => {
        try {
            dispatch({
                type: AccountProfileActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/account-profile`,
                MethodTypes.Get,
                undefined,
                true
            );

            if (statusCode === 200) {
                dispatch({
                    type: AccountProfileActionsOptions.Update,
                    payload: { data: data, loading: false },
                });
            }
            dispatch({
                type: AccountProfileActionsOptions.Update,
                payload: { loading: false },
            });
        } catch (e) {
            dispatch({
                type: AccountProfileActionsOptions.Update,
                payload: { loading: false },
            });
        }
    }, []);

    const updateAccountProfile = useCallback(
        async (values: UpdateAccountProfileType) => {
            try {
                dispatch({
                    type: AccountProfileActionsOptions.Update,
                    payload: { loading: true },
                });
                const {
                    data,
                    statusCode,
                    message,
                } = await coinForBarterRequest.call(
                    `/account-profile`,
                    MethodTypes.Post,
                    values,
                    true
                );

                if (statusCode === 201) {
                    success('Profile successfully updated');
                    dispatch({
                        type: AccountProfileActionsOptions.Update,
                        payload: {
                            data: data,
                            loading: false,
                        },
                    });
                } else if (statusCode === 400) {
                    error('Validation error');
                    dispatch({
                        type: AccountProfileActionsOptions.Update,
                        payload: {
                            errors: data.errors,
                            loading: false,
                        },
                    });
                } else {
                    error(message);
                    dispatch({
                        type: AccountProfileActionsOptions.Update,
                        payload: {
                            loading: false,
                        },
                    });
                }
            } catch (er) {
                dispatch({
                    type: AccountProfileActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
        },
        [error, success]
    );

    useEffect(() => {
        const abortController = new AbortController();
        getAccountProfile();
        return abortController.abort();
    }, [getAccountProfile]);

    return (
        <AccountProfileContext.Provider
            value={{
                state,
                getAccountProfile,
                updateAccountProfile,
            }}
        >
            {children}
        </AccountProfileContext.Provider>
    );
};
