/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MetaType, RequestResponseSchema, ServerErrorType } from 'services';

export interface BankAccountContextType {
    state: Record<string, any>;
    findAll: (
        params?: BankAccountsQueryType
    ) => Promise<{
        bankAccounts: BankAccountType[];
        meta: MetaType | null;
    } | null>;
    findOne: (id: string) => Promise<BankAccountType | null>;
    archive: (id: string) => Promise<boolean>;
    makePrimary: (id: string) => Promise<boolean>;
    getAccountName: (params: GetAccountNameType) => Promise<string | null>;
    create: (
        body: CreateBankAccountType
    ) => Promise<RequestResponseSchema<DataResponse> | null>;
    getBanks: (country: string) => void;
}

export interface DataResponse extends BankAccountType {
    errors: ServerErrorType;
}

export interface BankAccountProviderType {
    children: JSX.Element;
}

export type BankAccountStateType = {
    loading: boolean;
    banks?: BankType[];
    meta?: MetaType | null;
};

export type BankAccountActionsType = {
    type: BankAccountActionsOptions;
    payload: Partial<BankAccountStateType>;
};

export enum BankAccountActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface BankAccountType {
    country: string;
    currency: string;
    accountNumber: string;
    accountName: string;
    accountBank: string;
    bankCode: number;
    swiftCode: number;
    isPrimary: boolean;
    _id: string;
}
export interface BankType {
    id: string;
    code: string;
    name: string;
}

export interface BankAccountsQueryType {
    from?: string;
    to?: string;
    isPrimary?: boolean;
    page?: number;
    country?: string;
    currency?: string;
    skip?: number;
    accountBank?: string;
}

export interface GetAccountNameType {
    accountNumber: string;
    accountBank: string;
}

export interface CreateBankAccountType {
    isPrimary: boolean;
    accountBank: string;
    accountNumber: string;
    country: string;
    currency: string;
    accountName: string;
}
