/* eslint-disable no-unused-vars */
export interface MiscContextType {
    state: MiscStateType;
    countries: () => void;
    media: () => void;
    occupations: () => void;
}

export interface MiscProviderType {
    children: JSX.Element;
}

export type MiscStateType = {
    loading: boolean;
    data: MiscStateDataType;
};

export type MiscActionsType = {
    type: MiscActionsOptions;
    payload: Partial<MiscStateDataType> | Partial<MiscStateType>;
};

export enum MiscActionsOptions {
    Update = 'update',
    Default = 'default',
    Data = 'data',
}

export interface MiscStateDataType {
    countries: CountriesType[];
    media: MediaType[];
    occupations: OccupationType[];
    currencies?: CurrencyType[];
}

export interface CountriesType {
    name: string;
    code: string;
    currency?: {
        code: string;
        name: string;
        symbol: string;
    };
    flag: string;
}

export interface MediaType {
    name: string;
}

export interface OccupationType {
    name: string;
}

export interface CurrencyType {
    currency: string;

    symbol: string;

    abbreviation: string;

    isAvailable: boolean;

    code: number;

    decimals: number;

    networks: string[];
    type: CurrencyOptions;
}
export enum CurrencyOptions {
    Fiat = 'fiat',
    Crypto = 'crypto',
}
