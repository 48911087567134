import { InfoOutlined } from '@mui/icons-material';
import React, { FC, useEffect, useState } from 'react';
import { Input } from './Input';
import { Validation } from './Input.styled';
import { PasswordPropsType } from './types';

export const Password: FC<PasswordPropsType> = (props) => {
    const [validation, setValidation] = useState({
        isEightCharactersLong: false,
        isHasUppercase: false,
        isHasLowercase: false,
        isHasDigit: false,
    });

    useEffect(() => {
        setValidation((current) => ({
            ...current,
            isEightCharactersLong: new RegExp('(?=.{8,})').test(
                props.value || ''
            ),
            isHasUppercase: new RegExp('(?=.*[A-Z])').test(props.value || ''),
            isHasLowercase: new RegExp('(?=.*[a-z])').test(props.value || ''),
            isHasDigit: new RegExp('(?=.*[0-9])').test(props.value || ''),
        }));
    }, [props.value]);
    return (
        <>
            <Input
                type="password"
                {...{
                    ...props,
                    errors: props.errors?.filter(
                        (error) => !error.includes('uppercase')
                    ),
                }}
            />
            {props.showHint && props.value && (
                <div>
                    <Validation>
                        <span className="flex-2">
                            <InfoOutlined
                                fontSize="small"
                                className="mr-2"
                                color={
                                    validation.isEightCharactersLong
                                        ? 'success'
                                        : 'error'
                                }
                            />
                        </span>
                        <span
                            className="flex-10"
                            style={{
                                color: validation.isEightCharactersLong
                                    ? '#2e7d32'
                                    : 'rgba(207, 0, 0, 1)',
                            }}
                        >
                            Must be 8 characters long
                        </span>
                    </Validation>

                    <Validation>
                        <span className="flex-2">
                            <InfoOutlined
                                fontSize="small"
                                className="mr-2"
                                color={
                                    validation.isHasUppercase
                                        ? 'success'
                                        : 'error'
                                }
                            />
                        </span>
                        <span
                            className="flex-10"
                            style={{
                                color: validation.isHasUppercase
                                    ? '#2e7d32'
                                    : 'rgba(207, 0, 0, 1)',
                            }}
                        >
                            Must contain an upper case
                        </span>
                    </Validation>
                    <Validation>
                        <span className="flex-2">
                            <InfoOutlined
                                fontSize="small"
                                className="mr-2"
                                color={
                                    validation.isHasLowercase
                                        ? 'success'
                                        : 'error'
                                }
                            />
                        </span>
                        <span
                            className="flex-10"
                            style={{
                                color: validation.isHasLowercase
                                    ? '#2e7d32'
                                    : 'rgba(207, 0, 0, 1)',
                            }}
                        >
                            Must contain a lower case
                        </span>
                    </Validation>

                    <Validation>
                        <span className="flex-2">
                            <InfoOutlined
                                fontSize="small"
                                className="mr-2"
                                color={
                                    validation.isHasDigit ? 'success' : 'error'
                                }
                            />
                        </span>
                        <span
                            className="flex-10"
                            style={{
                                color: validation.isHasDigit
                                    ? '#2e7d32'
                                    : 'rgba(207, 0, 0, 1)',
                            }}
                        >
                            Must contain a number
                        </span>
                    </Validation>
                </div>
            )}
        </>
    );
};
