import React, { FC, useEffect } from 'react';
import { preloaderVariant } from 'constants/framer';
import Image from '@assets/images/logo-white.svg';
import './index.css';
import { motion } from 'framer-motion';
import { usePreloader } from 'hooks/usePreloader';

export const Preloader: FC<{ full?: boolean }> = ({ full }) => {
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            key="preloader"
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            variants={preloaderVariant}
            style={{
                background: full
                    ? 'linear-gradient(77.02deg, rgb(61, 0, 64) 0%, rgb(64, 0, 67) 50.8%, rgb(112, 0, 118) 100.56%)'
                    : 'linear-gradient(77.02deg, rgb(61, 0, 64, 0.7) 0%, rgb(64, 0, 67, 0.7) 50.8%, rgb(112, 0, 118, 0.7) 100.56%)',
            }}
            className="preloader flex justify-center items-center fixed inset-0 z-50"
        >
            <img src={Image} alt="CoinForBarter Preloader" />
        </motion.div>
    );
};

export const SuspenseLoader: FC<{ full?: boolean }> = () => {
    const { setIsLoading } = usePreloader();
    useEffect(() => {
        setIsLoading(null);
    }, [setIsLoading]);

    useEffect(() => {
        return () => {
            setIsLoading(false);
        };
    }, [setIsLoading]);
    return <Preloader full />;
};
