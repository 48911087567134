/* eslint-disable no-unused-vars */
import { ServerErrorType } from 'services';

export interface SettingsContextType {
    state: SettingsStateType;
    getSettings: () => void;
    updateSettings: (params: SettingsStateDataType) => void;
}

export interface SettingsProviderType {
    children: JSX.Element;
}

export type SettingsStateType = {
    loading: boolean;
    data: SettingsStateDataType;
    errors?: ServerErrorType;
};

export type SettingsActionsType = {
    type: SettingsActionsOptions;
    payload: Partial<SettingsStateType>;
};

export enum SettingsActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface SettingsStateDataType {
    transactionEmails: boolean;
    transactionEmailsCustomers: boolean;
    isCustomerToPayFee: boolean;
    loginTwoFactorAuth: boolean;
    transferTwoFactorAuth: boolean;
}
