import { FiatWalletContextType, FiatWalletStateType } from './types';

export const defaultFiatWalletContextValue: FiatWalletContextType = {
    state: {},
    findAll: async () => null,
    findOne: async () => null,
    archive: async () => false,
    makePrimary: async () => false,
    create: async () => null,
    getWallets: () => {},
};

export const defaultFiatWalletState: FiatWalletStateType = {
    loading: false,
};
