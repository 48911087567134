import React, { createContext, useState, FC, useEffect } from 'react';
import { StorageTypes } from 'utils';
import { TestType, TestContextProps } from './types';
import { testActions } from './default';

export const TestContext = createContext<TestType>(testActions);

export const TestProvider: FC<TestContextProps> = ({ children }) => {
    const [isTest, setIsTest] = useState(
        sessionStorage.getItem(StorageTypes.Test) === 'true'
    );

    useEffect(() => {
        if (isTest) {
            sessionStorage.setItem(StorageTypes.Test, 'true');
        } else {
            sessionStorage.removeItem(StorageTypes.Test);
        }
    }, [isTest]);

    return (
        <TestContext.Provider value={{ isTest, setIsTest }}>
            {children}
        </TestContext.Provider>
    );
};
