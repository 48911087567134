import React, { FC, MouseEventHandler } from 'react';
import { createPortal } from 'react-dom';
import { modalVariant } from 'constants/framer';
import { ReactComponent as Close } from '@assets/icons/close.svg';
import {
    Container,
    ModalMain,
    ModalCloseButton,
    ModalTop,
    ModalRoot,
    ModalBody,
} from './Modal.styled';
import { ModalType } from './types';

const modalRoot = document.querySelector('#modal-root') as HTMLElement;

export const Modal: FC<ModalType> = ({
    isModalVisible,
    toggleModal,
    closable,
    children,
    headerComponent,
    footerComponent,
}) => {
    const handleClose: MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        const modal = document.querySelector('#modal-root > div');
        if (e.target === modal) {
            toggleModal();
        }
    };

    return isModalVisible
        ? createPortal(
              <Container
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  transition={{ duration: 0.1, ease: 'easeInOut' }}
                  variants={modalVariant}
                  onClick={handleClose}
              >
                  <ModalMain>
                      {closable && (
                          <ModalCloseButton>
                              <Close onClick={toggleModal} />
                          </ModalCloseButton>
                      )}
                      {headerComponent && (
                          <ModalTop>{headerComponent}</ModalTop>
                      )}
                      <ModalBody>{children}</ModalBody>
                      {footerComponent && (
                          <ModalRoot>{footerComponent}</ModalRoot>
                      )}
                  </ModalMain>
              </Container>,
              modalRoot
          )
        : null;
};
