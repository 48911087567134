import { RequestResponseSchema, ServerErrorType } from 'services';

/* eslint-disable no-unused-vars */
export interface WithdrawalContextType {
    state: WithdrawalStateType;
    create: (
        params: Partial<WithdrawalType>
    ) => Promise<RequestResponseSchema<WithdrawalResponse> | null>;
    transfer: (
        params: Partial<WithdrawalType>
    ) => Promise<RequestResponseSchema<WithdrawalResponse> | null>;
    findAll: (
        params?: Partial<WithdrawalQueryType>
    ) => Promise<RequestResponseSchema<WithdrawalType[]> | null>;
    findOne: (
        id: string
    ) => Promise<RequestResponseSchema<WithdrawalResponse> | null>;
    getFee: (
        params: Partial<WithdrawalType>
    ) => Promise<RequestResponseSchema<FeeResponse> | null>;

    getOtp: () => Promise<RequestResponseSchema<{ token: string }> | null>;
}

interface WithdrawalResponse extends WithdrawalType {
    errors: ServerErrorType | null;
}

interface FeeResponse extends FeeType {
    errors: ServerErrorType | null;
}

export interface WithdrawalProviderType {
    children: JSX.Element;
}

export type WithdrawalStateType = {
    loading: boolean;
    withdrawals: WithdrawalType[];
};

export type WithdrawalActionsType = {
    type: WithdrawalActionsOptions;
    payload: Partial<WithdrawalStateType>;
};

export enum WithdrawalActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface WithdrawalType {
    amount: number;
    currency: string;
    fee: number;
    addressId: string;
    bankAccountId: string;
    fiatWalletId: string;
    status: WithdrawalStatus;
    token?: string;
    otp?: string;
    address?: string;
    network?: string;
}

export interface FeeType {
    fee: number;
    currency: string;
    amount: number;
}

export enum WithdrawalStatus {
    Declined = 'declined',
    Pending = 'pending',
    Success = 'success',
}

export interface WithdrawalQueryType {
    from: string;
    to: string;
    page: number;
    currency: string;
    status: WithdrawalStatus;
    minAmount: number;
    maxAmount: number;
    bankAccountId: string;
    addressId: string;
    isAscending: boolean;
    skip: number;
    sort: string;
}
