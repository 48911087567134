import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    display: grid;
    transition: all 0.2s;
    overflow: hidden;
    grid-template-columns: ${({ open }: Record<string, unknown>) =>
            open ? '20%' : '62px'} 1fr;
    grid-template-rows: 60px 1fr;

    grid-template-areas:
        'head head head head'
        'sidebar main main main'
        'sidebar main main main'
        'sidebar main main main';

    height: 100vh;
    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            'head'
            'main';
    }
`;

export const Content = styled.div`
    grid-area: main;
    width: 100%;
    border-radius: 10px;
    /* min-height: 80vh; */
    overflow-y: scroll;
    padding: 20px;
    background: #f3f3f3;
    opacity: 1;
    transition: all 0.3s;
    @media (max-width: 600px) {
        padding: 0;
        position: block;
        border-radius: 0px;
        padding: 10px 16px;
        overflow: hidden;
        overflow-y: scroll;
        background: ${({ open }: Record<string, unknown>) =>
            open ? '#c4c4c4' : ''};
        height: 100vh;
    }
`;

const slideDown = keyframes`
  from {
  transform: scaleY(0);
  }
`;

export const Notice = styled.p`
    text-align: center;
    padding: 5px 10px;
    color: #003db5;
    background: #bebeff;
    font-size: 8px;
    transition: all 0.2s;
    transform-origin: top;
    font-weight: 600;
    animation: ${slideDown} 0.2s;
`;
