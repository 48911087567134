import React, {
    createContext,
    useReducer,
    useCallback,
    FC,
    useEffect,
} from 'react';
import { useToast } from 'hooks';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    WebHookActionsOptions,
    WebHookContextType,
    WebHookProviderType,
    WebHookStateDataType,
} from './types';
import { defaultWebHookContextValue, defaultWebHookState } from './default';

export const WebHookContext = createContext<WebHookContextType>(
    defaultWebHookContextValue
);

export const WebHookProvider: FC<WebHookProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultWebHookState);
    const { success, error } = useToast();
    const getWebHooks = useCallback(async () => {
        try {
            dispatch({
                type: WebHookActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/webhooks`,
                MethodTypes.Get,
                undefined,
                true
            );

            if (statusCode === 200) {
                dispatch({
                    type: WebHookActionsOptions.Update,
                    payload: { data: data, loading: false },
                });
            }
        } catch (e) {
            dispatch({
                type: WebHookActionsOptions.Update,
                payload: { loading: false },
            });
        }
    }, []);

    const updateWebHooks = useCallback(
        async (params: WebHookStateDataType) => {
            try {
                dispatch({
                    type: WebHookActionsOptions.Update,
                    payload: { loading: true },
                });
                const {
                    data,
                    statusCode,
                    message,
                } = await coinForBarterRequest.call(
                    `/webhooks`,
                    MethodTypes.Post,
                    params,
                    true
                );
                if (statusCode === 201) {
                    success('Webhooks updated successfully');
                    dispatch({
                        type: WebHookActionsOptions.Update,
                        payload: { data: data, loading: false },
                    });
                } else if (statusCode === 400) {
                    error('Validation error');
                    dispatch({
                        type: WebHookActionsOptions.Update,
                        payload: { errors: data.errors, loading: false },
                    });
                } else {
                    error(message);
                }
            } catch (e) {
                dispatch({
                    type: WebHookActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, try again');
            }
        },
        [error, success]
    );

    useEffect(() => {
        const abortController = new AbortController();
        getWebHooks();
        return abortController.abort();
    }, [getWebHooks]);

    return (
        <WebHookContext.Provider
            value={{
                state,
                getWebHooks,
                updateWebHooks,
            }}
        >
            {children}
        </WebHookContext.Provider>
    );
};
