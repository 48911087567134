import { defaultInvoiceState } from './default';
import {
    InvoiceActionsOptions,
    InvoiceActionsType,
    InvoiceStateType,
} from './types';

export const authReducer = (
    state: InvoiceStateType,
    { type, payload }: InvoiceActionsType
): InvoiceStateType => {
    switch (type) {
        case InvoiceActionsOptions.Default:
            return defaultInvoiceState;

        case InvoiceActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
