import { RequestResponseSchema, ServerErrorType } from 'services';

/* eslint-disable no-unused-vars */
export interface WalletContextType {
    state: WalletStateType;
    create: (
        params: Partial<WalletsType>
    ) => Promise<RequestResponseSchema<WalletResponse> | null>;

    findAll: (
        params?: Partial<WalletQueryType>
    ) => Promise<RequestResponseSchema<WalletsType[]> | null>;

    findOne: (id: string) => Promise<RequestResponseSchema<WalletsType> | null>;

    remove: (id: string) => Promise<RequestResponseSchema<null> | null>;
}

interface WalletResponse extends WalletsType {
    errors: ServerErrorType | null;
}

export interface WalletProviderType {
    children: JSX.Element;
}

export type WalletStateType = {
    loading: boolean;
};

export type WalletActionsType = {
    type: WalletActionsOptions;
    payload: Partial<WalletStateType>;
};

export enum WalletActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface WalletsType {
    accountId: string;
    addresses: WalletAddress[];
    currency: string;
    label: string;
    availableBalance: number;
    totalBalance: number;
    isDeleted: boolean;
    _id: string;
    createdAt: string;
}

export type WalletAddress = {
    network: string;
    address: string;
};

export interface WalletQueryType {
    from: string;
    to: string;
    page: number;
    network: string;
    currency: string;
    address: string;
    label: string;
    skip: number;
    sort: string;
    isAscending: boolean;
}
