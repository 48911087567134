import React, {
    createContext,
    useReducer,
    useCallback,
    FC,
    useEffect,
} from 'react';
import { useToast } from 'hooks';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    ApiKeyActionsOptions,
    ApiKeyContextType,
    ApiKeyProviderType,
} from './types';
import { defaultApiKeyContextValue, defaultApiKeyState } from './default';

export const ApiKeyContext = createContext<ApiKeyContextType>(
    defaultApiKeyContextValue
);

export const ApiKeyProvider: FC<ApiKeyProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultApiKeyState);
    const { error, success } = useToast();

    const getApiKeys = useCallback(async () => {
        try {
            dispatch({
                type: ApiKeyActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/api-keys`,
                MethodTypes.Get,
                undefined,
                true
            );

            if (statusCode === 200) {
                dispatch({
                    type: ApiKeyActionsOptions.Update,
                    payload: { data: data, loading: false },
                });
            }
        } catch (e) {
            dispatch({
                type: ApiKeyActionsOptions.Update,
                payload: { loading: false },
            });
        }

        dispatch({
            type: ApiKeyActionsOptions.Update,
            payload: { loading: false },
        });
    }, []);

    const createApiKeys = useCallback(async () => {
        try {
            dispatch({
                type: ApiKeyActionsOptions.Update,
                payload: { loading: true },
            });
            const {
                data,
                statusCode,
                message,
            } = await coinForBarterRequest.call(
                `/api-keys`,
                MethodTypes.Post,
                undefined,
                true
            );
            if (statusCode === 201) {
                dispatch({
                    type: ApiKeyActionsOptions.Update,
                    payload: { data: data, loading: false },
                });
                success('New Api Keys Created');
                return true;
            }
            error(message);
        } catch (e) {
            dispatch({
                type: ApiKeyActionsOptions.Update,
                payload: { loading: false },
            });
            error('An error occurred, please try again');
        }

        return false;
    }, [error, success]);

    useEffect(() => {
        const abortController = new AbortController();
        getApiKeys();
        return abortController.abort();
    }, [getApiKeys]);

    return (
        <ApiKeyContext.Provider
            value={{
                state,
                getApiKeys,
                createApiKeys,
            }}
        >
            {children}
        </ApiKeyContext.Provider>
    );
};
