/* eslint-disable no-unused-vars */
import { MouseEventHandler } from 'react';

export interface ButtonType {
    children: JSX.Element | string;
    variant?: string;
    loading?: boolean;
    to?: string;
    width?: string;
    type?: string;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLFormElement>;
    size?: ButtonSize;
    style?: Record<string, string>;
}

export enum ButtonSize {
    Small = 'small',
    Large = 'large'
}
