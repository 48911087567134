/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
    createContext,
    useState,
    FC,
    useEffect,
    useCallback,
} from 'react';

import { Modal } from 'components';
import { Login } from 'pages/account';
import { getAuthToken } from 'utils';

export const LoginModalContext = createContext({});

export const LoginModalProvider: FC<{ children: JSX.Element }> = ({
    children,
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    const checkAuthentication = useCallback(() => {
        setInterval(() => {
            if (!getAuthToken()) {
                setIsAuthenticated(false);
            } else {
                setIsAuthenticated(true);
            }
        }, 1000);
    }, []);

    useEffect(() => {
        checkAuthentication();
    }, [checkAuthentication]);

    return (
        <LoginModalContext.Provider value={{}}>
            <Modal
                headerComponent={
                    <h1
                        style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: 'black',
                        }}
                    >
                        Login
                    </h1>
                }
                toggleModal={() => {}}
                isModalVisible={!isAuthenticated}
                closable={false}
            >
                <Login blockOptions={true} />
            </Modal>
            {children}
        </LoginModalContext.Provider>
    );
};
