import { CssBaseline } from '@mui/material';
import { ErrorBoundary } from 'components';
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Providers } from './context';
import { Pages } from './pages';

const App: FC = () => {
    return (
        <ErrorBoundary>
            <CssBaseline />
            <BrowserRouter>
                <Providers>
                    <Pages />
                </Providers>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default App;
