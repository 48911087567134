import React, { FC, useState } from 'react';
import {
    TextField,
    InputAdornment,
    OutlinedInput,
    IconButton,
    FormControl,
    InputLabel,
    Theme,
} from '@mui/material';
import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { makeStyles } from '@mui/styles';
import { Visibility, VisibilityOff, InfoOutlined } from '@mui/icons-material';
import { InputWrapper, Validation } from './Input.styled';
import { InputPropsType, InputPropsTypeOptions } from './types';

const useStyles = makeStyles<
    Theme,
    {
        hasErrors: boolean;
    }
>({
    input: () => ({
        borderRadius: 3,
        width: '100%',
        fontSize: 14,
        color: '#7b7b7b',
        fontWeight: 600,
    }),
});

export const Input: FC<InputPropsType> = ({
    type,
    className,
    errors,
    disabled,
    name,
    placeholder,
    label,
    value,
    onChange,
    id,
    multiline,
    ...props
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const classes = useStyles({
        hasErrors: !!errors && errors.length > 0,
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
        event.preventDefault();
    };
    return (
        <InputWrapper>
            {type === 'password' ? (
                <FormControl
                    variant="outlined"
                    className={`${classes.input} ${className}`}
                >
                    <InputLabel htmlFor={id}>{label}</InputLabel>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        name={name}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        id={id}
                        label={label}
                        color={
                            errors && errors.length > 0 ? 'warning' : undefined
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {!showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        {...props}
                    />
                </FormControl>
            ) : type === 'date' ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                        disabled={disabled}
                        value={value || null}
                        onChange={(d: Date | null) => {
                            onChange({
                                preventDefault: () => {},
                                target: {
                                    name,
                                    value: d ? d.toString() : '',
                                },
                            });
                        }}
                        label={label}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                {...props}
                                fullWidth
                                placeholder="DD/MM/YYYY"
                                color={
                                    errors && errors.length > 0
                                        ? 'warning'
                                        : undefined
                                }
                            />
                        )}
                    />
                </LocalizationProvider>
            ) : (
                <TextField
                    type={type || InputPropsTypeOptions.Text}
                    label={label}
                    InputProps={
                        type === InputPropsTypeOptions.Number
                            ? { inputProps: { min: 0 } }
                            : undefined
                    }
                    name={name}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={`${classes.input} ${className}`}
                    color={errors && errors.length > 0 ? 'warning' : undefined}
                    id={id}
                    multiline={!!multiline}
                    minRows={multiline ? 4 : undefined}
                    {...props}
                />
            )}
            {errors &&
                errors.length > 0 &&
                errors.map((error) => (
                    <Validation key="error">
                        <span className="flex-2">
                            <InfoOutlined fontSize="small" className="mr-2" />
                        </span>
                        <span className="flex-10">{error}</span>
                    </Validation>
                ))}
        </InputWrapper>
    );
};
