import { NotificationContextType, NotificationStateType } from './types';

export const defaultNotificationState: NotificationStateType = {
    loading: false,
    notifications: [],
    totalUnread: 0,
};

export const defaultNotificationContextValue: NotificationContextType = {
    state: defaultNotificationState,
};
