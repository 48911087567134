/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { MetaType, RequestResponseSchema, ServerErrorType } from 'services';

export interface InvoiceContextType {
    state: InvoiceStateType;
    findAll: (
        params?: Partial<InvoicesQueryType>
    ) => Promise<{
        invoices: InvoiceType[];
        meta: MetaType | null;
    } | null>;
    findAllSent: (
        params?: Partial<SentInvoicesQueryType>
    ) => Promise<{
        sentInvoices: SentInvoiceType[];
        meta: MetaType | null;
    } | null>;
    findOne: (id: string) => Promise<InvoiceType | null>;
    findOneSent: (id: string) => Promise<SentInvoiceType | null>;
    remove: (id: string) => Promise<boolean>;
    inviteCustomers: (id: string, emails: string[]) => Promise<boolean>;
    update: (
        id: string,
        params: Partial<InvoiceType>
    ) => Promise<RequestResponseSchema<InvoiceDataResponse> | null>;
    create: (
        body: Partial<InvoiceType>
    ) => Promise<RequestResponseSchema<InvoiceDataResponse> | null>;
    deleteInvoice: (id: string) => Promise<RequestResponseSchema<null> | null>;
}

export interface InvoiceDataResponse extends InvoiceType {
    errors: ServerErrorType;
}

export interface InvoiceProviderType {
    children: JSX.Element;
}

export type InvoiceStateType = {
    loading: boolean;
};

export type InvoiceActionsType = {
    type: InvoiceActionsOptions;
    payload: Partial<InvoiceStateType>;
};

export enum InvoiceActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface InvoiceType {
    currency: string;
    currencies: string[];
    items: InvoiceItem[];
    amount: number;
    notes: string;
    shippingFee: number;
    shippingLocation: string;
    discount: number;
    tax: number;
    total: number;
    dueDate: string;
    redirectUrl: string;
    _id: string;
    createdAt: string;
}

export interface InvoicesQueryType {
    from: string;
    to: string;
    page: number;
}

export interface SentInvoiceType {
    currency: string;
    customer: string;
    currencies: string[];
    items: InvoiceItem[];
    amount: number;
    notes: string;
    shippingFee: number;
    shippingLocation: string;
    discount: number;
    tax: number;
    total: number;
    dueDate: string;
    redirectUrl: string;
    isPayed: boolean;
    _id: string;
    createdAt: string;
}

export interface InvoiceItem {
    item: string;
    quantity: number;
    unitPrice: number;
}

export interface SentInvoicesQueryType {
    from: string;
    to: string;
    page: number;
}
