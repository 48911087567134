import { defaultMiscState } from './default';
import { MiscActionsOptions, MiscActionsType, MiscStateType } from './types';

export const authReducer = (
    state: MiscStateType,
    { type, payload }: MiscActionsType
): MiscStateType => {
    switch (type) {
        case MiscActionsOptions.Default:
            return defaultMiscState;

        case MiscActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        case MiscActionsOptions.Data:
            return {
                ...state,
                data: { ...state.data, ...payload },
            };
        default:
            return state;
    }
};
