import React, {
    createContext,
    useReducer,
    useCallback,
    FC,
    useEffect,
} from 'react';
import { useToast } from 'hooks';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    SettingsActionsOptions,
    SettingsContextType,
    SettingsProviderType,
    SettingsStateDataType,
} from './types';
import { defaultSettingsContextValue, defaultSettingsState } from './default';

export const SettingsContext = createContext<SettingsContextType>(
    defaultSettingsContextValue
);

export const SettingsProvider: FC<SettingsProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultSettingsState);
    const { error, success } = useToast();
    const getSettings = useCallback(async () => {
        try {
            dispatch({
                type: SettingsActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/settings`,
                MethodTypes.Get,
                undefined,
                true
            );

            if (statusCode === 200) {
                dispatch({
                    type: SettingsActionsOptions.Update,
                    payload: { data: data, loading: false },
                });
            }
        } catch (e) {
            dispatch({
                type: SettingsActionsOptions.Update,
                payload: { loading: false },
            });
        }
    }, []);

    const updateSettings = useCallback(
        async (params: SettingsStateDataType) => {
            try {
                dispatch({
                    type: SettingsActionsOptions.Update,
                    payload: { loading: true },
                });
                const {
                    data,
                    statusCode,
                    message,
                } = await coinForBarterRequest.call(
                    `/settings`,
                    MethodTypes.Post,
                    params,
                    true
                );
                if (statusCode === 201) {
                    success('Settings updated successfully');
                    dispatch({
                        type: SettingsActionsOptions.Update,
                        payload: { data: data, loading: false },
                    });
                } else if (statusCode === 401) {
                    error('Validation error');
                    dispatch({
                        type: SettingsActionsOptions.Update,
                        payload: { errors: data.errors, loading: false },
                    });
                } else {
                    error(message);
                }
            } catch (e) {
                dispatch({
                    type: SettingsActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error ocurred, please try again');
            }
        },
        [error, success]
    );

    useEffect(() => {
        const abortController = new AbortController();
        getSettings();
        return abortController.abort();
    }, [getSettings]);

    return (
        <SettingsContext.Provider
            value={{
                state,
                getSettings,
                updateSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};
