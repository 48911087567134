/* eslint-disable no-unused-vars */
export interface ApiKeyContextType {
    state: ApiKeyStateType;
    getApiKeys: () => void;
    createApiKeys: () => Promise<boolean>;
}

export interface ApiKeyProviderType {
    children: JSX.Element;
}

export type ApiKeyStateType = {
    loading: boolean;
    data: ApiKeyStateDataType;
};

export type ApiKeyActionsType = {
    type: ApiKeyActionsOptions;
    payload: Partial<ApiKeyStateType>;
};

export enum ApiKeyActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface ApiKeyStateDataType {
    publicKey: string;
    privateKey: string;
    encryptionKey: string;
}
