import { PaymentLinkContextType, PaymentLinkStateType } from './types';

export const defaultPaymentLinkState: PaymentLinkStateType = {
    loading: false,
};

export const defaultPaymentLinkContextValue: PaymentLinkContextType = {
    state: defaultPaymentLinkState,
    create: async () => null,

    update: async () => null,

    findAll: async () => null,

    findOne: async () => null,

    remove: async () => null,

    getAnalytics: async () => null,

    getAnalyticsByPaymentLink: async () => null,
};
