export const formatDate = (date: string, showTime = false): string => {
    const dateObj = new Date(date);

    const day = dateObj.getDate();

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const month = months[dateObj.getMonth()];

    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();

    return `${day} ${month}, ${dateObj.getFullYear()} ${
        showTime ? `${hours}:${minutes}:${seconds}` : ''
    }`;
};

export const dateDifference = (date: string): string => {
    const dateObj = new Date(date);
    const today = new Date();

    const difference = today.getTime() - dateObj.getTime();

    const days = Math.floor(difference / 86400000);

    const hours = Math.floor((difference - days * 86400000) / 3600000);

    const minutes = Math.floor(
        (difference - hours * 3600000 - days * 86400000) / 60000
    );

    return `${days > 0 ? `${days} Days, ` : ''}${
        hours > 0 ? `${hours} Hours, ` : ''
    } ${minutes > 0 ? ` ${minutes} Minutes` : ''}`;
};
