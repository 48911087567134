import React, { FC, lazy, Suspense } from 'react';
import { useAuth } from 'hooks';
import { ScrollToTop } from 'components/scrollToTop';
import { SuspenseLoader } from 'components';

const ProtectedRoutes = lazy(() => import('routes/ProtectedRoute'));
const GuestRoutes = lazy(() => import('routes/GuestRoutes'));

export const Pages: FC = () => {
    const {
        state: { authenticated },
    } = useAuth();

    return (
        <>
            <ScrollToTop />
            <Suspense fallback={<SuspenseLoader />}>
                {authenticated && <ProtectedRoutes />}
                {!authenticated && <GuestRoutes />}
            </Suspense>
        </>
    );
};
