import styled from 'styled-components/macro';
import tw from 'tailwind-styled-components';

export const AccountDetails = tw.div`
    px-3
    capitalize
    overflow-ellipsis
    overflow-hidden
`;

export const CurrentAccount = tw.div`
    flex
    items-center
    overflow-hidden
    pb-4
`;

export const Avatar = styled.img`
    border-radius: 50%;
    border: 1px solid #c4c4c47a;
    height: 40px;
    width: 40px;
    margin-left: 5px;
    object-fit: cover;
    object-position: top center;
`;

export const Menu = styled.div`
    position: absolute;
    background: #fff;
    border-radius: 8px;
    width: 220px;
    padding: 12px 16px;
    top: 105px;
    right: 10px;
    box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.2);
    &,
    & * {
        opacity: ${(props: Record<string, unknown>) =>
            props.open ? '1' : '0'};
        visibility: ${(props: Record<string, unknown>) =>
            props.open ? 'visible' : 'hidden'};
        transition: opacity 0.3s;
    }

    &:after {
        position: absolute;
        content: '';
        top: -10px;
        opacity: ${(props: Record<string, unknown>) =>
            props.open ? '1' : '0'};
        transition: opacity 0.1s;
        right: 10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 15px solid #fff;
    }
`;

export const MenuItem = styled.li`
    button {
        padding: 8px 24px;
        font-size: 12px;
        margin-bottom: 12px;
    }
    a {
        display: flex;
        // height: 46px;
        align-items: center;
        padding: 12px 24px;
        transition: all 0.1s;
        &:hover {
            cursor: pointer;
            border-radius: 5px;
        }
    }
`;

export const LogoutButton = styled.div`
    padding: 12px 24px;
    font-size: 12px;
    margin-bottom: 12px;
    align-items: center;
    color: red;
    cursor: pointer;
    display: flex;
    outline: none;
`;

export const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 10px;
`;

export const Svg = styled.svg`
    width: 16px;
    height: 16px;
    margin-right: 10px;
`;

export const MenuItemLabel = styled.p`
    font-size: 14px;
    line-height: 1.3;
    text-align: left;
    color: rgba(82, 4, 4, 0.75);
`;
