import React, { createContext, useReducer, useCallback, FC } from 'react';
import { coinForBarterRequest, MethodTypes } from 'services';
import { useToast } from 'hooks';
import { authReducer } from './reducer';
import {
    PaymentLinkActionsOptions,
    PaymentLinkContextType,
    PaymentLinkOptions,
    PaymentLinkProviderType,
    PaymentLinkQueryType,
    PaymentLinkType,
} from './types';
import {
    defaultPaymentLinkContextValue,
    defaultPaymentLinkState,
} from './default';

export const PaymentLinkContext = createContext<PaymentLinkContextType>(
    defaultPaymentLinkContextValue
);

export const PaymentLinkProvider: FC<PaymentLinkProviderType> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(authReducer, defaultPaymentLinkState);
    const { error: toastError } = useToast();
    const create = useCallback(
        async (params: Partial<PaymentLinkType>, type: PaymentLinkOptions) => {
            try {
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: true },
                });
                let subPath = '';
                if (type === PaymentLinkOptions.DonationPage) {
                    subPath = 'donation';
                }
                if (type === PaymentLinkOptions.SubscriptionLink) {
                    subPath = 'subscription-link';
                }
                if (type === PaymentLinkOptions.SingleCharge) {
                    subPath = 'single-charge';
                }
                const response = await coinForBarterRequest.call(
                    `/payment-links/${subPath}`,
                    MethodTypes.Post,
                    params,
                    true
                );
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: false },
                });
                toastError('An error occurred, please try again');
            }
            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: false },
            });
            return null;
        },
        [toastError]
    );

    const update = useCallback(
        async (id: string, params: Partial<PaymentLinkType>) => {
            try {
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: true },
                });

                const response = await coinForBarterRequest.call(
                    `/payment-links/${id}`,
                    MethodTypes.Patch,
                    params,
                    true
                );
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: false },
                });
                toastError('An error occurred, please try again');
            }
            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: false },
            });
            return null;
        },
        [toastError]
    );

    const findAll = useCallback(
        async (params?: Partial<PaymentLinkQueryType>) => {
            try {
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: true },
                });
                const searchParams =
                    coinForBarterRequest.makeQueryString(params || {}) || '';
                const response = await coinForBarterRequest.call(
                    `/payment-links${searchParams}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: false },
                });
            }

            return null;
        },
        []
    );

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: true },
            });

            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: false },
            });
            const response = await coinForBarterRequest.call(
                `/payment-links/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            return response;
        } catch (e) {
            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const remove = useCallback(
        async (id: string) => {
            try {
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: true },
                });

                const response = await coinForBarterRequest.call(
                    `/payment-links/${id}`,
                    MethodTypes.Delete,
                    undefined,
                    true
                );

                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: PaymentLinkActionsOptions.Update,
                    payload: { loading: false },
                });
                toastError('An error occurred, please try again');
            }

            return null;
        },
        [toastError]
    );

    const getAnalytics = useCallback(async () => {
        try {
            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: true },
            });

            const response = await coinForBarterRequest.call(
                `/payment-links/analytics`,
                MethodTypes.Get,
                undefined,
                true
            );

            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const getAnalyticsByPaymentLink = useCallback(async (id: string) => {
        try {
            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: true },
            });

            const response = await coinForBarterRequest.call(
                `/payment-links/analytics/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );

            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: PaymentLinkActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    return (
        <PaymentLinkContext.Provider
            value={{
                state,
                create,
                findAll,
                findOne,
                update,
                remove,
                getAnalytics,
                getAnalyticsByPaymentLink,
            }}
        >
            {children}
        </PaymentLinkContext.Provider>
    );
};
