/* eslint-disable no-unused-vars */
import { ServerErrorType } from 'services';

export interface AccountProfileContextType {
    state: AccountProfileStateType;
    getAccountProfile: () => void;
    updateAccountProfile: (values: UpdateAccountProfileType) => void;
}

export interface AccountProfileProviderType {
    children: JSX.Element;
}

export type AccountProfileStateType = {
    loading: boolean;
    data: AccountProfileStateDataType;
    errors?: ServerErrorType;
};

export type AccountProfileActionsType = {
    type: AccountProfileActionsOptions;
    payload: Partial<AccountProfileStateType>;
};

export enum AccountProfileActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface AccountProfileStateDataType {
    address: string;
    supportEmail: string;
    supportPhoneNumber: string;
    country: string;
    chargeBackEmail: string;
}
export type UpdateAccountProfileType = {
    address?: string;
    supportEmail?: string;
    supportPhoneNumber?: string;
    country?: string;
    chargeBackEmail?: string;
};
