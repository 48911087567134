import { defaultApiKeyState } from './default';
import {
    ApiKeyActionsOptions,
    ApiKeyActionsType,
    ApiKeyStateType,
} from './types';

export const authReducer = (
    state: ApiKeyStateType,
    { type, payload }: ApiKeyActionsType
): ApiKeyStateType => {
    switch (type) {
        case ApiKeyActionsOptions.Default:
            return defaultApiKeyState;

        case ApiKeyActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
