import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import { Link } from 'react-router-dom';

const Container = styled.main`
    background: #3d0040;
`;
export const FullContainer = tw(Container)`
 w-screen h-screen overflow-auto 
`;

export const ContentWrapper = styled.section`
    border: 1px solid #cacaca;
    box-sizing: border-box;
    border-radius: 3px;
    max-width: 575px;
    background: white;
    padding: 40px;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 5vw;
    margin-top: 25px;
`;

export const FirstImg = styled.img`
    height: 80vh;
    width: 100vw;
    max-width: 600px;
    object-fit: contain;
`;

export const LastImg = styled.img`
    height: 60vh;
    object-fit: contain;
    width: 80vw;
    max-width: 400px;
`;

export const Section = tw(styled.section`
    padding: 5vw;
`)`
relative z-index-10 flex flex-col overflow-scroll justify-start items-center min-h-screen
`;

export const ForgotPasswordLink = styled(Link)`
    display: block;
    margin: 20px 0;
    color: white;
    &:hover {
        text-decoration: underline;
    }
`;

export const Img = styled.div`
    max-width: 575px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    img {
        width: 60%;
        min-width: 200px;
        display: block;
    }
    @media (max-width: 600px) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`;
