import { defaultCryptoAddressState } from './default';
import {
    CryptoAddressActionsOptions,
    CryptoAddressActionsType,
    CryptoAddressStateType,
} from './types';

export const authReducer = (
    state: CryptoAddressStateType,
    { type, payload }: CryptoAddressActionsType
): CryptoAddressStateType => {
    switch (type) {
        case CryptoAddressActionsOptions.Default:
            return defaultCryptoAddressState;

        case CryptoAddressActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
