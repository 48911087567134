/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { ServerErrorType } from 'services';

export interface AuthContextType {
    state: AuthStateType;
    login: (values: AuthLoginValuesType) => void;
    logout: () => void;
    register: (values: AuthRegisterValuesType) => void;
    resendVerificationEmail?: (email: string) => void;
    clearState?: () => void;
    getAuthenticatedUser: () => void;
    suspend: () => void;
}

export type AuthLoginValuesType = {
    email: string;
    password: string;
};

export type AuthRegisterValuesType = {
    email: string;
    password: string;
    confirmPassword: string;
    country: string;
    fullName: string;
    tradingName: string;
    howYouHeardAboutUs?: string;
    referralCode?: string;
    occupation?: string;
};

export interface AuthProviderType {
    children: JSX.Element;
}

export type AuthStateType = {
    errors?: ServerErrorType;
    loading: boolean;
    authenticated: boolean;
    data: AuthStateDataType;
    registering: boolean;
    message?: string;
    toVerify?: boolean;
};

export type AuthActionsType = {
    type: AuthActionsOptions;
    payload: Partial<AuthStateType>;
};

export enum AuthActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface AuthStateDataType {
    account: AccountType;
    user: UserType;
}

export interface AccountType {
    userId: string;
    _id: string;
    name: string;
    email: string;
    type: string;
    isDeleted: boolean;
    isPhoneNumberVerified: boolean;
    isEmailVerified: boolean;
    status: AccountStatus;
    isPrimary: boolean;
    phoneNumber: string;
}

export enum AccountStatus {
    Active = 'active',
    Suspended = 'suspended',
}

export interface UserType {
    referralCode: string;
    howYouHeardAboutUs?: string;
    country: string;
    tradingName: string;
    fullName: string;
    emailVerifiedAt: string;
    email: string;
    _id: string;
    updatedAt: string;
    createdAt: string;
}
