class Routes {
    public home = '/';

    public register = '/register';

    public registerWithReferralCode = '/register/:referralCode';

    public login = '/login';

    public verify = '/verify';

    public verifyEmail = '/verify-email';

    public forgotPassword = '/forgot-password';

    public resetPassword = '/reset-password';

    public transactions = '/transactions';

    public transaction = (id?: string): string => {
        return `${this.transactions}/${id || ':id'}`;
    };

    public customers = '/customers';

    public customer = {
        index: `${this.customers}/:id`,
        add: `${this.customers}/add`,
        blacklisted: `${this.customers}/blacklisted`,
        edit: `${this.customers}/:id/edit`,
    };

    public notFound = '/not-found';

    public balancesIndex = '/balances';

    public settingsIndex = '/settings';

    public customize = '/customize';

    public paymentLinks = '/payment-links';

    public paymentPlans = '/payment-plans';

    public wallets = '/wallets';

    public paymentLink = {
        index: `${this.paymentLinks}/:id`,
        add: `${this.paymentLinks}/add`,
        addOption: `${this.paymentLinks}/add/:option`,
        edit: `${this.paymentLinks}/:id/edit`,
    };

    public paymentPlan = {
        index: `${this.paymentPlans}/:id`,
        add: `${this.paymentPlans}/add`,
        edit: `${this.paymentPlans}/:id/edit`,
        addSubscriber: `${this.paymentPlans}/:id/subscribers/add`,
    };

    public wallet = {
        index: `${this.wallets}/:id`,
        add: `${this.wallets}/add`,
    };

    public balance = {
        index: `${this.balancesIndex}/:currency`,
    };

    public invoices = '/invoices';

    public invoice = {
        sent: `${this.invoices}/sent`,
        saved: `${this.invoices}/saved`,
        savedAdd: `${this.invoices}/saved/add`,
        sentIndex: `${this.invoices}/sent/:id`,
        savedIndex: `${this.invoices}/saved/:id`,
        savedEdit: `${this.invoices}/saved/:id/edit`,
        savedSend: `${this.invoices}/saved/:id/send`,
    };

    public payouts = '/payouts';

    public notifications = '/notifications';

    public account = {
        add: '/account/add',
        verify: '/account/verify',
    };

    public notification = '/notifications/:id';

    public suspend = {
        suspend: '/suspend',
        unsuspend: '/unsuspend',
    };

    public payout = {
        index: `${this.payouts}/:id`,
        makeComplaint: `${this.payouts}/:id/complaint/make`,
        seeComplaint: `${this.payouts}/:id/complaint/:complaintId`,
        settings: `${this.payouts}/settings`,
        complaints: `${this.payouts}/complaints`,
    };

    public settings = {
        webhooks: `${this.settingsIndex}/webhooks`,
        profile: `${this.settingsIndex}/profile`,
        cryptoAddresses: `${this.settingsIndex}/crypto-addresses`,
        api: `${this.settingsIndex}/api`,
        accountSettings: `${this.settingsIndex}/account-settings`,
        suspend: `${this.settingsIndex}/account-settings/suspend`,
        bankAccounts: `${this.settingsIndex}/bank-accounts`,
        bankAccount: {
            index: `${this.settingsIndex}/bank-accounts/:id`,
            add: `${this.settingsIndex}/bank-accounts/currency/:currency/add`,
            delete: `${this.settingsIndex}/bank-accounts/:id/delete`,
            primary: `${this.settingsIndex}/bank-accounts/:id/primary`,
        },
        fiatWallets: `${this.settingsIndex}/fiat-wallets`,
        fiatWallet: {
            index: `${this.settingsIndex}/fiat-wallets/:id`,
            add: `${this.settingsIndex}/fiat-wallets/currency/:currency/add`,
            delete: `${this.settingsIndex}/fiat-wallets/:id/delete`,
            primary: `${this.settingsIndex}/fiat-wallets/:id/primary`,
        },
        cryptoAddress: {
            index: `${this.settingsIndex}/crypto-addresses/:id`,
            add: `${this.settingsIndex}/crypto-addresses/currency/:currency/add`,
            delete: `${this.settingsIndex}/crypto-addresses/:id/delete`,
            primary: `${this.settingsIndex}/crypto-addresses/:id/primary`,
        },
        apiKey: {
            add: `${this.settingsIndex}/api/add`,
        },
    };
}

export const routes = new Routes();
