import React, { createContext, useReducer, useCallback, FC } from 'react';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    PaymentPlanSubscriberActionsOptions,
    PaymentPlanSubscriberContextType,
    PaymentPlanSubscriberProviderType,
    PaymentPlanSubscriberQueryType,
    PaymentPlanSubscriberType,
} from './types';
import {
    defaultPaymentPlanSubscriberContextValue,
    defaultPaymentPlanSubscriberState,
} from './default';

export const PaymentPlanSubscriberContext = createContext<PaymentPlanSubscriberContextType>(
    defaultPaymentPlanSubscriberContextValue
);

export const PaymentPlanSubscriberProvider: FC<PaymentPlanSubscriberProviderType> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(
        authReducer,
        defaultPaymentPlanSubscriberState
    );

    const create = useCallback(
        async (params: Partial<PaymentPlanSubscriberType>, id: string) => {
            try {
                dispatch({
                    type: PaymentPlanSubscriberActionsOptions.Update,
                    payload: { loading: true },
                });

                const response = await coinForBarterRequest.call(
                    `/payment-plan-subscribers/${id}`,
                    MethodTypes.Post,
                    params,
                    true
                );
                dispatch({
                    type: PaymentPlanSubscriberActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: PaymentPlanSubscriberActionsOptions.Update,
                    payload: { loading: false },
                });
            }
            dispatch({
                type: PaymentPlanSubscriberActionsOptions.Update,
                payload: { loading: false },
            });
            return null;
        },
        []
    );

    const remove = useCallback(async (id: string) => {
        try {
            dispatch({
                type: PaymentPlanSubscriberActionsOptions.Update,
                payload: { loading: true },
            });

            const response = await coinForBarterRequest.call(
                `/payment-plan-subscribers/${id}`,
                MethodTypes.Delete,
                undefined,
                true
            );
            dispatch({
                type: PaymentPlanSubscriberActionsOptions.Update,
                payload: { loading: false },
            });

            return response;
        } catch (e) {
            dispatch({
                type: PaymentPlanSubscriberActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: PaymentPlanSubscriberActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const findAll = useCallback(
        async (params?: Partial<PaymentPlanSubscriberQueryType>) => {
            try {
                dispatch({
                    type: PaymentPlanSubscriberActionsOptions.Update,
                    payload: { loading: true },
                });
                const searchParams =
                    coinForBarterRequest.makeQueryString(params || {}) || '';
                const response = await coinForBarterRequest.call(
                    `/payment-plan-subscribers/${searchParams}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                dispatch({
                    type: PaymentPlanSubscriberActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: PaymentPlanSubscriberActionsOptions.Update,
                    payload: { loading: false },
                });
            }

            return null;
        },
        []
    );

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: PaymentPlanSubscriberActionsOptions.Update,
                payload: { loading: true },
            });

            dispatch({
                type: PaymentPlanSubscriberActionsOptions.Update,
                payload: { loading: false },
            });
            const response = await coinForBarterRequest.call(
                `/payment-plan-subscribers/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            return response;
        } catch (e) {
            dispatch({
                type: PaymentPlanSubscriberActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);
    return (
        <PaymentPlanSubscriberContext.Provider
            value={{
                state,
                create,
                findAll,
                findOne,
                remove,
            }}
        >
            {children}
        </PaymentPlanSubscriberContext.Provider>
    );
};
