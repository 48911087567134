export const preloaderVariant = {
    hidden: {
        opacity: 1,
        scaleY: 1,
        scaleX: 1,
        backdropFilter: 'blur(5px)',
    },
    visible: {
        opacity: 1,
        scaleY: 1,
        scaleX: 1,
        backdropFilter: 'blur(2px)',
    },
    exit: { opacity: 0, scaleY: 2, scaleX: 2, backdropFilter: 'blur(0)' },
};

export const cardVariant = {
    hidden: {
        opacity: 0,
        y: -50,
    },
    visible: { opacity: 1, y: 0 },
    exit: {
        opacity: 0,
        y: -50,
    },
};

export const pageVariant = {
    hidden: {
        opacity: 0,
        y: -10,
    },
    visible: { opacity: 1, y: 0 },
    exit: {
        opacity: 0,
        y: -10,
    },
};

export const modalVariant = {
    hidden: {
        transformOrigin: 'center',
        opacity: 0,
        scaleY: 1.5,
        scaleX: 1.5,
    },
    visible: { opacity: 1, scaleY: 1, scaleX: 1 },
    exit: {
        opacity: 0,
        scaleY: 1.5,
        scaleX: 1.5,
    },
};
