import { RequestResponseSchema, ServerErrorType } from 'services';

/* eslint-disable no-unused-vars */
export interface PaymentPlanContextType {
    state: PaymentPlanStateType;
    create: (
        params: Partial<PaymentPlanType>
    ) => Promise<RequestResponseSchema<PaymentPlanResponse> | null>;

    update: (
        id: string,
        params: Partial<PaymentPlanType>
    ) => Promise<RequestResponseSchema<PaymentPlanResponse> | null>;

    findAll: (
        params?: Partial<PaymentPlanQueryType>
    ) => Promise<RequestResponseSchema<PaymentPlanType[]> | null>;

    findOne: (
        id: string
    ) => Promise<RequestResponseSchema<PaymentPlanType> | null>;

    getPaymentPlanAnalytics: () => Promise<RequestResponseSchema<null> | null>;

    getAnalyticsByPaymentPlan: (
        id: string
    ) => Promise<RequestResponseSchema<null> | null>;
}

interface PaymentPlanResponse extends PaymentPlanType {
    errors: ServerErrorType | null;
}

export interface PaymentPlanProviderType {
    children: JSX.Element;
}

export type PaymentPlanStateType = {
    loading: boolean;
};

export type PaymentPlanActionsType = {
    type: PaymentPlanActionsOptions;
    payload: Partial<PaymentPlanStateType>;
};

export enum PaymentPlanActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface PaymentPlanType {
    planName: string;
    frequency: PaymentPlanIntervals;
    numberOfTimesToCharge: number;
    amount: number;
    currency: string;
    currencies: string[];
    isActive: boolean;
    createdAt: string;
    _id: string;
}

export interface PaymentPlanQueryType {
    from: string;
    to: string;
    isActive: boolean;
    page: number;
    frequency: PaymentPlanIntervals;
    currency: string;
    maxAmount: number;
    minAmount: number;
    minNumberOfTimesToCharge: number;
    maxNumberOfTimesToCharge: number;
}

export interface PaymentPlanAnalyticType {
    count: number;
    localCurrencyValue: number;
    dollarSum: number;
    activePlans: number;
}

export enum PaymentPlanIntervals {
    Hourly = 'hourly',
    Weekly = 'weekly',
    Daily = 'daily',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    EverySixMonths = 'every six months',
    Yearly = 'yearly',
}
