import { WithdrawalContextType, WithdrawalStateType } from './types';

export const defaultWithdrawalState: WithdrawalStateType = {
    loading: false,
    withdrawals: [],
};

export const defaultWithdrawalContextValue: WithdrawalContextType = {
    state: defaultWithdrawalState,
    create: async () => null,
    transfer: async () => null,
    getOtp: async () => null,
    findAll: async () => null,
    findOne: async () => null,
    getFee: async () => null,
};
