import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
    position: relative;
    margin-right: 20px;
`;

export const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        left: 4px;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }
`;
export const CheckBoxLabel = styled.label`
    position: relative;
    display: block;
    width: 100px;
    height: 30px;

    p {
        position: absolute;
        cursor: pointer;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        opacity: 0;
        transition: 0.4s;
        font-size: 10px;

        &:last-child {
            opacity: 1;
        }
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    input:checked + ${Slider} {
        background-color: #3d0040;

        p:first-child {
            opacity: 1;
        }

        p:last-child {
            opacity: 0;
        }
    }

    input:checked + ${Slider}:before {
        -webkit-transform: translate(76px, -50%);
        -ms-transform: translate(76px, -50%);
        transform: translate(76px, -50%);
    }
`;

export const CheckBox = styled.input`
    opacity: 0;
    z-index: 1;
    border-radius: 15px;
    width: 70px;
    height: 26px;
    &:checked + ${CheckBoxLabel} {
        background: #7b7b7b;
        &::after {
            content: '';
            display: block;
            border-radius: 50%;
            width: 14px;
            height: 14px;
            right: 0;
            margin-left: 66px;
            background: #fff;
            transition: all 0.4s;
        }
        &::before {
            opacity: 1;
            position: absolute;
            content: "You're Live";
            left: 0;
            /* color: '#7B7B7B'; */
            font-size: 10px;
            color: #fff;
            display: block;
            padding: 10px;
            transition-delay: 0.4s;
            /* transition: 0.4s; */
        }
    }
`;
