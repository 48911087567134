/* eslint-disable no-unused-vars */
import { ServerErrorType } from 'services';

export interface WebHookContextType {
    state: WebHookStateType;
    getWebHooks: () => void;
    updateWebHooks: (params: WebHookStateDataType) => void;
}

export interface WebHookProviderType {
    children: JSX.Element;
}

export type WebHookStateType = {
    loading: boolean;
    data: WebHookStateDataType;
    errors?: ServerErrorType;
};

export type WebHookActionsType = {
    type: WebHookActionsOptions;
    payload: Partial<WebHookStateType>;
};

export enum WebHookActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface WebHookStateDataType {
    url: string;
    secretHash: string;
}
