import { defaultPaymentPlanSubscriberState } from './default';
import {
    PaymentPlanSubscriberActionsOptions,
    PaymentPlanSubscriberActionsType,
    PaymentPlanSubscriberStateType,
} from './types';

export const authReducer = (
    state: PaymentPlanSubscriberStateType,
    { type, payload }: PaymentPlanSubscriberActionsType
): PaymentPlanSubscriberStateType => {
    switch (type) {
        case PaymentPlanSubscriberActionsOptions.Default:
            return defaultPaymentPlanSubscriberState;

        case PaymentPlanSubscriberActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
