import React, {
    createContext,
    useEffect,
    useState,
    useReducer,
    useCallback,
    FC,
} from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import {
    getAuthToken,
    getLocationHistory,
    removeLocationHistory,
    StorageTypes,
} from 'utils';
import { coinForBarterRequest, MethodTypes } from 'services';
import { usePreloader } from 'hooks/usePreloader';
import { useToast } from 'hooks';
import { authReducer } from './reducer';
import {
    AuthActionsOptions,
    AuthContextType,
    AuthLoginValuesType,
    AuthProviderType,
    AuthRegisterValuesType,
} from './types';
import { defaultAuthContextValue, defaultAuthState } from './default';

export const AuthContext = createContext<AuthContextType>(
    defaultAuthContextValue
);

export const AuthProvider: FC<AuthProviderType> = ({ children }) => {
    const history = useHistory();
    const [loadingApp, setLoadingApp] = useState(true);
    const [state, dispatch] = useReducer(authReducer, defaultAuthState);
    const { setIsLoading } = usePreloader();
    const { success, error } = useToast();

    const path = '/auth';

    const login = async (values: AuthLoginValuesType) => {
        try {
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { loading: true },
            });
            const {
                data,
                message,
                statusCode,
            } = await coinForBarterRequest.call(
                `${path}/login`,
                MethodTypes.Post,
                { ...values, email: values?.email?.trim() }
            );
            if (statusCode === 200) {
                success('Login successful');
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: {
                        data,
                        loading: false,
                        authenticated: true,
                        errors: undefined,
                    },
                });
                if (sessionStorage.getItem(StorageTypes.Test) === 'true') {
                    location.reload();
                }
            } else if (statusCode === 400) {
                error('Validation error');
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: { errors: data.errors, loading: false },
                });
            } else if (statusCode === 409) {
                error('Please verify your email address');
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: { message, toVerify: true, loading: false },
                });
            } else {
                error(message);
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: { message, loading: false },
                });
            }
        } catch (err) {
            const errorMessage = err?.response?.data?.message || err.message;
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { message: errorMessage, loading: false },
            });
            error('An error ocurred, please try again!');
        }
    };

    const logout = useCallback(async () => {
        try {
            dispatch({ type: AuthActionsOptions.Default, payload: {} });
            history.push(routes.login);
            localStorage.clear();
        } catch (error) {
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { message: error.message },
            });
        }
    }, [history]);

    const register = async (values: AuthRegisterValuesType) => {
        try {
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { loading: true, errors: {} },
            });
            const {
                data,
                message,
                statusCode,
            } = await coinForBarterRequest.call(`${path}/`, MethodTypes.Post, {
                ...values,
                email: values?.email?.trim(),
            });
            if (statusCode === 400) {
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: { errors: data.errors, loading: false },
                });
                error('Validation error');
            } else if (statusCode === 201) {
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: {
                        data: { ...state.data, user: data },
                        toVerify: true,
                        loading: false,
                    },
                });
                success('Registration successful');
            } else {
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: { message: message, loading: false },
                });
                error(message);
            }
        } catch (err) {
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { message: err.message, loading: false },
            });
            error('An error ocurred, please try again');
        }
    };

    const resendVerificationEmail = async (email: string) => {
        try {
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { loading: true },
            });
            const { statusCode } = await coinForBarterRequest.call(
                `${path}/email/resend/${email}`,
                MethodTypes.Get
            );

            if (statusCode === 200) {
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: { loading: false },
                });
                success('Email verification sent');
            } else {
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: { loading: false },
                });
                success('Error sending verification sent');
            }
        } catch (err) {
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { message: err.message, loading: false },
            });
            success('An error ocurred, please try again');
        }
    };

    const clearState = async () => {
        dispatch({
            type: AuthActionsOptions.Default,
            payload: {},
        });
    };

    const getAuthenticatedUser = useCallback(async () => {
        try {
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { loading: true },
            });
            const {
                data,
                message,
                statusCode,
            } = await coinForBarterRequest.call(
                `${path}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: { data, authenticated: true, loading: false },
                });
            } else {
                let msg = message;
                if (statusCode === 401) {
                    msg = 'Session Expired or Invalid!';
                }
                dispatch({
                    type: AuthActionsOptions.Update,
                    payload: {
                        authenticated: false,
                        loading: false,
                        message: msg,
                    },
                });
            }
        } catch (error) {
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { message: error.message, loading: false },
            });
        }
    }, []);

    const suspend = useCallback(async () => {
        try {
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { loading: true },
            });
            const { statusCode, message } = await coinForBarterRequest.call(
                `${path}/suspend`,
                MethodTypes.Patch,
                undefined,
                true
            );
            if (statusCode === 200) {
                success('Account successfully suspended!');
                await logout();
                location.reload();
            } else {
                error(message);
            }
        } catch (error) {
            dispatch({
                type: AuthActionsOptions.Update,
                payload: { message: error.message, loading: false },
            });
        }
    }, [error, success, logout]);

    useEffect(() => {
        const loadApp = async () => {
            setLoadingApp(true);
            if (getAuthToken()) {
                await getAuthenticatedUser();
                if (getLocationHistory()) {
                    history.push(getLocationHistory());
                    removeLocationHistory();
                }
            }
            setLoadingApp(false);
        };

        loadApp();
    }, [getAuthenticatedUser, history]);

    useEffect(() => {
        if (loadingApp) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [loadingApp, setIsLoading]);

    useEffect(() => {}, [state.data.account.email]);

    return (
        <>
            {!loadingApp && (
                <AuthContext.Provider
                    value={{
                        state,
                        login,
                        logout,
                        register,
                        clearState,
                        resendVerificationEmail,
                        getAuthenticatedUser,
                        suspend,
                    }}
                >
                    {children}
                </AuthContext.Provider>
            )}
        </>
    );
};
