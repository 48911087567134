import {
    PaymentPlanSubscriberContextType,
    PaymentPlanSubscriberStateType,
} from './types';

export const defaultPaymentPlanSubscriberState: PaymentPlanSubscriberStateType = {
    loading: false,
};

export const defaultPaymentPlanSubscriberContextValue: PaymentPlanSubscriberContextType = {
    state: defaultPaymentPlanSubscriberState,
    create: async () => null,

    remove: async () => null,

    findAll: async () => null,

    findOne: async () => null,
};
