import tw from 'tailwind-styled-components'

export const LoadingSVG = tw.svg`
    animate-spin 
    ml-1 
    mr-3 
    h-5 
    w-5 
    text-white
`

export const LoaderWrapper = tw.div`
    flex
    justify-center
    items-center
`
