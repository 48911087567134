import styled from 'styled-components'

export const CheckBoxWrapper = styled.div`
    position: relative;
`

export const CheckBoxLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 45px;
    height: 26px;
    border-radius: 15px;
    background: #c4c4c43a;
    cursor: pointer;
    &::after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin: 3px;
        background: #c4c4c4;
        transition: 0.2s;
    }
`

export const CheckBox = styled.input`
    opacity: 0;
    z-index: 1;
    border-radius: 15px;
    width: 42px;
    height: 26px;
    &:checked + ${CheckBoxLabel} {
        background: #c4c4c4;
        &::after {
            content: '';
            display: block;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            background: #fff;
            margin-left: 21px;
            transition: 0.2s;
        }
    }
`
