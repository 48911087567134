import { defaultFiatWalletState } from './default';
import {
    FiatWalletActionsOptions,
    FiatWalletActionsType,
    FiatWalletStateType,
} from './types';

export const authReducer = (
    state: FiatWalletStateType,
    { type, payload }: FiatWalletActionsType
): FiatWalletStateType => {
    switch (type) {
        case FiatWalletActionsOptions.Default:
            return defaultFiatWalletState;

        case FiatWalletActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
