import { Preloader } from 'components';
import { AnimatePresence } from 'framer-motion';
import React, { createContext, useState, FC } from 'react';
import { preloaderStatus } from './default';
import { PreloaderType, Props } from './types';

export const PreloaderContext = createContext<PreloaderType>(preloaderStatus);

export const PreloaderProvider: FC<Props> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean | null>(false);

    return (
        <PreloaderContext.Provider value={{ isLoading, setIsLoading }}>
            <AnimatePresence>
                {isLoading !== false && <Preloader full={isLoading === null} />}
            </AnimatePresence>
            {children}
        </PreloaderContext.Provider>
    );
};
