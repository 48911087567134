/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const ButtonElement: FC<Record<string, any>> = styled.button`
    padding: 10px 25px;
    width: ${(props: Record<string, any>) => props.width || ''};
    font-size: 14px;
    display: inline-block;
    transition: box-shadow 0.2s ease-out, all 0.3s;
    background: ${(props: Record<string, any>) =>
        props.variant === 'secondary'
            ? '#730079'
            : props.variant === 'delete'
            ? 'red'
            : '#e9be00'};
    color: ${(props: Record<string, any>) =>
        props.variant === 'secondary'
            ? 'white'
            : props.variant === 'delete'
            ? 'white'
            : '#3d0040'};
    font-weight: bold;
    &:focus {
        outline: none;
    }

    &:disabled {
        background: #cacaca;
        cursor: not-allowed;
        color: #7b7b7b;
    }

    &:hover {
        background: ${(props: Record<string, any>) =>
            props.variant === 'secondary'
                ? '#3D0040'
                : props.variant === 'delete'
                ? 'rgb(190, 0, 0)'
                : '#b59300'};
        box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.1),
            0px 3px 3px rgba(0, 0, 0, 0.125);
        color: white;
        &:disabled {
            background: #cacaca;
            cursor: not-allowed;
            color: #7b7b7b;
        }
    }
    box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.1),
        0px 3px 3px rgba(0, 0, 0, 0.125);
    border-radius: 3px;
`;

export const LinkButton: FC<Record<string, any>> = styled(Link)`
    border: ${(props: Record<string, any>) => {
        switch (props.variant) {
            case 'tertiary':
                return '1px solid transparent';
            case 'secondary':
                return 'Opx solid #730079';
            case 'delete':
                return 'Opx solid red';
            default:
                return '1.5px solid #FFB403';
        }
    }};
    margin: 0.2rem 0;
    padding: 0.6rem 0.7rem;
    border-radius: 6px;
    text-align: center;
    min-width: 200px;
    width: ${(props: Record<string, any>) => props.width || '100%'};
    color: #520404;
    background-color: ${(props: Record<string, any>) => {
        switch (props.variant) {
            case 'primary':
                return '#FFB403';
            case 'secondary':
                return '#730079';
            case 'delete':
                return 'red';
            default:
                return 'transparent';
        }
    }};
    transition: box-shadow 0.2s ease-out;
    &:focus {
        outline: none;
    }

    &:hover {
    }
`;
export const SmallButtonElement = styled(ButtonElement)`
    font-size: 10px;
    padding: 0.3rem 1.2rem;
`;
