import { defaultBalanceState } from './default';
import {
    BalanceActionsOptions,
    BalanceActionsType,
    BalanceStateType,
} from './types';

export const authReducer = (
    state: BalanceStateType,
    { type, payload }: BalanceActionsType
): BalanceStateType => {
    switch (type) {
        case BalanceActionsOptions.Default:
            return defaultBalanceState;

        case BalanceActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
