import { AccountProfileContextType, AccountProfileStateType } from './types';

export const defaultAccountProfileState: AccountProfileStateType = {
    loading: false,
    data: {
        address: '',
        supportEmail: '',
        supportPhoneNumber: '',
        country: '',
        chargeBackEmail: '',
    },
};

export const defaultAccountProfileContextValue: AccountProfileContextType = {
    state: defaultAccountProfileState,
    getAccountProfile: () => {},
    updateAccountProfile: () => {},
};
