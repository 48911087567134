import React, {
    createContext,
    useReducer,
    useCallback,
    FC,
    useEffect,
} from 'react';
import { coinForBarterRequest, MethodTypes } from 'services';
import { BalanceStateDataType, CurrencyType } from 'context';
import { authReducer } from './reducer';
import { MiscActionsOptions, MiscContextType, MiscProviderType } from './types';
import { defaultMiscContextValue, defaultMiscState } from './default';

export const MiscContext = createContext<MiscContextType>(
    defaultMiscContextValue
);

export const MiscProvider: FC<MiscProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultMiscState);
    const countries = useCallback(async () => {
        try {
            dispatch({
                type: MiscActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/countries`,
                MethodTypes.Get
            );
            if (statusCode === 200) {
                dispatch({
                    type: MiscActionsOptions.Data,
                    payload: { countries: data, loading: false },
                });
            }
        } catch (e) {}
    }, []);

    const media = useCallback(async () => {
        try {
            dispatch({
                type: MiscActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/media`,
                MethodTypes.Get
            );
            if (statusCode === 200) {
                dispatch({
                    type: MiscActionsOptions.Data,
                    payload: { media: data, loading: false },
                });
            }
        } catch (e) {}
    }, []);

    const occupations = useCallback(async () => {
        try {
            dispatch({
                type: MiscActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/occupations`,
                MethodTypes.Get
            );

            if (statusCode === 200) {
                dispatch({
                    type: MiscActionsOptions.Data,
                    payload: { occupations: data, loading: false },
                });
            }
        } catch (e) {}
    }, []);

    const getCurrencies = useCallback(async () => {
        try {
            dispatch({
                type: MiscActionsOptions.Update,
                payload: { loading: true },
            });
            const {
                data: balances,
                statusCode,
            } = await coinForBarterRequest.call(
                `/balances`,
                MethodTypes.Get,
                undefined,
                true
            );

            const {
                data: currencies,
                statusCode: currenciesCode,
            } = await coinForBarterRequest.call(`/currencies`, MethodTypes.Get);

            if (statusCode === 200 && currenciesCode === 200) {
                dispatch({
                    type: MiscActionsOptions.Data,
                    payload: {
                        currencies: balances.map(
                            (balance: BalanceStateDataType) => {
                                const currency = currencies.find(
                                    (_currency: CurrencyType) =>
                                        _currency.abbreviation ===
                                        balance.currency
                                );
                                return currency;
                            }
                        ),
                        loading: false,
                    },
                });
            }
        } catch (e) {}
    }, []);

    useEffect(() => {
        const abort = new AbortController();
        if (state.data.currencies?.length === 0) {
            getCurrencies();
        }
        return abort.abort();
    }, [getCurrencies, state.data.currencies?.length]);

    return (
        <MiscContext.Provider
            value={{
                state,
                countries,
                media,
                occupations,
            }}
        >
            {children}
        </MiscContext.Provider>
    );
};
