import { ServerErrorType } from 'services';

/* eslint-disable no-unused-vars */
export interface AccountContextType {
    state: AccountStateType;
    getAccounts: () => void;
    switchAccount: (id: string) => void;
    createAccount: (
        params: Partial<AccountStateDataType>
    ) => Promise<ServerErrorType | null> | null;
    verifyEmail: (params: {
        token: string;
        otp: number | null;
    }) => Promise<ServerErrorType | null> | null;
    requestEmailVerification: () => Promise<string | null> | null;
    requestPhoneNumberVerification: () => Promise<string | null> | null;
    updatePhoneNumber: (phoneNumber: string) => Promise<boolean> | null;
    verifyPhoneNumber: (params: {
        token: string;
        otp: number | null;
    }) => Promise<ServerErrorType | null> | null;
}

export interface AccountProviderType {
    children: JSX.Element;
}

export type AccountStateType = {
    loading: boolean;
    data: AccountStateDataType[];
};

export type AccountActionsType = {
    type: AccountActionsOptions;
    payload: Partial<AccountStateType>;
};

export enum AccountActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface AccountStateDataType {
    name: string;
    email: string;
    type: string;
    isDeleted: boolean;
    isPhoneNumberVerified: boolean;
    isEmailVerified: boolean;
    status: AccountStatus;
    isPrimary: boolean;
    phoneNumber: string;
    _id: string;
}

export enum AccountStatus {
    Active = 'active',
    Suspended = 'suspended',
}
