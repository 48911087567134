import React, {
    createContext,
    useReducer,
    useCallback,
    FC,
    useEffect,
} from 'react';
import { useToast } from 'hooks';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    ProfileActionsOptions,
    ProfileContextType,
    ProfileProviderType,
    UpdateProfileType,
} from './types';
import { defaultProfileContextValue, defaultProfileState } from './default';

export const ProfileContext = createContext<ProfileContextType>(
    defaultProfileContextValue
);

export const ProfileProvider: FC<ProfileProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultProfileState);
    const { error, success } = useToast();
    const getProfile = useCallback(async () => {
        try {
            dispatch({
                type: ProfileActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/profile`,
                MethodTypes.Get,
                undefined,
                true
            );

            if (statusCode === 200) {
                dispatch({
                    type: ProfileActionsOptions.Update,
                    payload: { data: data, loading: false },
                });
            }
            dispatch({
                type: ProfileActionsOptions.Update,
                payload: { loading: false },
            });
        } catch (e) {
            dispatch({
                type: ProfileActionsOptions.Update,
                payload: { loading: false },
            });
        }
    }, []);

    const updateProfile = useCallback(
        async (values: UpdateProfileType) => {
            try {
                dispatch({
                    type: ProfileActionsOptions.Update,
                    payload: { loading: true },
                });
                const {
                    data,
                    statusCode,
                    message,
                } = await coinForBarterRequest.call(
                    `/profile/phone-number`,
                    MethodTypes.Post,
                    values,
                    true
                );

                if (statusCode === 201) {
                    success('Profile updated successfully');
                    dispatch({
                        type: ProfileActionsOptions.Update,
                        payload: {
                            data: data.profile,
                            phoneNumberToken: data.token,
                            loading: false,
                        },
                    });
                } else if (statusCode === 400) {
                    error('validation error');
                    dispatch({
                        type: ProfileActionsOptions.Update,
                        payload: {
                            errors: data.errors,
                            loading: false,
                        },
                    });
                } else {
                    error(message);
                    dispatch({
                        type: ProfileActionsOptions.Update,
                        payload: {
                            loading: false,
                        },
                    });
                }
            } catch (e) {
                dispatch({
                    type: ProfileActionsOptions.Update,
                    payload: { loading: false },
                });
            }
        },
        [error, success]
    );

    useEffect(() => {
        const abortController = new AbortController();
        getProfile();
        return abortController.abort();
    }, [getProfile]);

    return (
        <ProfileContext.Provider
            value={{
                state,
                getProfile,
                updateProfile,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
};
