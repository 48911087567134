import { defaultBankAccountState } from './default';
import {
    BankAccountActionsOptions,
    BankAccountActionsType,
    BankAccountStateType,
} from './types';

export const authReducer = (
    state: BankAccountStateType,
    { type, payload }: BankAccountActionsType
): BankAccountStateType => {
    switch (type) {
        case BankAccountActionsOptions.Default:
            return defaultBankAccountState;

        case BankAccountActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
