/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, FC } from 'react';
import { dashboardRoutes } from 'constants/dashboardRoutes';
import { Button } from 'components';
import { copyToClipboard } from 'utils';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AccountProvider, AccountStateDataType } from 'context';
import { useAccounts, useAuth } from 'hooks';
import { ButtonSize } from 'components/Button/types';
import { ReactComponent as DrawerIcon } from '@assets/icons/drawer.svg';
import { ReactComponent as ArrowIcon } from '@assets/icons/dropdown-icon.svg';
// import { ReactComponent as ClipIcon } from '@assets/icons/clip.svg';
import AddAccountIcon from '@assets/icons/add-account.png';
import { routes } from 'routes';
import {
    SidebarContainer,
    Drawer,
    SidebarMenu,
    SidebarMenuItem,
    SidebarMenuItemLabel,
    Arrow,
    UserAccount,
    Name,
    AccountList,
    AccountListItem,
    SidebarCategory,
    SidebarCategoryTitle,
} from './Sidebar.styled';

const AccountsList: FC<{
    open: boolean;
    miniAccount: boolean;
    // eslint-disable-next-line no-unused-vars
    copy: (id: string) => unknown;
}> = ({ open, miniAccount }) => {
    const {
        state: { data },
        switchAccount,
    } = useAccounts();

    const {
        state: { data: authData },
    } = useAuth();

    return (
        <>
            {data
                ?.filter(
                    (item: AccountStateDataType) =>
                        item._id !== authData.account._id
                )
                .map((item) => (
                    <AccountListItem
                        key={item._id}
                        open={open}
                        active={!miniAccount}
                        onClick={() => {
                            switchAccount(item._id);
                        }}
                    >
                        <Name open={open}>{item.name}</Name>
                        {/* <Title active={open && miniAccount}>Merchant ID</Title>
                        <SubTitle
                            active={open && miniAccount}
                            onClick={() => copy(item._id)}
                        >
                            {item._id}
                            <ClipIcon className="ml-2" />
                        </SubTitle> */}
                    </AccountListItem>
                ))}
        </>
    );
};

export const Sidebar: FC<{
    open: boolean;
    close: () => unknown;
    toggle: () => unknown;
}> = (props) => {
    const { open, close, toggle } = props;
    const { pathname } = useLocation();
    const [miniAccount, setMiniAccount] = useState(false);
    const history = useHistory();

    const {
        state: {
            data: { account },
        },
    } = useAuth();

    const copy = async (content: string) => {
        try {
            await copyToClipboard(content);
        } catch (e) {}
    };

    return (
        <SidebarContainer open={open}>
            <Drawer open={open} onClick={toggle}>
                <DrawerIcon />
            </Drawer>
            <SidebarMenu>
                <UserAccount open={open} mini={miniAccount}>
                    <div
                        className="cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            history.push(routes.account.add);
                        }}
                    >
                        {!open && (
                            <img src={AddAccountIcon} alt="add account" />
                        )}
                    </div>
                    {open && (
                        <AccountList>
                            <AccountListItem open={open} active={miniAccount}>
                                <div
                                    onClick={() => setMiniAccount(!miniAccount)}
                                    className="flex cursor-pointer flex-row justify-between items-center w-full"
                                >
                                    <div>
                                        <Name open={open}>{account?.name}</Name>
                                    </div>
                                    <Arrow
                                        mini={miniAccount}
                                        open={open}
                                        onClick={() =>
                                            setMiniAccount(!miniAccount)
                                        }
                                    >
                                        <ArrowIcon />
                                    </Arrow>
                                </div>
                                {/* <Title active={open && miniAccount}>
                                    Merchant ID
                                </Title>
                                <SubTitle
                                    active={open && miniAccount}
                                    onClick={() => copy(account?._id)}
                                    value={account?._id}
                                >
                                    {account?._id}
                                    <ClipIcon className="ml-2" />
                                </SubTitle> */}
                            </AccountListItem>

                            {miniAccount && open && (
                                <AccountProvider>
                                    <>
                                        <AccountsList
                                            miniAccount={miniAccount}
                                            open={open}
                                            copy={copy}
                                        />
                                        <div>
                                            <Button
                                                width="100%"
                                                size={ButtonSize.Small}
                                                variant="secondary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    history.push(
                                                        routes.account.add
                                                    );
                                                }}
                                            >
                                                Add Account
                                            </Button>
                                        </div>
                                    </>
                                </AccountProvider>
                            )}
                        </AccountList>
                    )}
                </UserAccount>
                {dashboardRoutes.map((route, index) => {
                    return (
                        <SidebarCategory key={route.title + index}>
                            {open && route.title && (
                                <SidebarCategoryTitle>
                                    {route.title}
                                </SidebarCategoryTitle>
                            )}
                            {route.children.map((item) => {
                                const { icon: Image } = item;

                                const current =
                                    item.path !== routes.home
                                        ? pathname.includes(item.path)
                                        : item.path === pathname;
                                return (
                                    <Link
                                        to={item.path}
                                        key={item.title}
                                        onClick={close}
                                        title={item.title}
                                    >
                                        <SidebarMenuItem active={current}>
                                            {Image}

                                            <SidebarMenuItemLabel
                                                active={current}
                                                open={open}
                                            >
                                                {item.title}
                                            </SidebarMenuItemLabel>
                                        </SidebarMenuItem>
                                    </Link>
                                );
                            })}
                        </SidebarCategory>
                    );
                })}
            </SidebarMenu>
        </SidebarContainer>
    );
};
