import React, {
    createContext,
    useReducer,
    useCallback,
    FC,
    useEffect,
} from 'react';
import { useAuth, useToast } from 'hooks';
import { routes } from 'routes';
import { coinForBarterRequest, MethodTypes } from 'services';
import { useHistory } from 'react-router-dom';
import { authReducer } from './reducer';
import {
    AccountActionsOptions,
    AccountContextType,
    AccountProviderType,
    AccountStateDataType,
} from './types';
import { defaultAccountContextValue, defaultAccountState } from './default';

export const AccountContext = createContext<AccountContextType>(
    defaultAccountContextValue
);

export const AccountProvider: FC<AccountProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultAccountState);
    const { success, error } = useToast();
    const history = useHistory();
    const { getAuthenticatedUser } = useAuth();
    const getAccounts = useCallback(async () => {
        try {
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/account-auth`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { data: data, loading: false },
                });
            }
        } catch (e) {
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: false },
            });
        }
    }, []);

    const switchAccount = useCallback(
        async (id: string) => {
            try {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/account-auth/switch/${id}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    // location.reload();
                    getAuthenticatedUser();
                    success('Account switched successfully');
                } else {
                    error(message);
                }
            } catch (er) {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, try again');
            }
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: false },
            });
        },
        [success, error, getAuthenticatedUser]
    );

    const createAccount = useCallback(
        async (params: Partial<AccountStateDataType>) => {
            try {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { loading: true },
                });
                const {
                    data,
                    statusCode,
                    message,
                } = await coinForBarterRequest.call(
                    `/account-auth`,
                    MethodTypes.Post,
                    params,
                    true
                );
                if (statusCode === 201) {
                    dispatch({
                        type: AccountActionsOptions.Update,
                        payload: { data: data, loading: false },
                    });
                    history.push(routes.home);
                    success('Account created successfully');
                    setTimeout(() => {
                        switchAccount(data.account._id);
                    }, 1000);
                } else if (statusCode === 400) {
                    error('Validation error');
                    dispatch({
                        type: AccountActionsOptions.Update,
                        payload: { loading: false },
                    });
                    return data.errors;
                } else {
                    error(message);
                }
            } catch (er) {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, try again');
            }
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: false },
            });
            return null;
        },
        [success, error, history, switchAccount]
    );

    const verifyEmail = useCallback(
        async (params: { otp: number | null; token: string }) => {
            try {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { loading: true },
                });
                const {
                    data,
                    statusCode,
                    message,
                } = await coinForBarterRequest.call(
                    `/account-auth/email`,
                    MethodTypes.Post,
                    params,
                    true
                );
                if (statusCode === 201 || statusCode === 201) {
                    dispatch({
                        type: AccountActionsOptions.Update,
                        payload: { data: data, loading: false },
                    });
                    getAuthenticatedUser();
                    history.push(routes.home);

                    success('Email verified successfully');
                } else if (statusCode === 400) {
                    error('Validation error');
                    dispatch({
                        type: AccountActionsOptions.Update,
                        payload: { loading: false },
                    });
                    return data.errors;
                } else {
                    error(message);
                }
            } catch (er) {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, try again');
            }
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: false },
            });
            return null;
        },
        [success, error, history, getAuthenticatedUser]
    );

    const verifyPhoneNumber = useCallback(
        async (params: { otp: number | null; token: string }) => {
            try {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { loading: true },
                });
                const {
                    data,
                    statusCode,
                    message,
                } = await coinForBarterRequest.call(
                    `/account-auth/phone-number`,
                    MethodTypes.Patch,
                    params,
                    true
                );
                if (statusCode === 201 || statusCode === 200) {
                    dispatch({
                        type: AccountActionsOptions.Update,
                        payload: { data: data, loading: false },
                    });
                    getAuthenticatedUser();
                    history.push(routes.home);

                    success('Account verified successfully');
                } else if (statusCode === 400) {
                    error('Validation error');
                    dispatch({
                        type: AccountActionsOptions.Update,
                        payload: { loading: false },
                    });
                    return data.errors;
                } else {
                    error(message);
                }
            } catch (er) {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, try again');
            }
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: false },
            });
            return null;
        },
        [success, error, history, getAuthenticatedUser]
    );

    const requestEmailVerification = useCallback(async () => {
        try {
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: true },
            });
            const {
                data,
                statusCode,
                message,
            } = await coinForBarterRequest.call(
                `/account-auth/email`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200 || statusCode === 201) {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { data: data, loading: false },
                });
                success('Email verification sent');
                return data.token;
            } else {
                error(message);
            }
        } catch (er) {
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: false },
            });
            error('An error occurred, try again');
        }
        dispatch({
            type: AccountActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, [success, error]);

    const requestPhoneNumberVerification = useCallback(async () => {
        try {
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: true },
            });
            const {
                data,
                statusCode,
                message,
            } = await coinForBarterRequest.call(
                `/account-auth/phone-number`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200 || statusCode === 201) {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { data: data, loading: false },
                });
                success('OTP has been sent to phone number');
                return data.token;
            } else {
                error(message);
            }
        } catch (er) {
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: false },
            });
            error('An error occurred, try again');
        }
        dispatch({
            type: AccountActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, [success, error]);

    const updatePhoneNumber = useCallback(
        async (phoneNumber: string) => {
            try {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/account-auth/phone-number`,
                    MethodTypes.Post,
                    { phoneNumber },
                    true
                );
                if (statusCode === 200 || statusCode === 201) {
                    dispatch({
                        type: AccountActionsOptions.Update,
                        payload: { loading: false },
                    });
                    success('Phone number updated successfully');
                    return true;
                } else {
                    error(message);
                }
            } catch (er) {
                dispatch({
                    type: AccountActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, try again');
            }
            dispatch({
                type: AccountActionsOptions.Update,
                payload: { loading: false },
            });
            return false;
        },
        [success, error]
    );

    useEffect(() => {
        const abortController = new AbortController();
        getAccounts();
        return abortController.abort();
    }, [getAccounts]);

    return (
        <AccountContext.Provider
            value={{
                state,
                getAccounts,
                createAccount,
                switchAccount,
                requestEmailVerification,
                verifyEmail,
                verifyPhoneNumber,
                requestPhoneNumberVerification,
                updatePhoneNumber,
            }}
        >
            {children}
        </AccountContext.Provider>
    );
};
