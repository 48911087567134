import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5vw;
    grid-area: head;
    z-index: 15;
    margin-bottom: 0px;
    background: #fff;
    box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.1),
        0px 3px 3px rgba(0, 0, 0, 0.125);
`;

export const Toggler = styled.button`
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
        outline: none;
    }
    border-radius: 5px;
    @media (min-width: 600px) {
        display: none;
    }
`;

export const Path = styled.path``;

export const Actions = styled.div`
    display: flex;
    align-items: center;
`;

export const IconButton = styled.button`
    margin: 0;
    position: relative;
    height: 35px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    &:focus {
        outline: none;
    }
    span {
        color: #fff;
        font-size: 10px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        width: 18px;
        top: 0px;
        right: 10px;
        border-radius: 50%;
        background-color: #7b7b7b;
    }
    path {
        fill: #cacaca;
        transition: all 0.3s;
        stroke: #7b7b7b;
    }

    &:hover {
        background: #cacaca;
    }

    &:hover path {
        fill: #d9c2db;
        stroke: #3d0040;
    }
`;
export const Logo = styled(Link)`
    @media (max-width: 600px) {
        display: none;
    }
`;

export const UserCorner = styled.div`
    /* @media (min-width: 600px) { */
    display: flex;
    /* flex-basis: 80%; */
    align-items: center;
    justify-content: space-between;
    /* } */
    cursor: pointer;
    position: relative;
`;

export const Avatar = styled.img`
    margin-right: 5px;
    object-fit: cover;
    object-position: top center;
`;

export const Name = styled.div`
    // padding-right: 10px;
    text-transform: capitalize;
    @media (max-width: 600px) {
        display: none;
    }
`;

export const Arrow = styled.button`
    &:focus {
        outline: none;
    }
    @media (max-width: 600px) {
        display: none;
    }
`;
