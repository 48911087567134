import { CustomerContextType, CustomerStateType } from './types';

export const defaultCustomerContextValue: CustomerContextType = {
    state: { loading: false },
    findAll: async () => null,
    findOne: async () => null,
    update: async () => null,
    archive: async () => false,
    create: async () => null,
};

export const defaultCustomerState: CustomerStateType = {
    loading: false,
};
