import { ApiKeyContextType, ApiKeyStateType } from './types';

export const defaultApiKeyState: ApiKeyStateType = {
    loading: false,
    data: {
        privateKey: '',
        publicKey: '',
        encryptionKey: '',
    },
};

export const defaultApiKeyContextValue: ApiKeyContextType = {
    state: defaultApiKeyState,
    getApiKeys: () => {},
    createApiKeys: async () => false,
};
