import React, {
    ChangeEventHandler,
    FormEventHandler,
    useEffect,
    FC,
    useState,
} from 'react';
import { motion } from 'framer-motion';
import { pageVariant } from 'constants/framer';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Input, Password, Select } from 'components';
import { useAuth, useMisc } from 'hooks';
import { CountriesType, MiscProvider } from 'context';
import { AuthRegisterValuesType } from 'context/auth/types';
import { routes } from 'routes';
import { defaultRegistrationValue } from './default';
import { Title, ForgotPasswordLink, ButtonWrapper, Terms } from '../styled';
import { RegistrationParamTypes } from './types';

const Index = () => {
    const {
        register,
        state: { loading, authenticated, toVerify, errors, data },
    } = useAuth();

    const { referralCode }: RegistrationParamTypes = useParams();
    const {
        countries: getCountries,
        media: getMedia,
        occupations: getOccupations,
        state: {
            data: { countries },
        },
    } = useMisc();
    const history = useHistory();
    const [values, setValues] = useState<AuthRegisterValuesType>({
        ...defaultRegistrationValue,
        referralCode: referralCode,
    });
    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        register(values);
    };

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (value: number | string, name: string) => {
        setValues({ ...values, [name]: value });
    };

    useEffect(() => {
        getCountries();
        getMedia();
        getOccupations();
    }, [getCountries, getMedia, getOccupations]);

    useEffect(() => {
        if (toVerify) {
            history.push(`${routes.verify}?email=${data.user.email}`);
        }
    }, [authenticated, history, toVerify, data.user.email]);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const fields: { referralCode?: string; email?: string } = {};
        if (params.email) {
            fields.email = params.email;
        }
        if (params.referralCode) {
            fields.referralCode = params.email;
        }
        setValues({ ...values, ...fields });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            variants={pageVariant}
        >
            <Title>Create Account</Title>
            <form onSubmit={handleSubmit}>
                <Select
                    disabled={loading}
                    label="Country"
                    placeholder="Select Country"
                    errors={errors?.country}
                    name="country"
                    id="country"
                    value={values.country}
                    onChange={(v: unknown) => {
                        handleSelectChange(v as string, 'country');
                    }}
                    renderComponent={(country) => (
                        <div className="flex">
                            <img
                                src={(country as CountriesType).flag}
                                style={{
                                    width: '30px',
                                    marginRight: '10px',
                                }}
                                alt={(country as CountriesType).name}
                            />
                            <p>{(country as CountriesType).name}</p>
                        </div>
                    )}
                    options={countries?.map((country: CountriesType) => ({
                        value: country.name,
                        label: country.name,
                        key: country.name,
                        option: country,
                    }))}
                />
                <Input
                    disabled={loading}
                    placeholder="Business Name"
                    label="Business Name"
                    name="tradingName"
                    id="tradingName"
                    errors={errors?.tradingName}
                    value={values.tradingName}
                    onChange={handleInputChange}
                />

                <Input
                    disabled={loading}
                    placeholder="Full Name"
                    label="Full Name"
                    errors={errors?.fullName}
                    name="fullName"
                    id="fullName"
                    value={values.fullName}
                    onChange={handleInputChange}
                />

                <Input
                    disabled={loading}
                    placeholder="Email Address"
                    label="Email Address"
                    name="email"
                    id="email"
                    errors={errors?.email}
                    value={values.email}
                    onChange={handleInputChange}
                />

                <Password
                    disabled={loading}
                    placeholder="A minimum of 1 cap, 1 numeric and 1 special character"
                    label="Password"
                    name="password"
                    id="password"
                    value={values.password}
                    errors={errors?.password}
                    onChange={handleInputChange}
                    showHint={true}
                />
                <Password
                    disabled={loading}
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    errors={errors?.confirmPassword}
                    onChange={handleInputChange}
                />

                <ButtonWrapper>
                    <ForgotPasswordLink to={routes.login}>
                        Login
                    </ForgotPasswordLink>
                    <Button type="submit" loading={loading} disabled={loading}>
                        Create account
                    </Button>
                </ButtonWrapper>

                <Terms>
                    By clicking <b>Create Account</b>, you accept all of
                    CoinForBarter's{' '}
                    <a
                        href={`${
                            process.env.REACT_APP_WEBSITE_URL ||
                            'https://staging.coinforbarter.com'
                        }/terms`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        terms and conditions
                    </a>
                </Terms>
            </form>
        </motion.div>
    );
};

export const Register: FC = () => {
    return (
        <MiscProvider>
            <Index />
        </MiscProvider>
    );
};
