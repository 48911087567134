import React, { FC } from 'react';
import {
    Autocomplete,
    Box,
    OutlinedInput,
    Select as SelectMui,
    MenuItem,
    ListItemText,
    Checkbox,
    TextField,
    FormControl,
    InputLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InfoOutlined } from '@mui/icons-material';
import { SelectPropsType } from './types';
import { InputWrapper, Validation } from './Input.styled';

const useStyles = makeStyles({
    input: {
        borderRadius: 3,
        width: '100%',
        fontSize: 14,
        color: '#7b7b7b',
        fontWeight: 600,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const Select: FC<SelectPropsType> = ({
    name,
    errors,
    label,
    onChange,
    options,
    className,
    disabled,
    id,
    placeholder,
    value,
    multiple,
    renderComponent,
}) => {
    const handleChange = (
        selected: string | number | boolean | (string | number | boolean)[]
    ) => {
        onChange(multiple ? ((selected as unknown) as string) : selected);
    };
    const classes = useStyles({
        hasErrors: !!errors && errors.length > 0,
    });
    return (
        <>
            <InputWrapper>
                {!multiple && (
                    <Autocomplete
                        options={options}
                        onChange={(event, value) => {
                            handleChange((value?.value as string[]) || []);
                        }}
                        disabled={disabled}
                        autoHighlight
                        fullWidth
                        getOptionLabel={(option) => option.key}
                        isOptionEqualToValue={(option, value) => {
                            return option.value === value.value;
                        }}
                        defaultValue={
                            options.find((option) => option.value === value) ||
                            null
                        }
                        value={
                            options.find((option) => option.value === value) ||
                            null
                        }
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}
                            >
                                {renderComponent
                                    ? renderComponent(option.option)
                                    : option.label}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value={value}
                                name={name}
                                label={label}
                                fullWidth
                                className={`${classes.input} ${className}`}
                                inputProps={{
                                    autocomplete: 'chrome-off',
                                    ...params.inputProps,
                                    placeholder,
                                }}
                                color={
                                    errors && errors.length > 0
                                        ? 'warning'
                                        : undefined
                                }
                            />
                        )}
                    />
                )}

                {multiple && (
                    <FormControl
                        variant="outlined"
                        className={`${classes.input} ${className}`}
                    >
                        <InputLabel htmlFor={id}>{label}</InputLabel>
                        <SelectMui
                            id={id}
                            multiple
                            value={value?.length ? value.split(', ') : []}
                            onChange={(e) => {
                                handleChange(e.target.value as string);
                            }}
                            input={
                                <OutlinedInput
                                    label={label}
                                    color={
                                        errors && errors.length > 0
                                            ? 'warning'
                                            : undefined
                                    }
                                    fullWidth
                                    name={name}
                                    className={`${classes.input} ${className}`}
                                />
                            }
                            renderValue={(selected) => {
                                return ((selected as unknown) as string[]).join(
                                    ', '
                                );
                            }}
                            MenuProps={MenuProps}
                        >
                            {options.map((option) => (
                                <MenuItem
                                    key={option.key}
                                    value={option.value?.toString() || ''}
                                >
                                    <Checkbox
                                        checked={
                                            value
                                                ? value
                                                      .split(', ')
                                                      .indexOf(
                                                          option.value as any
                                                      ) > -1
                                                : false
                                        }
                                    />
                                    <ListItemText primary={option.value} />
                                </MenuItem>
                            ))}
                        </SelectMui>
                    </FormControl>
                )}

                {errors &&
                    errors.length > 0 &&
                    errors.map((error) => (
                        <Validation key={error}>
                            <span className="flex-2">
                                <InfoOutlined
                                    fontSize="small"
                                    className="mr-2"
                                />
                            </span>
                            <span className="flex-10">{error}</span>
                        </Validation>
                    ))}
            </InputWrapper>
        </>
    );
};
