import { defaultPayoutState } from './default';
import {
    PayoutActionsOptions,
    PayoutActionsType,
    PayoutStateType,
} from './types';

export const authReducer = (
    state: PayoutStateType,
    { type, payload }: PayoutActionsType
): PayoutStateType => {
    switch (type) {
        case PayoutActionsOptions.Default:
            return defaultPayoutState;

        case PayoutActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
