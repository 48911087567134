import { ProfileContextType, ProfileStateType } from './types';

export const defaultProfileState: ProfileStateType = {
    loading: false,
};

export const defaultProfileContextValue: ProfileContextType = {
    state: defaultProfileState,
    getProfile: () => {},
    updateProfile: () => {},
};
