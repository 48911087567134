import React, { createContext, useReducer, useCallback, FC } from 'react';
import {
    coinForBarterRequest,
    MethodTypes,
    RequestResponseSchema,
} from 'services';
import { DataResponse } from 'context/bank-accounts';
import { useToast } from 'hooks';
import { authReducer } from './reducer';
import {
    FiatWalletActionsOptions,
    FiatWalletContextType,
    FiatWalletProviderType,
    FiatWalletsQueryType,
    FiatWalletType,
} from './types';
import {
    defaultFiatWalletContextValue,
    defaultFiatWalletState,
} from './default';

export const FiatWalletContext = createContext<FiatWalletContextType>(
    defaultFiatWalletContextValue
);

export const FiatWalletProvider: FC<FiatWalletProviderType> = ({
    children,
}) => {
    const { error, success } = useToast();
    const [state, dispatch] = useReducer(authReducer, defaultFiatWalletState);

    const findAll = useCallback(
        async (params?: Partial<FiatWalletsQueryType>) => {
            try {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: true },
                });
                const searchParams =
                    coinForBarterRequest.makeQueryString(params || {}) || '';
                const {
                    statusCode,
                    meta,
                    data,
                } = await coinForBarterRequest.call(
                    `/third-party-wallets${searchParams}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    dispatch({
                        type: FiatWalletActionsOptions.Update,
                        payload: { loading: false },
                    });
                    return { fiatWallets: data, meta: meta || null };
                }
            } catch (e) {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: false },
                });
            }

            return null;
        },
        []
    );

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: FiatWalletActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/third-party-wallets/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: false },
                });
                return data;
            }
        } catch (e) {
            dispatch({
                type: FiatWalletActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const makePrimary = useCallback(
        async (id: string) => {
            try {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/third-party-wallets/primary/${id}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    success('Switching primary bank account successful');
                    return true;
                } else {
                    error(message);
                }
            } catch (e) {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: FiatWalletActionsOptions.Update,
                payload: { loading: false },
            });
            return false;
        },
        [success, error]
    );

    const archive = useCallback(
        async (id: string) => {
            try {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/third-party-wallets/archive/${id}`,
                    MethodTypes.Delete,
                    undefined,
                    true
                );
                if (statusCode === 204) {
                    success('Bank account deleted successfully');
                    return true;
                } else {
                    error(message);
                }
            } catch (e) {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: FiatWalletActionsOptions.Update,
                payload: { loading: false },
            });

            return false;
        },
        [success, error]
    );

    const getWallets = useCallback(async () => {
        if (!state.wallets || state.wallets?.length === 0) {
            try {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: true },
                });
                const { data, statusCode } = await coinForBarterRequest.call(
                    `/third-party-wallets/fiat-wallets`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    dispatch({
                        type: FiatWalletActionsOptions.Update,
                        payload: { loading: false, wallets: data },
                    });
                } else {
                }
            } catch (e) {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: false },
                });
            }
            dispatch({
                type: FiatWalletActionsOptions.Update,
                payload: { loading: false },
            });
        }
    }, [state.wallets]);

    const create = useCallback(
        async (
            body: Partial<FiatWalletType>
        ): Promise<RequestResponseSchema<DataResponse> | null> => {
            try {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: true },
                });
                const response = await coinForBarterRequest.call(
                    `/third-party-wallets`,
                    MethodTypes.Post,
                    body,
                    true
                );
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: FiatWalletActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: FiatWalletActionsOptions.Update,
                payload: { loading: false },
            });

            return null;
        },
        [error]
    );

    return (
        <FiatWalletContext.Provider
            value={{
                state,
                findAll,
                findOne,
                makePrimary,
                archive,
                getWallets,
                create,
            }}
        >
            {children}
        </FiatWalletContext.Provider>
    );
};
