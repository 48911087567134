import { AuthRegisterValuesType } from 'context/auth/types';

export const defaultRegistrationValue: AuthRegisterValuesType = {
    email: '',
    password: '',
    confirmPassword: '',
    fullName: '',
    tradingName: '',
    country: '',
};
