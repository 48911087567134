/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { CustomerType } from 'context/customers';
import { MetaType } from 'services';

export interface TransactionContextType {
    state: TransactionStateType;
    findAll: (
        params?: Partial<TransactionsQueryType>
    ) => Promise<{
        transactions: TransactionType[];
        meta: MetaType | null;
    } | null>;
    findOne: (id: string) => Promise<TransactionType | null>;
    graph: () => Promise<Record<string, string | number>[] | null>;
}

export interface TransactionProviderType {
    children: JSX.Element;
}

export type TransactionStateType = {
    loading: boolean;
};

export type TransactionActionsType = {
    type: TransactionActionsOptions;
    payload: Partial<TransactionStateType>;
};

export enum TransactionActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface TransactionType {
    status: TransactionStatus;
    description: string;
    source: TransactionSource;
    sourceDetails: Record<string, any>;
    currency: string;
    currencyNetwork: string;
    amount: number;
    nairaValue: number;
    localCurrencyValue: number;
    dollarValue: number;
    baseAmount: number;
    fee: number;
    settlement: number;
    amountReceived: number;
    baseCurrency: string;
    currencies: string[];
    remark: string;
    paymentRef: string;
    transactionFees: number;
    totalDue: number;
    customer: string;
    customerDetails: CustomerType;
    destinationAddress: string;
    destinationAddressInformation: AddressInformation;
    sourceAddressInformation: Record<string, any>[];
    deviceInformation: DeviceInformation;
    transactionTimeLog: TransactionTimeLog[];
    isWebhookHandled: boolean;
    isCurrencyLocked: boolean;
    expiresBy: number;
    _id: string;
    createdAt: string;
}

export interface TransactionsQueryType {
    from: string;
    to: string;
    page: number;
    currency: string;
    status: TransactionStatus;
    minAmount: number;
    maxAmount: number;
    customer: string;
    isAscending: boolean;
    skip: number;
    sort: string;
    source: TransactionSource;
    sourceId: string;
}

export type TransactionTimeLog = {
    time: string;
    event: string;
    type: TransactionLogEventsType;
};

export type AddressInformation = {
    address: string;
    network: string;
    id: string;
};

export type DeviceInformation = {
    ipAddress: string;
    deviceFingerPrint: string;
};

export enum TransactionLogEventsType {
    Success = 'success',
    Error = 'error',
    Event = 'event',
}

export enum TransactionStatus {
    Success = 'success',
    Error = 'error',
    Pending = 'pending',
    Cancelled = 'cancelled',
    Inprogress = 'in progress',
}

export enum TransactionSource {
    SingleChargePaymentLink = 'single charge payment link',
    PaymentPlan = 'payment plan',
    SubscriptionChargePaymentLink = 'subscription charge payment link',
    DonationChargePaymentLink = 'donation charge payment link',
    InlineApiPayment = 'inline api payment',
    StandardApiPayment = 'standard api payment',
    ApiPayment = 'api payment',
    Invoice = 'invoice',
}

export type CustomerDetails = {
    email: string;
    fullName?: string;
};
