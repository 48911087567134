/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { MetaType, RequestResponseSchema, ServerErrorType } from 'services';

export interface CryptoAddressContextType {
    state: Record<string, any>;
    findAll: (
        params?: CryptoAddressesQueryType
    ) => Promise<{
        addresses: CryptoAddressType[];
        meta: MetaType | null;
    } | null>;
    findOne: (id: string) => Promise<CryptoAddressType | null>;
    archive: (id: string) => Promise<boolean>;
    makePrimary: (id: string) => Promise<boolean>;
    create: (
        body: CreateCryptoAddressType
    ) => Promise<RequestResponseSchema<CryptoDataResponse> | null>;
}

export interface CryptoDataResponse extends CryptoAddressType {
    errors: ServerErrorType;
}
export interface CryptoAddressProviderType {
    children: JSX.Element;
}

export type CryptoAddressStateType = {
    loading: boolean;
    addresses: CryptoAddressType[];
    meta?: MetaType | null;
    queryParams?: CryptoAddressesQueryType;
};

export type CryptoAddressActionsType = {
    type: CryptoAddressActionsOptions;
    payload: Partial<CryptoAddressStateType>;
};

export enum CryptoAddressActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface CryptoAddressType {
    address: string;
    currency: string;
    network: string;
    isPrimary: boolean;
    _id: string;
    label: string;
}

export interface CryptoAddressesQueryType {
    from?: string;
    to?: string;
    isPrimary?: boolean;
    page?: number;
    network?: string;
    currency?: string;
    address?: string;
    label?: string;
}

export interface CreateCryptoAddressType {
    isPrimary: boolean;
    currency: string;
    address: string;
    network: string;
    label: string;
}
