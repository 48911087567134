import { RequestResponseSchema, ServerErrorType } from 'services';

/* eslint-disable no-unused-vars */
export interface BrandingContextType {
    state: BrandingStateType;
    find: () => Promise<void>;
    update: (
        params: Partial<BrandingType>,
        file?: File
    ) => Promise<RequestResponseSchema<BrandingResponse> | null>;
}

interface BrandingResponse extends BrandingType {
    errors: ServerErrorType | null;
}

export interface BrandingProviderType {
    children: JSX.Element;
}

export type BrandingStateType = {
    loading: boolean;
    branding: BrandingType;
};

export type BrandingActionsType = {
    type: BrandingActionsOptions;
    payload: Partial<BrandingStateType>;
};

export enum BrandingActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface BrandingType {
    businessName: string;
    description: string;
    logo: string;
    color: string;
}
