import React, { useState, useEffect, FC } from 'react';
import { Button } from 'components';
import { routes } from 'routes';
import { shortEmail } from 'utils';
import { useAuth } from 'hooks';
import { motion } from 'framer-motion';
import { pageVariant } from 'constants/framer';
import SuccessIcon from '@assets/icons/success.gif';
import { useHistory } from 'react-router-dom';
import { Title, ButtonWrapper, ForgotPasswordLink } from '../styled';

export const Verify: FC = () => {
    const {
        resendVerificationEmail,
        clearState,
        state: { loading },
    } = useAuth();
    const [email, setEmail] = useState('');

    const history = useHistory();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (!params.email) {
            history.push(routes.login);
        } else {
            setEmail(params.email);
        }
    }, [history, email]);

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            variants={pageVariant}
        >
            <Title>Account Created</Title>
            <div className="text-center py-5">
                <img
                    src={SuccessIcon}
                    alt="Email verification success icon"
                    className="m-auto my-6 w-16  p-2 rounded-lg"
                />
                <p
                    className="my-4 text-secondary-700 text-tiny"
                    style={{ fontSize: '14px' }}
                >
                    A verification email has been sent to{' '}
                    <b>{shortEmail(email)}</b>. Please verify your email
                </p>
                <ButtonWrapper>
                    <ForgotPasswordLink
                        to={routes.login}
                        onClick={() => {
                            if (clearState) clearState();
                        }}
                    >
                        Login
                    </ForgotPasswordLink>
                    <Button
                        disabled={loading}
                        loading={loading}
                        onClick={() => {
                            if (resendVerificationEmail) {
                                resendVerificationEmail(email);
                            }
                        }}
                    >
                        Resend
                    </Button>
                </ButtonWrapper>
            </div>
        </motion.div>
    );
};
