import React, { FC } from 'react';

import {
    CheckboxContainer,
    HiddenCheckbox,
    StyledCheckbox,
    CheckBoxIcon,
    Label,
} from './Input.styled';

export const Checkbox: FC<{
    className?: string;
    label: string;
    checked: boolean;
    onChange: () => void;
}> = ({ className, label, checked, onChange }) => {
    return (
        <div
            onClick={onChange}
            className="flex items-between bg-red cursor-pointer"
        >
            <CheckboxContainer className={className}>
                <HiddenCheckbox checked={checked} readOnly />
                <StyledCheckbox data-checked={checked}>
                    <CheckBoxIcon viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </CheckBoxIcon>
                </StyledCheckbox>
            </CheckboxContainer>

            <Label onClick={onChange} className="ml-2 cursor-pointer">
                {label}
            </Label>
        </div>
    );
};
