import React, { createContext, useReducer, useCallback, FC } from 'react';
import {
    coinForBarterRequest,
    MethodTypes,
    RequestResponseSchema,
} from 'services';
import { useToast } from 'hooks';
import { authReducer } from './reducer';
import {
    CryptoAddressActionsOptions,
    CryptoAddressContextType,
    CryptoAddressProviderType,
    CreateCryptoAddressType,
    CryptoAddressesQueryType,
    CryptoDataResponse,
} from './types';
import {
    defaultCryptoAddressContextValue,
    defaultCryptoAddressState,
} from './default';

export const CryptoAddressContext = createContext<CryptoAddressContextType>(
    defaultCryptoAddressContextValue
);

export const CryptoAddressProvider: FC<CryptoAddressProviderType> = ({
    children,
}) => {
    const { error, success } = useToast();
    const [state, dispatch] = useReducer(
        authReducer,
        defaultCryptoAddressState
    );

    const findAll = useCallback(async (params?: CryptoAddressesQueryType) => {
        try {
            dispatch({
                type: CryptoAddressActionsOptions.Update,
                payload: { loading: true, queryParams: params },
            });
            const searchParams =
                coinForBarterRequest.makeQueryString(params || {}) || '';
            const { data, statusCode, meta } = await coinForBarterRequest.call(
                `/wallet-addresses${searchParams}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: CryptoAddressActionsOptions.Update,
                    payload: { loading: false },
                });
                return { addresses: data, meta: meta || null };
            }
        } catch (e) {
            dispatch({
                type: CryptoAddressActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: CryptoAddressActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/wallet-addresses/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: CryptoAddressActionsOptions.Update,
                    payload: { loading: false },
                });
                return data;
            }
        } catch (e) {
            dispatch({
                type: CryptoAddressActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const makePrimary = useCallback(
        async (id: string) => {
            try {
                dispatch({
                    type: CryptoAddressActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/wallet-addresses/primary/${id}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    success('Switching primary crypto address successful');
                    return true;
                } else {
                    error(message);
                }
            } catch (e) {
                dispatch({
                    type: CryptoAddressActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: CryptoAddressActionsOptions.Update,
                payload: { loading: false },
            });

            return false;
        },
        [error, success]
    );

    const archive = useCallback(
        async (id: string) => {
            try {
                dispatch({
                    type: CryptoAddressActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/wallet-addresses/archive/${id}`,
                    MethodTypes.Delete,
                    undefined,
                    true
                );
                if (statusCode === 204) {
                    success('Crypto address deleted successfully');
                    return true;
                } else {
                    error(message);
                }
            } catch (e) {
                dispatch({
                    type: CryptoAddressActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: CryptoAddressActionsOptions.Update,
                payload: { loading: false },
            });

            return false;
        },
        [error, success]
    );

    const create = useCallback(
        async (
            body: CreateCryptoAddressType
        ): Promise<RequestResponseSchema<CryptoDataResponse> | null> => {
            try {
                dispatch({
                    type: CryptoAddressActionsOptions.Update,
                    payload: { loading: true },
                });
                const response = await coinForBarterRequest.call(
                    `/wallet-addresses`,
                    MethodTypes.Post,
                    body,
                    true
                );
                dispatch({
                    type: CryptoAddressActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: CryptoAddressActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: CryptoAddressActionsOptions.Update,
                payload: { loading: false },
            });

            return null;
        },
        [error]
    );

    return (
        <CryptoAddressContext.Provider
            value={{
                state,
                findAll,
                findOne,
                makePrimary,
                archive,
                create,
            }}
        >
            {children}
        </CryptoAddressContext.Provider>
    );
};
