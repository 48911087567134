import { defaultBrandingState } from './default';
import {
    BrandingActionsOptions,
    BrandingActionsType,
    BrandingStateType,
} from './types';

export const authReducer = (
    state: BrandingStateType,
    { type, payload }: BrandingActionsType
): BrandingStateType => {
    switch (type) {
        case BrandingActionsOptions.Default:
            return defaultBrandingState;

        case BrandingActionsOptions.Update:
            return {
                ...state,
                ...{
                    ...payload,
                    branding: { ...state.branding, ...payload.branding },
                },
            };

        default:
            return state;
    }
};
