import { TransactionContextType, TransactionStateType } from './types';

export const defaultTransactionContextValue: TransactionContextType = {
    state: { loading: false },
    findAll: async () => null,
    findOne: async () => null,
    graph: async () => null,
};

export const defaultTransactionState: TransactionStateType = {
    loading: false,
};
