/* eslint-disable no-unused-vars */
export interface BalanceContextType {
    state: BalanceStateType;
    getBalances: () => void;
}

export interface BalanceProviderType {
    children: JSX.Element;
}

export type BalanceStateType = {
    loading: boolean;
    data: BalanceStateDataType[];
};

export type BalanceActionsType = {
    type: BalanceActionsOptions;
    payload: Partial<BalanceStateType>;
};

export enum BalanceActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface BalanceStateDataType {
    currency: string;
    availableBalance: number;
    totalBalance: number;
    _id: string;
    isSuspended: boolean;
}
