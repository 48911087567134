import styled from 'styled-components';

export const H1 = styled.h1`
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    margin-right: 10px;
`;

export const Span = styled.span`
    display: flex;
    align-items: center;
    cursor: help;
`;

export const Div = styled.div`
    width: 20px;
    max-width: 20px;
    position: relative;
    &:hover {
        p {
            visibility: visible;
            opacity: 1;
        }
    }
`;

export const P = styled.p`
    position: absolute;
    padding: 5px;
    background: black;
    color: white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-size: 10px;
    max-width: 120px;
    width: 120px;
    z-index: 1;
    right: -120px;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
`;
