import React, { createContext, useReducer, useCallback, FC } from 'react';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    WithdrawalActionsOptions,
    WithdrawalContextType,
    WithdrawalProviderType,
    WithdrawalQueryType,
    WithdrawalType,
} from './types';
import {
    defaultWithdrawalContextValue,
    defaultWithdrawalState,
} from './default';

export const WithdrawalContext = createContext<WithdrawalContextType>(
    defaultWithdrawalContextValue
);

export const WithdrawalProvider: FC<WithdrawalProviderType> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(authReducer, defaultWithdrawalState);

    const create = useCallback(async (params: Partial<WithdrawalType>) => {
        try {
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: true },
            });
            const response = await coinForBarterRequest.call(
                `/withdrawals`,
                MethodTypes.Post,
                params,
                true
            );
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: WithdrawalActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const transfer = useCallback(async (params: Partial<WithdrawalType>) => {
        try {
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: true },
            });
            const response = await coinForBarterRequest.call(
                `/transfers`,
                MethodTypes.Post,
                {
                    walletAddress: params.address,
                    amount: params.amount,
                    currency: params.currency,
                    walletNetwork: params.network,
                    ...params,
                },
                true
            );
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: WithdrawalActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const findAll = useCallback(
        async (params?: Partial<WithdrawalQueryType>) => {
            try {
                dispatch({
                    type: WithdrawalActionsOptions.Update,
                    payload: { loading: true },
                });
                const searchParams =
                    coinForBarterRequest.makeQueryString(params || {}) || '';
                const response = await coinForBarterRequest.call(
                    `/withdrawals${searchParams}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                dispatch({
                    type: WithdrawalActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: WithdrawalActionsOptions.Update,
                    payload: { loading: false },
                });
            }

            return null;
        },
        []
    );

    const getFee = useCallback(async (params: Partial<WithdrawalType>) => {
        try {
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: true },
            });
            const response = await coinForBarterRequest.call(
                `/withdrawals/fee`,
                MethodTypes.Post,
                params,
                true
            );

            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: WithdrawalActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: true },
            });

            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: false },
            });
            const response = await coinForBarterRequest.call(
                `/withdrawals/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            return response;
        } catch (e) {
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const getOtp = useCallback(async () => {
        try {
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: true },
            });

            const response = await coinForBarterRequest.call(
                `/withdrawals/otp`,
                MethodTypes.Post,
                undefined,
                true
            );

            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: WithdrawalActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    return (
        <WithdrawalContext.Provider
            value={{
                state,
                create,
                findAll,
                findOne,
                getFee,
                getOtp,
                transfer,
            }}
        >
            {children}
        </WithdrawalContext.Provider>
    );
};
