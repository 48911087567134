import React, {
    createContext,
    useReducer,
    useCallback,
    FC,
    useEffect,
} from 'react';
import {
    coinForBarterRequest,
    MethodTypes,
    RequestResponseSchema,
} from 'services';
import { useToast } from 'hooks';
import { authReducer } from './reducer';
import {
    ComplaintDataResponse,
    ComplaintQueryType,
    PayoutActionsOptions,
    PayoutContextType,
    PayoutInType,
    PayoutProviderType,
    PayoutSettingsType,
    PayoutsQueryType,
} from './types';
import { defaultPayoutContextValue, defaultPayoutState } from './default';

export const PayoutContext = createContext<PayoutContextType>(
    defaultPayoutContextValue
);

export const PayoutProvider: FC<PayoutProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultPayoutState);
    const { error, success } = useToast();
    const findAll = useCallback(async (params?: Partial<PayoutsQueryType>) => {
        try {
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: true },
            });

            const searchParams =
                coinForBarterRequest.makeQueryString(params || {}) || '';
            const { data, statusCode, meta } = await coinForBarterRequest.call(
                `/payouts${searchParams}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false },
                });
                return { payouts: data, meta: meta || null };
            }
        } catch (e) {
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: PayoutActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const getSettings = useCallback(async () => {
        try {
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/payouts/settings`,
                MethodTypes.Get,
                undefined,
                true
            );

            if (statusCode === 200) {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false, settings: data },
                });
            } else {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false },
                });
            }
        } catch (e) {
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: PayoutActionsOptions.Update,
            payload: { loading: false },
        });
    }, []);

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/payouts/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false },
                });
                return data;
            }
        } catch (e) {
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: PayoutActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const makeAllAutomatic = useCallback(
        async (value: boolean) => {
            try {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: true },
                });
                const {
                    data,
                    statusCode,
                    message,
                } = await coinForBarterRequest.call(
                    `/payouts/settings/automatic/${value}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    success('Payout settings updated');
                    dispatch({
                        type: PayoutActionsOptions.Update,
                        payload: { loading: false, settings: data },
                    });
                } else {
                    error(message);
                    dispatch({
                        type: PayoutActionsOptions.Update,
                        payload: { loading: false },
                    });
                }
            } catch (e) {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: false },
            });
        },
        [error, success]
    );

    const makeAllInACurrency = useCallback(
        async (type: PayoutInType) => {
            try {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: true },
                });
                const {
                    data,
                    statusCode,
                    message,
                } = await coinForBarterRequest.call(
                    `/payouts/settings/currency/${type}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    success('Payout settings updated');
                    dispatch({
                        type: PayoutActionsOptions.Update,
                        payload: { loading: false, settings: data },
                    });
                } else {
                    error(message);
                    dispatch({
                        type: PayoutActionsOptions.Update,
                        payload: { loading: false },
                    });
                }
            } catch (e) {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: false },
            });
        },
        [success, error]
    );

    const makeCustom = useCallback(
        async (custom: PayoutSettingsType[]) => {
            try {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: true },
                });
                const {
                    data,
                    statusCode,
                    message,
                } = await coinForBarterRequest.call(
                    `/payouts/settings/custom`,
                    MethodTypes.Post,
                    custom,
                    true
                );
                if (statusCode === 201) {
                    success('Payout settings updated');
                    dispatch({
                        type: PayoutActionsOptions.Update,
                        payload: { loading: false, settings: data },
                    });
                } else {
                    error(message);
                    dispatch({
                        type: PayoutActionsOptions.Update,
                        payload: { loading: false },
                    });
                }
            } catch (e) {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: false },
            });
        },
        [error, success]
    );

    const createComplaint = useCallback(
        async (
            id: string,
            body: { message: string }
        ): Promise<RequestResponseSchema<ComplaintDataResponse> | null> => {
            try {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: true },
                });
                const response = await coinForBarterRequest.call(
                    `/payouts/complaints/${id}`,
                    MethodTypes.Post,
                    body,
                    true
                );
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false },
                });
            }
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: false },
            });
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: false },
            });
            return null;
        },
        []
    );

    const getComplaints = useCallback(
        async (params?: Partial<ComplaintQueryType>) => {
            try {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: true },
                });

                const searchParams =
                    coinForBarterRequest.makeQueryString(params || {}) || '';
                const {
                    data,
                    statusCode,
                    meta,
                } = await coinForBarterRequest.call(
                    `/payouts/complaints${searchParams}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    dispatch({
                        type: PayoutActionsOptions.Update,
                        payload: { loading: false },
                    });
                    return { complaints: data, meta: meta || null };
                }
            } catch (e) {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false },
                });
            }
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: false },
            });
            return null;
        },
        []
    );

    const getComplaint = useCallback(async (id: string) => {
        try {
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/payouts/complaints/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: PayoutActionsOptions.Update,
                    payload: { loading: false },
                });
                return data;
            }
        } catch (e) {
            dispatch({
                type: PayoutActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: PayoutActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    useEffect(() => {
        const abort = new AbortController();
        getSettings();
        return abort.abort();
    }, [getSettings]);
    return (
        <PayoutContext.Provider
            value={{
                state,
                findAll,
                findOne,
                getSettings,
                makeAllAutomatic,
                makeAllInACurrency,
                makeCustom,
                createComplaint,
                getComplaints,
                getComplaint,
            }}
        >
            {children}
        </PayoutContext.Provider>
    );
};
