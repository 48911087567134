import React, { FC } from 'react';
import TransparentLogo from '@assets/images/logo-transparent.svg';
import Logo from '@assets/images/logo-landscape.svg';
import {
    FullContainer,
    ContentWrapper,
    FirstImg,
    LastImg,
    Section,
    Img,
} from './AuthLayout.styled';

export const AuthLayout: FC<{ children: JSX.Element | JSX.Element[] }> = ({
    children,
}) => {
    return (
        <FullContainer>
            <FirstImg
                src={TransparentLogo}
                className="absolute top-0 right-0"
                alt="CoinForBarter Logo"
            />
            <LastImg
                src={TransparentLogo}
                className="absolute bottom-0 left-0"
                alt="CoinForBarter Logo"
            />
            <Section>
                <Img>
                    <img src={Logo} alt="CoinForBarter" />
                </Img>
                <ContentWrapper>{children}</ContentWrapper>
            </Section>
        </FullContainer>
    );
};
