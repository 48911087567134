import { SettingsContextType, SettingsStateType } from './types';

export const defaultSettingsState: SettingsStateType = {
    loading: false,
    data: {
        transactionEmails: false,
        transactionEmailsCustomers: false,
        isCustomerToPayFee: false,
        loginTwoFactorAuth: false,
        transferTwoFactorAuth: false,
    },
};

export const defaultSettingsContextValue: SettingsContextType = {
    state: defaultSettingsState,
    getSettings: () => {},
    updateSettings: () => {},
};
