import tw from 'tailwind-styled-components'

export const FullScreenCenteredContainer = tw.div`
    h-screen
    w-full
    flex
    items-center
    justify-center
    bg-white
`

export const Title = tw.h2`
    text-2xl
    py-1
`

export const Subtitle = tw.p`
    text-tiny
    text-black-300
    max-w-screen-sm
`

export const Capitalised = tw.div`
    capitalize
`