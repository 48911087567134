import { defaultProfileState } from './default';
import {
    ProfileActionsOptions,
    ProfileActionsType,
    ProfileStateType,
} from './types';

export const authReducer = (
    state: ProfileStateType,
    { type, payload }: ProfileActionsType
): ProfileStateType => {
    switch (type) {
        case ProfileActionsOptions.Default:
            return defaultProfileState;

        case ProfileActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
