import { BrandingContextType, BrandingStateType } from './types';

export const defaultBranding = {
    businessName: '',
    description: '',
    logo: '',
    color: '',
};

export const defaultBrandingState: BrandingStateType = {
    loading: false,
    branding: defaultBranding,
};

export const defaultBrandingContextValue: BrandingContextType = {
    state: defaultBrandingState,
    find: async () => {},
    update: async () => null,
};
