import React, { createContext, useReducer, useCallback, FC } from 'react';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    WalletActionsOptions,
    WalletContextType,
    WalletProviderType,
    WalletQueryType,
    WalletsType,
} from './types';
import { defaultWalletContextValue, defaultWalletState } from './default';

export const WalletContext = createContext<WalletContextType>(
    defaultWalletContextValue
);

export const WalletProvider: FC<WalletProviderType> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, defaultWalletState);

    const create = useCallback(async (params: Partial<WalletsType>) => {
        try {
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: true },
            });

            const response = await coinForBarterRequest.call(
                `/wallets`,
                MethodTypes.Post,
                params,
                true
            );
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: WalletActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const remove = useCallback(async (id: string) => {
        try {
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: true },
            });

            const response = await coinForBarterRequest.call(
                `/wallets/${id}`,
                MethodTypes.Delete,
                undefined,
                true
            );
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: false },
            });

            return response;
        } catch (e) {
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: WalletActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const findAll = useCallback(async (params?: Partial<WalletQueryType>) => {
        try {
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: true },
            });
            const searchParams =
                coinForBarterRequest.makeQueryString(params || {}) || '';
            const response = await coinForBarterRequest.call(
                `/wallets${searchParams}`,
                MethodTypes.Get,
                undefined,
                true
            );
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: WalletActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: true },
            });

            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: false },
            });
            const response = await coinForBarterRequest.call(
                `/wallets/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            return response;
        } catch (e) {
            dispatch({
                type: WalletActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    return (
        <WalletContext.Provider
            value={{
                state,
                create,
                findAll,
                findOne,
                remove,
            }}
        >
            {children}
        </WalletContext.Provider>
    );
};
