import React, { createContext, useReducer, useCallback, FC } from 'react';
import {
    coinForBarterRequest,
    MethodTypes,
    RequestResponseSchema,
} from 'services';
import { useToast } from 'hooks';
import { authReducer } from './reducer';
import {
    BankAccountActionsOptions,
    BankAccountContextType,
    BankAccountProviderType,
    BankAccountsQueryType,
    CreateBankAccountType,
    DataResponse,
    GetAccountNameType,
} from './types';
import {
    defaultBankAccountContextValue,
    defaultBankAccountState,
} from './default';

export const BankAccountContext = createContext<BankAccountContextType>(
    defaultBankAccountContextValue
);

export const BankAccountProvider: FC<BankAccountProviderType> = ({
    children,
}) => {
    const { error, success } = useToast();
    const [state, dispatch] = useReducer(authReducer, defaultBankAccountState);

    const findAll = useCallback(async (params?: BankAccountsQueryType) => {
        try {
            dispatch({
                type: BankAccountActionsOptions.Update,
                payload: { loading: true },
            });
            const searchParams =
                coinForBarterRequest.makeQueryString(params || {}) || '';
            const { statusCode, meta, data } = await coinForBarterRequest.call(
                `/bank-accounts${searchParams}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: false },
                });
                return { bankAccounts: data, meta: meta || null };
            }
        } catch (e) {
            dispatch({
                type: BankAccountActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: BankAccountActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/bank-accounts/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: false },
                });
                return data;
            }
        } catch (e) {
            dispatch({
                type: BankAccountActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const makePrimary = useCallback(
        async (id: string) => {
            try {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/bank-accounts/primary/${id}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    success('Switching primary bank account successful');
                    return true;
                } else {
                    error(message);
                }
            } catch (e) {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: BankAccountActionsOptions.Update,
                payload: { loading: false },
            });
            return false;
        },
        [success, error]
    );

    const archive = useCallback(
        async (id: string) => {
            try {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: true },
                });
                const { statusCode, message } = await coinForBarterRequest.call(
                    `/bank-accounts/account/archive/${id}`,
                    MethodTypes.Delete,
                    undefined,
                    true
                );
                if (statusCode === 204) {
                    success('Bank account deleted successfully');
                    return true;
                } else {
                    error(message);
                }
            } catch (e) {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: BankAccountActionsOptions.Update,
                payload: { loading: false },
            });

            return false;
        },
        [success, error]
    );

    const getBanks = useCallback(async (country: string) => {
        try {
            dispatch({
                type: BankAccountActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/bank-accounts/banks/${country}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: false, banks: data },
                });
            } else {
            }
        } catch (e) {
            dispatch({
                type: BankAccountActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: BankAccountActionsOptions.Update,
            payload: { loading: false },
        });
    }, []);

    const create = useCallback(
        async (
            body: CreateBankAccountType
        ): Promise<RequestResponseSchema<DataResponse> | null> => {
            try {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: true },
                });
                const response = await coinForBarterRequest.call(
                    `/bank-accounts`,
                    MethodTypes.Post,
                    body,
                    true
                );
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }
            dispatch({
                type: BankAccountActionsOptions.Update,
                payload: { loading: false },
            });

            return null;
        },
        [error]
    );

    const getAccountName = useCallback(
        async (body: GetAccountNameType) => {
            try {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: true },
                });
                const { data, statusCode } = await coinForBarterRequest.call(
                    `/bank-accounts/account/name`,
                    MethodTypes.Post,
                    body,
                    true
                );
                if (statusCode === 200) {
                    dispatch({
                        type: BankAccountActionsOptions.Update,
                        payload: { loading: false },
                    });
                    return data;
                }
            } catch (e) {
                dispatch({
                    type: BankAccountActionsOptions.Update,
                    payload: { loading: false },
                });
                error('An error occurred, please try again');
            }

            return null;
        },
        [error]
    );

    return (
        <BankAccountContext.Provider
            value={{
                state,
                findAll,
                findOne,
                makePrimary,
                archive,
                getBanks,
                getAccountName,
                create,
            }}
        >
            {children}
        </BankAccountContext.Provider>
    );
};
