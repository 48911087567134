import { PreloaderProvider } from 'context/preloader';
import { ToastProvider } from 'context/toast';
import React, { FC } from 'react';
import { AuthProvider } from '../auth/provider';
import { ProvidersProps } from './providers.type';

export const Providers: FC<ProvidersProps> = ({ children }) => {
    return (
        <PreloaderProvider>
            <ToastProvider>
                <AuthProvider>{children}</AuthProvider>
            </ToastProvider>
        </PreloaderProvider>
    );
};
