import { WalletContextType, WalletStateType } from './types';

export const defaultWalletState: WalletStateType = {
    loading: false,
};

export const defaultWalletContextValue: WalletContextType = {
    state: defaultWalletState,
    create: async () => null,
    remove: async () => null,
    findAll: async () => null,
    findOne: async () => null,
};
