export const getAuthToken = (): string | null => {
    return localStorage.getItem('c4b:user:token');
};

export const setAuthToken = (token: string): void => {
    localStorage.setItem('c4b:user:token', token);
};

export const removeAuthToken = (): void => {
    localStorage.removeItem('c4b:user:token');
};

export const setLocationHistory = (location: string): void => {
    sessionStorage.setItem('c4b:user:redirect:location', location);
};

export const getLocationHistory = (): string => {
    return sessionStorage.getItem('c4b:user:redirect:location') || '';
};

export const removeLocationHistory = (): void => {
    sessionStorage.removeItem('c4b:user:redirect:location');
};

export const copyToClipboard = async (content: string): Promise<void> => {
    try {
        await navigator.clipboard.writeText(content);
    } catch (err) {
        throw new Error('Failed to copy!');
    }
};

export const shortEmail = (email: string): string => {
    const split = email.split('@');
    return `${split[0]?.substr(0, 2)}****${split[1]}`;
};

export const shortPhoneNumber = (phoneNumber: string): string => {
    return `${phoneNumber?.substr(0, 3)}****${phoneNumber?.substr(
        phoneNumber?.length - 4,
        4
    )}`;
};
export enum StorageTypes {
    // eslint-disable-next-line no-unused-vars
    Test = 'c4b:test',
}
