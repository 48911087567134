import { InvoiceContextType, InvoiceStateType } from './types';

export const defaultInvoiceContextValue: InvoiceContextType = {
    state: { loading: false },
    findAll: async () => null,
    findOne: async () => null,
    findOneSent: async () => null,
    update: async () => null,
    create: async () => null,
    deleteInvoice: async () => null,
    remove: async () => false,
    findAllSent: async () => null,
    inviteCustomers: async () => false,
};

export const defaultInvoiceState: InvoiceStateType = {
    loading: false,
};
