import React, { useState, useEffect, FC } from 'react';
import { useTest } from 'hooks';
import { LoginModalProvider } from 'context';
import { useLocation } from 'react-router-dom';
import { Sidebar } from './Sidebar/Sidebar';
import { Header } from './Header';
import { Footer } from './Footer';
import { Content, Container, Notice } from './DashboardLayout.styled';

export const DashboardLayout: FC = (props) => {
    const { isTest } = useTest();
    const [isSideBarOpen, toggleSidebar] = useState(false);
    const { children } = props;
    const { pathname } = useLocation();

    useEffect(() => {
        const doc = document.querySelector('#c4bmaincontent');
        doc?.scrollTo(0, 0);
    }, [pathname]);
    return (
        <LoginModalProvider>
            <>
                <Notice
                    style={{
                        display: isTest ? 'block' : 'none',
                    }}
                >
                    You are currently in Test mode. Turn it off to switch to
                    Live Mode!
                </Notice>

                <Container open={isSideBarOpen}>
                    <Sidebar
                        open={isSideBarOpen}
                        close={() => toggleSidebar(false)}
                        toggle={() => toggleSidebar(!isSideBarOpen)}
                    />

                    <Header
                        isSideBarOpen={isSideBarOpen}
                        toggle={() => toggleSidebar(!isSideBarOpen)}
                    />

                    <Content
                        open={isSideBarOpen}
                        id="c4bmaincontent"
                        // onClick={() => isSideBarOpen && toggleSidebar(false)}
                    >
                        <>
                            {children}
                            <Footer />
                        </>
                    </Content>
                </Container>
            </>
        </LoginModalProvider>
    );
};

// export const withDashboard = (Component: FC) => {
//     return (props: Record<string, unknown>): ReactElement => {
//         return (
//             <DashboardLayout>
//                 <Component {...props} />
//             </DashboardLayout>
//         );
//     };
// };
