import { defaultSettingsState } from './default';
import {
    SettingsActionsOptions,
    SettingsActionsType,
    SettingsStateType,
} from './types';

export const authReducer = (
    state: SettingsStateType,
    { type, payload }: SettingsActionsType
): SettingsStateType => {
    switch (type) {
        case SettingsActionsOptions.Default:
            return defaultSettingsState;

        case SettingsActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
