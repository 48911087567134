import React, { FC } from 'react';
import { cardVariant } from 'constants/framer';
import { Container, Title, Subtitle, Icon } from './DashboardCard.styled';
import { Button } from '../Button';

export const DashboardCard: FC<{
    icon: JSX.Element;
    title: string;
    subtitle: string;
    onClick?: () => void;
    buttonText: string;
}> = ({ icon, title, subtitle, onClick, buttonText }) => {
    return (
        <Container
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            variants={cardVariant}
        >
            <Icon>{icon}</Icon>
            <div>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </div>
            <Button onClick={onClick} width="100%" variant="secondary">
                {buttonText}
            </Button>
        </Container>
    );
};
