/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { ChangeEventHandler } from 'react';

interface PropsType {
    children?: JSX.Element;
    type?: InputPropsTypeOptions | 'text' | 'number' | 'password' | 'hidden';
    className?: string;
    label?: string;
    errors?: string[];
    disabled?: boolean;
    name?: string;
    placeholder?: string;
    labelHint?: string;
    value?: string;
    required?: boolean;
    id?: string;
    defaultValue?: any;
}

export interface InputPropsType extends PropsType {
    onChange: (...any: any) => any;
    multiline?: boolean;
}

export interface PasswordPropsType extends InputPropsType {
    type?: undefined;
    showHint?: boolean;
}
export interface TextAreaType extends PropsType {
    maxLength: number;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export enum InputPropsTypeOptions {
    Text = 'text',
    Number = 'number',
    Password = 'password',
    Email = 'email',
    Date = 'date',
    Tel = 'tel',
}

interface SelectOptionsValue {
    value: string | number | boolean | (string | number | boolean)[];
    key: string;
    label: string;
    option: unknown;
}
export interface SelectPropsType extends PropsType {
    options: SelectOptionsValue[];
    onChange: (
        v: string | number | boolean | (string | number | boolean)[]
    ) => void;
    multiple?: boolean;
    renderComponent?: (v: unknown) => JSX.Element;
}
