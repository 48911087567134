import styled from 'styled-components';

export const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    position: relative;
    max-width: 270px;
    z-index: 14;
    border-right: 1px solid #cacaca;
    background-color: #fff;
    color: rgba(82, 4, 4, 0.75);
    grid-area: sidebar;
    transition: all 0.1s;
    @media (max-width: 600px) {
        position: absolute;
        width: 70%;
        left: ${({ open }: Record<string, unknown>) => (open ? '0' : '-100vw')};
        max-height: 100vh;
        height: calc(100vh-72px);
        padding-top: 90px;
    }
    overflow: hidden;
    overflow-y: scroll;
`;

export const SidebarCategory = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    padding-top: 0px;
    /* &:not(:first-child) { */
    position: relative;
    padding-top: 30px;
    /* } */
    &::before {
        position: absolute;
        height: 2px;
        top: 0;
        width: 20px;
        background-color: #cacaca;
        /* border-top: 1px solid #cacaca; */
    }
    @media (max-width: 600px) {
        padding-top: 20px;
    }

    border-bottom: 1px solid #cacaca;
`;
export const SidebarCategoryTitle = styled.p`
    display: flex;
    height: 46px;
    align-items: center;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 14px;
    color: #373737;
`;

export const SidebarMenu = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
`;

export const SidebarMenuItemLabel = styled.p`
    font-size: 14px;
    text-align: left;
    margin-left: 16px;
    transition: all 0.3s;
    font-weight: ${(props: Record<string, unknown>) =>
        props.active ? 600 : 'normal'};
    color: #373737;

    @media (min-width: 600px) {
        display: ${(props: Record<string, unknown>) =>
        props.open ? 'block' : 'none'};
    }
    font-weight: ${(props: Record<string, unknown>) =>
        props.active ? ' bold' : 'normal'};
`;

export const SidebarMenuItem = styled.li`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 30px;
    @media (min-width: 600px) {
        padding: ${(props: Record<string, unknown>) =>
        props.open ? '24px' : '12px'};
        padding-left: ${(props: Record<string, unknown>) =>
        props.open ? '30px' : '20px'};
    }
    background: ${(props: Record<string, unknown>) =>
        props.active ? ' #D9C2DB' : ''};
    transition: all 0.3s;
    &:hover {
        background: #d9c2db;

        cursor: pointer;
    }
    margin-bottom: 5px;

    path {
        stroke: ${(props: Record<string, unknown>) =>
        props.active ? ' #3d0040' : '#7b7b7b'};
        transition: all 0.3s;
        fill: ${(props: Record<string, unknown>) =>
        props.active ? ' #d9c2db' : '#cacaca'};
    }

    &:hover path {
        fill: #d9c2db;
        stroke: #3d0040;
    }

    &:hover ${SidebarMenuItemLabel} {
        font-weight: bold;
    }
`;

export const LogoutButton = styled.div`
    outline: none;
`;

export const Icon = styled.div`
    width: 20px;
    height: 20px;

    svg {
    }
`;
export const Drawer = styled.button`
    position: fixed;
    &:focus {
        outline: none;
    }
    @media (max-width: 600px) {
        display: none;
    }
    &:hover {
        cursor: pointer;
        opacity: 1;
    }

    opacity: 0.7;
    top: 50%;
    background: #3d0040;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 0px 50px 50px 0px;
    padding: 8px;
    left: ${({ open }: Record<string, unknown>) => (open ? '19.8%' : '60px')};
    transition: all 0.2s;

    svg {
        transform: ${({ open }: Record<string, unknown>) =>
        open ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
`;

export const Arrow = styled.button`
    &:focus {
        outline: none;
    }
    display: ${({ open }: Record<string, unknown>) =>
        open ? 'block' : 'none'};
    transform: ${({ mini }) => (mini ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

export const UserAccount = styled.div`
    background-color: #3d3d3d1a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${({ open }: Record<string, unknown>) =>
        open ? '8px' : '16px 8px'};
    border-radius: 0px 0px 20px 20px;
    display: flex;
`;

export const Name = styled.p`
    font-size: 14px;
    display: ${({ open }: Record<string, unknown>) =>
        open ? 'block' : 'none'};
    color: #373737;
    display: block;
    text-align: left;
    cursor: pointer;
`;

export const AccountList = styled.ul`
    width: 100%;
`;

export const AccountListItem = styled.li`
    display: ${({ mini }) => (mini ? 'none' : 'block')};
    padding: ${({ open }: Record<string, unknown>) => (open ? '8px' : '16px')};
    display: block;
    font-size: 14px;
    transition: all 0.1s;
    :hover {
        background: #d9c2db;
    }
    @media (max-width: 600px) {
        padding: ${({ mini }) => (!mini ? '8px' : '16px')};
    }
`;

export const Title = styled.p`
    font-size: 12px;
    padding-top: 6px;
    color: #7b7b7b;
    /* display: block; */
    display: ${({ active }: Record<string, unknown>) =>
        active ? 'block' : 'none'};
`;

export const SubTitle = styled.button`
    &:focus {
        outline: none;
    }

    display: flex;
    font-size: 11px;
    align-items: center;
    padding-bottom: 6px;
    color: #373737;
    text-decoration: underline;
    display: ${({ active }: Record<string, unknown>) =>
        active ? 'flex' : 'none'};
`;

export const Hint = styled.div`
    &:hover {
        content: attr(title); 
    }
`;