import React, { FC } from 'react';
import {
    Container,
    Title,
    ButtonWrapper,
    ForgotPasswordLink,
} from '../../styled';

export const ResetPasswordModal: FC = () => {
    return (
        <Container>
            <Title>Password reset successful</Title>
            <p
                style={{
                    fontSize: '14px',
                    textAlign: 'center',
                }}
                className="my-4"
            >
                Your password reset was successful.
            </p>
            <ButtonWrapper
                className="justify-end"
                style={{ justifyContent: 'flex-end' }}
            >
                <ForgotPasswordLink to="/login">Login</ForgotPasswordLink>
            </ButtonWrapper>
        </Container>
    );
};
