import { PayoutContextType, PayoutStateType } from './types';

export const defaultPayoutState: PayoutStateType = {
    loading: false,
    settings: [],
};
export const defaultPayoutContextValue: PayoutContextType = {
    state: defaultPayoutState,
    findAll: async () => null,
    findOne: async () => null,
    getSettings: async () => {},
    makeAllAutomatic: async () => {},
    makeAllInACurrency: async () => {},
    makeCustom: async () => {},
    getComplaints: async () => null,
    getComplaint: async () => null,
    createComplaint: async () => null,
};
