import { AccountContextType, AccountStateType } from './types';

export const defaultAccountState: AccountStateType = {
    loading: false,
    data: [],
};

export const defaultAccountContextValue: AccountContextType = {
    state: defaultAccountState,
    getAccounts: () => {},
    createAccount: async () => null,
    verifyEmail: async () => null,
    verifyPhoneNumber: async () => null,
    switchAccount: () => {},
    requestEmailVerification: () => null,
    requestPhoneNumberVerification: () => null,
    updatePhoneNumber: () => null,
};
