import React, {
    ChangeEventHandler,
    FormEventHandler,
    useState,
    FC,
} from 'react';
import { Button, Input } from 'components';
import { useHistory } from 'react-router-dom';
import { shortEmail } from 'utils';
import { useToast } from 'hooks';
import { InputPropsTypeOptions } from 'components/Input/types';
import { routes } from 'routes';
import { coinForBarterRequest, MethodTypes, ServerErrorType } from 'services';
import { motion } from 'framer-motion';
import { pageVariant } from 'constants/framer';
import { Title, ButtonWrapper, ForgotPasswordLink } from '../styled';

export const ForgotPassword: FC = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState<{ email: string }>({ email: '' });
    const [errors, setErrors] = useState<ServerErrorType>({});
    const [success, setSuccess] = useState<boolean>(false);
    const toast = useToast();

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const {
                data,
                message,
                statusCode,
            } = await coinForBarterRequest.call(
                `/auth/password/${values.email}`,
                MethodTypes.Get
            );
            if (statusCode === 400) {
                toast.error('Validation error');
                setErrors(data.errors);
            } else if (statusCode === 200) {
                toast.success('Reset email sent successfully');
                setSuccess(true);
            } else {
                toast.error(message);
            }
        } catch (e) {
            toast.error(e.message);
        }
        setLoading(false);
    };

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            variants={pageVariant}
        >
            <Title>Recover Password</Title>

            {!success && (
                <>
                    <form onSubmit={handleSubmit}>
                        <p
                            style={{
                                color: '#373737',
                                fontSize: '14px',
                            }}
                            className="mb-4"
                        >
                            Enter your email address to recover password
                        </p>
                        <Input
                            placeholder="Email address"
                            label="Email address"
                            name="email"
                            id="email"
                            value={values.email}
                            errors={errors.email}
                            type={InputPropsTypeOptions.Email}
                            onChange={handleInputChange}
                            required={true}
                        />

                        <ButtonWrapper>
                            <ForgotPasswordLink to={routes.login}>
                                Login
                            </ForgotPasswordLink>
                            <Button
                                loading={loading}
                                disabled={loading}
                                type="submit"
                            >
                                Send link
                            </Button>
                        </ButtonWrapper>
                    </form>
                </>
            )}

            {success && (
                <>
                    <p
                        style={{
                            fontSize: '14px',
                        }}
                    >
                        An email reset link has been sent to{' '}
                        <b>{shortEmail(values.email)}</b>. Follow the
                        instructions to reset password
                    </p>

                    <ButtonWrapper>
                        <ForgotPasswordLink to={routes.login}>
                            Login
                        </ForgotPasswordLink>
                        <Button
                            loading={loading}
                            disabled={loading}
                            onClick={(e) => {
                                e.preventDefault();
                                history.push(routes.login);
                            }}
                        >
                            Proceed
                        </Button>
                    </ButtonWrapper>
                </>
            )}
        </motion.div>
    );
};
