import {
    CreateCryptoAddressType,
    CryptoAddressContextType,
    CryptoAddressStateType,
} from './types';

export const defaultCryptoAddressContextValue: CryptoAddressContextType = {
    state: {},
    findAll: async () => null,
    findOne: async () => null,
    archive: async () => false,
    makePrimary: async () => false,
    create: async () => null,
};

export const defaultCryptoAddressState: CryptoAddressStateType = {
    loading: false,
    addresses: [],
};

export const createCryptoAddressBody: CreateCryptoAddressType = {
    currency: '',
    address: '',
    network: '',
    label: '',
    isPrimary: false,
};
