import { MiscContextType, MiscStateType } from './types';

export const defaultMiscState: MiscStateType = {
    loading: false,
    data: {
        countries: [],
        media: [],
        occupations: [],
        currencies: [],
    },
};

export const defaultMiscContextValue: MiscContextType = {
    state: defaultMiscState,
    media: () => {},
    countries: () => {},
    occupations: () => {},
};
