import {
    BankAccountContextType,
    BankAccountStateType,
    CreateBankAccountType,
} from './types';

export const defaultBankAccountContextValue: BankAccountContextType = {
    state: {},
    findAll: async () => null,
    findOne: async () => null,
    archive: async () => false,
    makePrimary: async () => false,
    create: async () => null,
    getAccountName: async () => null,
    getBanks: () => {},
};

export const defaultBankAccountState: BankAccountStateType = {
    loading: false,
};

export const createBankAccount: CreateBankAccountType = {
    accountBank: '',
    accountNumber: '',
    country: '',
    currency: '',
    accountName: '',
    isPrimary: false,
};
