import tw from 'tailwind-styled-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const TitleWrapper = tw.div`
    pt-6
    max-w-sm
    pb-6
`;

export const Title = tw(styled.h2`
    font-size: 18px;
    padding-bottom: 20px;
`)`
    font-bold
    text-center
`;

export const Subtitle = tw.p`
    text-gray-500
    text-xs
    sm:text-xs
    sm:max-w-xs
    pt-4
    sm:pt-0
`;

export const BetweenRow = tw.div`
    flex
    justify-end
    items-center

`;

export const ForgotPasswordLink = styled(Link)`
    font-size: 14px;
    text-align: center;
    color: #003db5;
    transition: all 0.3s;
    text-decoration-line: underline;
    font-weight: bold;
    &:hover {
    }
`;

export const ButtonWrapper = tw.div`
    pt-4
    flex
    justify-between
    items-center
`;

export const Container = tw.h3`
    items-center flex flex-col py-12 px-8 justify-center items-stretch
`;

export const Terms = styled.p`
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #7b7b7b;
    margin-top: 20px;

    a {
        text-align: center;
        color: #003db5;
        transition: all 0.3s;
        text-decoration-line: underline;
        font-weight: 600;
        &:hover {
            text-decoration-line: underline;
        }
    }
`;
