import React, { createContext, useReducer, useCallback, FC } from 'react';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    TransactionActionsOptions,
    TransactionContextType,
    TransactionProviderType,
    TransactionsQueryType,
} from './types';
import {
    defaultTransactionContextValue,
    defaultTransactionState,
} from './default';

export const TransactionContext = createContext<TransactionContextType>(
    defaultTransactionContextValue
);

export const TransactionProvider: FC<TransactionProviderType> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(authReducer, defaultTransactionState);

    const findAll = useCallback(
        async (params?: Partial<TransactionsQueryType>) => {
            try {
                dispatch({
                    type: TransactionActionsOptions.Update,
                    payload: { loading: true },
                });
                const searchParams =
                    coinForBarterRequest.makeQueryString(params || {}) || '';
                const {
                    data,
                    statusCode,
                    meta,
                } = await coinForBarterRequest.call(
                    `/transactions${searchParams}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                if (statusCode === 200) {
                    dispatch({
                        type: TransactionActionsOptions.Update,
                        payload: { loading: false },
                    });
                    return { transactions: data, meta: meta || null };
                }
            } catch (e) {
                dispatch({
                    type: TransactionActionsOptions.Update,
                    payload: { loading: false },
                });
            }

            return null;
        },
        []
    );

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: TransactionActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/transactions/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: TransactionActionsOptions.Update,
                    payload: { loading: false },
                });
                return data;
            }
        } catch (e) {
            dispatch({
                type: TransactionActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const graph = useCallback(async () => {
        try {
            dispatch({
                type: TransactionActionsOptions.Update,
                payload: { loading: true },
            });
            const { data, statusCode } = await coinForBarterRequest.call(
                `/transactions/graph`,
                MethodTypes.Get,
                undefined,
                true
            );
            if (statusCode === 200) {
                dispatch({
                    type: TransactionActionsOptions.Update,
                    payload: { loading: false },
                });
                return data;
            }
        } catch (e) {
            dispatch({
                type: TransactionActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);
    return (
        <TransactionContext.Provider
            value={{
                state,
                findAll,
                findOne,
                graph,
            }}
        >
            {children}
        </TransactionContext.Provider>
    );
};
