import React, { createContext, useReducer, useCallback, FC } from 'react';
import { coinForBarterRequest, MethodTypes } from 'services';
import { authReducer } from './reducer';
import {
    PaymentPlanActionsOptions,
    PaymentPlanContextType,
    PaymentPlanProviderType,
    PaymentPlanQueryType,
    PaymentPlanType,
} from './types';
import {
    defaultPaymentPlanContextValue,
    defaultPaymentPlanState,
} from './default';

export const PaymentPlanContext = createContext<PaymentPlanContextType>(
    defaultPaymentPlanContextValue
);

export const PaymentPlanProvider: FC<PaymentPlanProviderType> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(authReducer, defaultPaymentPlanState);

    const create = useCallback(async (params: Partial<PaymentPlanType>) => {
        try {
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: true },
            });

            const response = await coinForBarterRequest.call(
                `/payment-plans`,
                MethodTypes.Post,
                params,
                true
            );
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: false },
            });
        }
        dispatch({
            type: PaymentPlanActionsOptions.Update,
            payload: { loading: false },
        });
        return null;
    }, []);

    const update = useCallback(
        async (id: string, params: Partial<PaymentPlanType>) => {
            try {
                dispatch({
                    type: PaymentPlanActionsOptions.Update,
                    payload: { loading: true },
                });

                const response = await coinForBarterRequest.call(
                    `/payment-plans/${id}`,
                    MethodTypes.Patch,
                    params,
                    true
                );
                dispatch({
                    type: PaymentPlanActionsOptions.Update,
                    payload: { loading: false },
                });

                return response;
            } catch (e) {
                dispatch({
                    type: PaymentPlanActionsOptions.Update,
                    payload: { loading: false },
                });
            }
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: false },
            });
            return null;
        },
        []
    );

    const findAll = useCallback(
        async (params?: Partial<PaymentPlanQueryType>) => {
            try {
                dispatch({
                    type: PaymentPlanActionsOptions.Update,
                    payload: { loading: true },
                });
                const searchParams =
                    coinForBarterRequest.makeQueryString(params || {}) || '';
                const response = await coinForBarterRequest.call(
                    `/payment-plans${searchParams}`,
                    MethodTypes.Get,
                    undefined,
                    true
                );
                dispatch({
                    type: PaymentPlanActionsOptions.Update,
                    payload: { loading: false },
                });
                return response;
            } catch (e) {
                dispatch({
                    type: PaymentPlanActionsOptions.Update,
                    payload: { loading: false },
                });
            }
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: false },
            });
            return null;
        },
        []
    );

    const findOne = useCallback(async (id: string) => {
        try {
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: true },
            });

            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: false },
            });
            const response = await coinForBarterRequest.call(
                `/payment-plans/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );
            return response;
        } catch (e) {
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const getPaymentPlanAnalytics = useCallback(async () => {
        try {
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: true },
            });

            const response = await coinForBarterRequest.call(
                `/payment-plans/analytics`,
                MethodTypes.Get,
                undefined,
                true
            );

            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    const getAnalyticsByPaymentPlan = useCallback(async (id: string) => {
        try {
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: true },
            });

            const response = await coinForBarterRequest.call(
                `/payment-plans/analytics/${id}`,
                MethodTypes.Get,
                undefined,
                true
            );

            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: false },
            });
            return response;
        } catch (e) {
            dispatch({
                type: PaymentPlanActionsOptions.Update,
                payload: { loading: false },
            });
        }

        return null;
    }, []);

    return (
        <PaymentPlanContext.Provider
            value={{
                state,
                create,
                findAll,
                findOne,
                update,
                getPaymentPlanAnalytics,
                getAnalyticsByPaymentPlan,
            }}
        >
            {children}
        </PaymentPlanContext.Provider>
    );
};
