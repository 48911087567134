/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import {
    getAuthToken,
    removeAuthToken,
    setAuthToken,
    setLocationHistory,
    StorageTypes,
} from 'utils';
import {
    MethodTypes,
    RequestResponseSchema,
    StatusType,
    ContentType,
} from './types';

class CoinForBarterRequest {
    private url: string;

    private testUrl: string;

    constructor() {
        this.url = process.env.REACT_APP_API_BASE_URL || '';
        this.testUrl = process.env.REACT_APP_API_BASE_URL_TEST || '';
    }

    async call(
        path = '',
        method = MethodTypes.Get,
        body = {},
        useToken = false,
        contentType = ContentType.Json
    ): Promise<RequestResponseSchema<any>> {
        try {
            const paymentToken = getAuthToken();

            let request: Record<string, any>;
            const status = StatusType.Success;
            const url = `${
                sessionStorage.getItem(StorageTypes.Test) &&
                !path.includes('/auth/')
                    ? this.testUrl
                    : this.url
            }${path}`;
            let data = {};
            let statusCode = 200;
            let message = '';
            let meta;

            const headers = {
                Authorization: useToken ? paymentToken : '',
                'Content-Type': contentType,
            };
            if (method === MethodTypes.Get || method === MethodTypes.Delete) {
                request = await axios[method](url, { headers });
                if (request.status === 204) {
                    statusCode = request.status;
                } else {
                    data = request.data.data;
                    message = request.data.message;
                    statusCode = request.status;
                    meta = request.data.meta;
                }
                if (request?.data?.accessToken) {
                    setAuthToken(request?.data?.accessToken);
                }
            }
            if (method === MethodTypes.Post || method === MethodTypes.Patch) {
                request = await axios[method](url, body, { headers });
                data = request.data.data;
                message = request.data.message;
                statusCode = request.status;
                meta = request.data.meta;
                if (request?.data?.accessToken) {
                    setAuthToken(request?.data?.accessToken);
                }
            }

            return { data, status, message, statusCode, meta };
        } catch (error) {
            if (error.response.status === 401) {
                setLocationHistory(window.location.pathname);
                removeAuthToken();
            }
            const status = StatusType.Error;
            if (error.response?.status && error.response?.data) {
                const {
                    status: statusCode,
                    data: { data, message, accessToken },
                } = error.response;

                if (accessToken) {
                    setAuthToken(accessToken);
                }
                return { data, message, status, statusCode };
            }
            return {
                status,
                message: 'an error occurred',
                data: {},
                statusCode: 0,
            };
        }
    }

    makeQueryString(query: Record<string, any>): string {
        let queryArray = Object.entries(query).map(([key, value]) =>
            value ? `${key}=${value}` : undefined
        );
        queryArray = queryArray.filter((item) => !!item);
        return queryArray.length > 0 ? `?${queryArray.join('&')}` : '';
    }
}

export const coinForBarterRequest = new CoinForBarterRequest();
