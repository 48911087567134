/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Button } from 'components';
import { ButtonSize } from 'components/Button/types';

interface State {
    hasError: boolean;
}

const h1style = {
    color: '#373737',
    fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: '3.2rem',
};
const hstyle = {
    marginTop: '40vh',
};

export class ErrorBoundary extends Component<Record<string, unknown>, State> {
    constructor(props: Record<string, unknown>) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch = (error: any, errorInfo: any) => {
        // TODO: send this error to database
    };

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <div style={hstyle} className="text-center">
                        <h1 className="mb-2" style={h1style}>
                            An unexpected error occurred
                        </h1>
                        <p className="mb-8">
                            An unexpected error occurred, please go home
                        </p>
                        <a href="/ ">
                            <Button size={ButtonSize.Large}>Go Home</Button>
                        </a>
                    </div>
                </>
            );
        }

        return this.props.children;
    }
}
