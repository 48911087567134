/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataResponse } from 'context/bank-accounts';
import { MetaType, RequestResponseSchema } from 'services';

export interface FiatWalletContextType {
    state: Record<string, any>;
    findAll: (
        params?: Partial<FiatWalletsQueryType>
    ) => Promise<{
        fiatWallets: FiatWalletType[];
        meta: MetaType | null;
    } | null>;
    findOne: (id: string) => Promise<FiatWalletType | null>;
    archive: (id: string) => Promise<boolean>;
    makePrimary: (id: string) => Promise<boolean>;
    create: (
        body: Partial<FiatWalletType>
    ) => Promise<RequestResponseSchema<DataResponse> | null>;
    getWallets: () => void;
}

export interface FiatWalletProviderType {
    children: JSX.Element;
}

export type FiatWalletStateType = {
    loading: boolean;
    wallets?: WalletType[];
    meta?: MetaType | null;
};

export type FiatWalletActionsType = {
    type: FiatWalletActionsOptions;
    payload: Partial<FiatWalletStateType>;
};

export enum FiatWalletActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface FiatWalletType {
    accountId: string;

    isPrimary: boolean;

    country: string;

    currency: string;

    walletName: string;

    email: string;

    merchantId: string;

    isDeleted: boolean;

    paypalName: string;

    paypalUsername: string;

    mobileNumber: string;

    paystackId: string;

    abegUserName: string;
}

export interface WalletType {
    title: string;
    currency: string;
    isAvailable: boolean;
    countries: string[];
    walletProperties: WalletProperty[];
    _id: string;
}

export type WalletProperty = {
    backEndName: string;
    frontEndName: string;
};
export interface FiatWalletsQueryType {
    from: string;
    to: string;
    isPrimary: boolean;
    page: number;
    country: string;
    currency: string;
    skip: number;
    walletName: string;
}
