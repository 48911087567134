import { WebHookContextType, WebHookStateType } from './types';

export const defaultWebHookState: WebHookStateType = {
    loading: false,
    data: {
        url: '',
        secretHash: '',
    },
};

export const defaultWebHookContextValue: WebHookContextType = {
    state: defaultWebHookState,
    getWebHooks: () => {},
    updateWebHooks: () => {},
};
