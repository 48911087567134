/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
export enum StatusType {
    Success = 'success',
    Error = 'error',
}

export enum StorageOptions {
    Payment = 'CoinForBarter:Checkout:Payment',
    PaymentLink = 'CoinForBarter:Checkout:PaymentLink',
    PaymentExpiryTime = 'PaymentExpiryTime',
    PaymentSource = 'PaymentSource',
}

export interface RequestResponseSchema<T> {
    status: StatusType;
    data: T;
    message: string;
    statusCode: number;
    meta?: MetaType | null;
}

export type MetaType = {
    pagination: {
        total: number;
        count: number;
        from: number;
        to: number;
        page: number;
    };
};

export enum MethodTypes {
    Post = 'post',
    Get = 'get',
    Delete = 'delete',
    Patch = 'patch',
}

export enum ContentType {
    Json = 'application/json',
    Form = 'multipart/form-data',
}

export interface ServerErrorType {
    [key: string]: string[];
}
export interface ServerContextType {
    makeQueryString: (query: Record<string, any>) => string;
    call: (
        path?: string,
        method?: MethodTypes,
        body?: any,
        useToken?: boolean,
        contentType?: ContentType
    ) => Promise<RequestResponseSchema<any>>;
}
