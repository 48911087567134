import React, {
    ChangeEventHandler,
    FormEventHandler,
    useState,
    FC,
} from 'react';
import { routes } from 'routes';
import { Button, Password, Modal } from 'components';
import { coinForBarterRequest, MethodTypes, ServerErrorType } from 'services';
import { useModal, useToast } from 'hooks';
import { motion } from 'framer-motion';
import { pageVariant } from 'constants/framer';
import { ResetPasswordModal } from './ResetPasswordModal';
import { Title, ButtonWrapper, ForgotPasswordLink } from '../styled';

interface AuthPasswordResetValuesType {
    password: string;
    confirmPassword: string;
}
const defaultResetPasswordValue = {
    password: '',
    confirmPassword: '',
};

export const ResetPassword: FC = () => {
    const { isModalVisible, toggleModal } = useModal();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState<AuthPasswordResetValuesType>(
        defaultResetPasswordValue
    );
    const toast = useToast();

    const [errors, setErrors] = useState<ServerErrorType>({});

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const {
                data,
                message,
                statusCode,
            } = await coinForBarterRequest.call(
                `/auth/password/${params.token}`,
                MethodTypes.Post,
                values
            );
            if (statusCode === 400) {
                toast.error('Validation error');
                setErrors(data.errors);
            } else if (statusCode === 201) {
                toast.success('Password reset was successful');
                toggleModal();
            } else {
                toast.error(message);
            }
        } catch (e: any) {
            toast.error(e?.message);
        }
        setLoading(false);
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            variants={pageVariant}
        >
            <Modal
                isModalVisible={isModalVisible}
                toggleModal={toggleModal}
                closable
            >
                <ResetPasswordModal />
            </Modal>

            <Title>New password</Title>
            <form onSubmit={handleSubmit}>
                <Password
                    onChange={handleInputChange}
                    label="Password"
                    placeholder="Enter new password"
                    name="password"
                    errors={errors?.password}
                    id="password"
                    value={values.password}
                    showHint={true}
                />
                <Password
                    onChange={handleInputChange}
                    label="Confirm Password"
                    placeholder="Re-enter new password"
                    name="confirmPassword"
                    id="confirmPassword"
                    errors={errors?.confirmPassword}
                    value={values.confirmPassword}
                />

                <ButtonWrapper>
                    <ForgotPasswordLink to={routes.login}>
                        Login
                    </ForgotPasswordLink>

                    <Button loading={loading} disabled={loading} type="submit">
                        Reset Password
                    </Button>
                </ButtonWrapper>
            </form>
        </motion.div>
    );
};
