/* eslint-disable no-unused-vars */
import { MetaType } from 'services';

export interface NotificationContextType {
    state: NotificationStateType;
    getNotifications?: (query?: {
        from?: string;
        to?: string;
        isRead?: boolean;
        page?: number;
    }) => void;
    readAll?: () => void;
    readNotification?: (id: string) => void;
    getNotification?: (id: string) => Promise<NotificationType | null>;
}

export interface NotificationProviderType {
    children: JSX.Element;
}

export type NotificationStateType = {
    loading: boolean;
    notifications: NotificationType[];
    meta?: MetaType | null;
    totalUnread: number;
};

export type NotificationActionsType = {
    type: NotificationActionsOptions;
    payload: Partial<NotificationStateType>;
};

export enum NotificationActionsOptions {
    Update = 'update',
    Default = 'default',
}

export interface NotificationType {
    title: string;
    content: string;
    isRead: boolean;
    createdAt: string;
    _id: string;
}
