/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useCallback, FC } from 'react';
import SettingsIcon from '@assets/icons/settings.svg';
import { routes } from 'routes';
import LogoutIcon from '@assets/icons/logout.svg';
import SupportIcon from '@assets/icons/support.svg';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks';
import {
    Menu,
    MenuItem,
    MenuItemLabel,
    LogoutButton,
    Icon,
    Svg,
} from './ProfileMenu.styled';

type RefCurrentType = {
    contains: (x: unknown) => void;
};
export const ProfileMenu: FC<{
    openMenu: boolean;
    setOpenMenu: (x: boolean) => unknown;
}> = ({ openMenu, setOpenMenu }) => {
    const menuDropDownRef = useRef<RefCurrentType>();
    const { logout } = useAuth();

    const handleClick = useCallback(
        (e) => {
            if (menuDropDownRef?.current?.contains(e.target)) {
                // inside click
                return;
            }
            // outside click
            setOpenMenu(false);
        },
        [setOpenMenu]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [handleClick]);

    return (
        <Menu ref={menuDropDownRef} open={openMenu}>
            <ul>
                <MenuItem onClick={() => setOpenMenu(false)}>
                    <Link to={routes.settingsIndex}>
                        <Icon src={SettingsIcon} />
                        <MenuItemLabel>Settings</MenuItemLabel>
                    </Link>
                </MenuItem>
                <MenuItem onClick={() => setOpenMenu(false)}>
                    <Link to={routes.settings.api}>
                        <Svg
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="#d9c2db"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M22.0229 4.2766L21.339 3.81323C20.4911 3.23874 19.4901 2.93244 18.4659 2.93412L18.4643 2.93412C17.1491 2.93412 15.8377 3.43413 14.8346 4.43723L12.2455 7.02637L19.5063 14.2872L22.0965 11.6971L22.0229 4.2766ZM22.0229 4.2766L22.6071 3.69247M22.0229 4.2766L22.6071 3.69247M22.6071 3.69247L24.407 1.89253L24.6417 2.1272L22.8417 3.92715L22.2577 4.51121M22.6071 3.69247L22.2577 4.51121M22.2577 4.51121L22.7209 5.19508M22.2577 4.51121L22.7209 5.19508M22.7209 5.19508C24.072 7.1897 23.8636 9.92689 22.097 11.6965L22.7209 5.19508ZM12.4029 16.8959L13.11 17.603L13.8171 16.8959L15.3272 15.3857L15.3752 15.4331L13.8679 16.9405L13.1607 17.6476L13.8679 18.3547L14.6518 19.1387L12.0627 21.7278C11.0596 22.7309 9.74811 23.2309 8.43299 23.2309C7.42485 23.2309 6.42058 22.9378 5.55934 22.3526L4.87513 21.8876L4.29019 22.4726L2.49025 24.2725L2.2571 24.0394L4.05551 22.241L4.63958 21.6569L4.17634 20.973C2.82487 18.9779 3.0341 16.2377 4.80027 14.4716L4.80062 14.4712L7.38814 11.8811L8.17336 12.6664L8.88046 13.3735L9.58757 12.6664L11.0979 11.156L11.1456 11.2038L9.63529 12.7141L8.92818 13.4212L9.63529 14.1283L12.4029 16.8959ZM6.85547 11.3485L6.85595 11.349C6.8557 11.3487 6.85544 11.3484 6.85519 11.3482L6.85547 11.3485ZM24.407 0.827708L24.5817 0.653011L24.407 0.827708Z"
                                strokeWidth="2"
                                stroke="#3d0040"
                            />
                        </Svg>
                        <MenuItemLabel>Api</MenuItemLabel>
                    </Link>
                </MenuItem>
                <MenuItem onClick={() => setOpenMenu(false)}>
                    <Link
                        to="/"
                        onClick={() => {
                            window.open(process.env.REACT_APP_SUPPORT_URL);
                        }}
                    >
                        <Icon src={SupportIcon} />
                        <MenuItemLabel>Support</MenuItemLabel>
                    </Link>
                </MenuItem>
                <hr />
                <MenuItem onClick={() => setOpenMenu(false)}>
                    <LogoutButton onClick={logout}>
                        <Icon src={LogoutIcon} />
                        <MenuItemLabel>Logout</MenuItemLabel>
                    </LogoutButton>
                </MenuItem>
            </ul>
        </Menu>
    );
};
