import { defaultPaymentLinkState } from './default';
import {
    PaymentLinkActionsOptions,
    PaymentLinkActionsType,
    PaymentLinkStateType,
} from './types';

export const authReducer = (
    state: PaymentLinkStateType,
    { type, payload }: PaymentLinkActionsType
): PaymentLinkStateType => {
    switch (type) {
        case PaymentLinkActionsOptions.Default:
            return defaultPaymentLinkState;

        case PaymentLinkActionsOptions.Update:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
